export const NoFlame19 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="297.76 654.4 449.51 605.04 484.04 612.71 537.24 792.82 360.56 852.95 297.29 674.02 297.76 654.4"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m294.8,672.73c.47-5.64.98-11.32,1.38-17.02.08-1.1.76-1.22,1.49-1.46,12.09-3.95,113.82-37.13,152.59-49.79,1.13-.37,2.18-.46,3.36-.22,7.17,1.47,28.25,5.65,31.63,6.17,1.13.17,1.35.7,1.59,1.53,2.35,7.93,45.51,153.73,53.29,179.98.32,1.1.64,1.87-.98,2.44-44.96,15.75-164.06,57.66-178.67,62.84-1.11.39-1.57.3-1.99-.91-18.36-52.77-60.66-174.1-63.42-182.01-.17-.49-.43-.96-.29-1.56Zm239.14,117.2c-.13-.43-.24-.84-.36-1.24-2.77-9.28-36.23-121.75-50.2-168.71-.64-2.15-.65-2.15-2.71-1.47-14.92,4.91-132.63,43.64-176.57,58.05-1.49.49-1.83.96-1.28,2.5,3.83,10.8,43.17,124.15,59.05,170.01.45,1.3.91,1.55,2.22,1.08,48.95-17.23,161.4-56.72,168.66-59.28.48-.17,1.24-.12,1.2-.95Zm-55.04-177.66c-.28-.48-.77-.48-1.22-.58-8.85-1.86-17.71-3.69-26.55-5.62-2.03-.44-3.87-.26-5.82.38-18.5,6.08-113.48,37.07-142.47,46.48-1.56.51-2.13,1.22-2.1,2.89.08,4.24.06,8.48-.14,12.72-.11,2.39-.09,2.57,2.14,1.84,12.7-4.17,147.19-48.34,174.8-57.41.48-.16,1.04-.2,1.36-.7Z"/>
          <path fill="#e8c305" d="m441.53,734.9c.08,11.17-8.91,20.19-20.21,20.27-10.44.08-19.23-8.38-19.25-18.55-.03-11.54,8.88-20.62,20.32-20.69,10.54-.07,19.08,8.39,19.15,18.96Z"/>
          <path fill="#e8c305" d="m447.92,617.96c-.46,1.11-1.33,1.41-2.11,1.78-7.3,3.48-14.99,5.84-22.76,8.01-3.32.93-6.68,1.68-10.11,2.11-.49.06-1.2.35-1.4-.24-.18-.55.51-.83.96-1.07,4.06-2.23,8.41-3.74,12.77-5.2,6.3-2.1,12.64-4.06,19.2-5.21,1.1-.19,2.17-.18,3.45-.17Z"/>
          <path fill="#e8c305" d="m399.84,629.07c-2.88,3.4-29.45,12.03-34.75,11.38,2.23-3.06,27.86-11.5,34.75-11.38Z"/>
          <path fill="#e8c305" d="m355.98,639.57c-10.51,5.27-21.56,8.89-33.47,11.06,2.77-3.35,28.6-11.91,33.47-11.06Z"/>
        </g>
      </g>
    </svg>
  )
}