export const SignOutIcon = ({ size }) => {
    return (
      <svg
        width={size ? size : 22}
        height={size ? size : 22}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.44444 22C1.77222 22 1.19676 21.7606 0.718056 21.2819C0.239352 20.8032 0 20.2278 0 19.5556V2.44444C0 1.77222 0.239352 1.19676 0.718056 0.718056C1.19676 0.239352 1.77222 0 2.44444 0H11V2.44444H2.44444V19.5556H11V22H2.44444ZM15.8889 17.1111L14.2083 15.3389L17.325 12.2222H7.33333V9.77778H17.325L14.2083 6.66111L15.8889 4.88889L22 11L15.8889 17.1111Z"
          fill="#C5C5C5"
        />
      </svg>
    );
  };