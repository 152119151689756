export const SmallFlame39 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="463.35 863.74 517.53 730.74 522.64 621.07 356.35 581.79 276.15 707.36 306.16 813.09 463.35 863.74"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m443.73,697.25c-14.7-13.55-48.67,5.37-29.2,22.81,4.07,3.1,8.81,4.22,13.43,4.36,14.55,1.29,29.96-15.83,15.77-27.16Z"/>
          <path fill="#e8c305" d="m398.94,684.49c-13.83-11.44-45.82,5.66-29.16,22.45,4.02,3.55,8.93,4.57,14.03,4.84,14.44.82,30.21-16.3,15.12-27.28Z"/>
          <path fill="#e8c305" d="m327.71,675.7c-7.77,7.49-6.18,17.73,3.67,21.83,11.68,5.3,29.85-.69,30.65-15.04-1.4-15.77-25.19-16.25-34.32-6.79Z"/>
          <path fill="#e8c305" d="m464.03,628.31c-25.24-1.27-28.87,27.14-1.99,27.83,23.34-.2,26.65-26.04,1.99-27.83Z"/>
          <path fill="#e8c305" d="m421.89,618.01c-21.62-.69-29.25,23.81-4.95,27.01,24.15,2.43,32.39-25.2,4.95-27.01Z"/>
          <path fill="#e8c305" d="m375.36,634.31c22.2,2.99,34.29-23.06,7.56-26.25-7-.06-12.02,1.53-16.19,4.68-11.23,8.44-2.86,21.16,8.62,21.57Z"/>
          <path fill="#e8c305" d="m314.1,740.83c-5.09,1.26-4.68,6.76-2.32,10.25,5.73,8.16,15.35,13.47,25.3,14.16,22.72-1.49-7.35-29.24-22.99-24.41Z"/>
          <path fill="#e8c305" d="m369.67,780.16c-6.51-2.01-16.48.24-11.41,8.82,5.52,8.32,15.48,13.03,25.27,13.86,4.43.18,9.58-2.63,7.34-7.69-3.87-8.24-12.74-12.79-21.2-14.99Z"/>
          <path fill="#e8c305" d="m409.05,816.47c-4.17-.04-9.05,1.84-7.61,6.54,3.57,8.49,12.97,12.68,21.41,14.74,7,2,16.37-.97,10.22-9.31-6.26-7.5-14.56-10.92-24.03-11.96Z"/>
          <path fill="#e8c305" d="m480.73,737.25c-5.03,9.31-8.01,19.8-8.67,30.36-.21,2.04.03,6.55,2.89,3.62,6.2-8.76,8.55-19.72,10.16-30.18.71-4.35-.41-10.55-4.38-3.8Z"/>
          <path fill="#e8c305" d="m509.56,671.55c-4.39,9.71-8.13,20.55-7.34,31.28,3.86,7.59,12.42-27.52,11.63-30.32.18-5.35-2.1-5.53-4.29-.96Z"/>
          <path fill="#e8c305" d="m498.19,727.74c-5.47,1.08-11.08,25.9-11.07,31.49-.09,4.55,1.75,4.85,3.86.97,4.69-9.16,7.77-19.41,8.18-29.69-.04-.97.18-2.49-.97-2.76Z"/>
          <path fill="#e8c305" d="m482.32,786.7c-5.23,7.76-7.48,17.19-9.27,26.26-.89,3.88-.68,11.93,3.58,4.51,4.52-8.74,7.39-18.43,8.17-28.24.28-1.86-.39-5.21-2.49-2.54Z"/>
          <path fill="#e8c305" d="m507.24,725.92c-2.81,5.95-4.61,12.22-5.93,18.64-.47,1.62-2.51,13.97,1.59,9.11,4.96-8.53,7.4-18.42,8.35-28.19.42-6.2-2.61-2.89-4,.44Z"/>
          <path fill="#e8c305" d="m507.68,593.35c-5.82-3.14-10.96-6.83-15.38-11.62-17.53-18.64-33.46-37.12-50.39-56.8-6.21,13.14-15.56,22.95-25.43,32.29-44.73-2.02-82.39,15.21-100.99,57.44-4.69,10.28-12.92,15.96-20.61,23.06-26.24-11.62-52.47-22.76-75.4-42.7,15.45,45.82,15.53,92.6,17.97,140.06.38,41.22,9.98,78.19,48.27,99.48,36.23,21.35,78.86,21.57,118.85,11.09,19.14,6.28,38.29,12.55,57.42,18.85,1.14.38,1.59.21,2.06-.94,7.99-19.94,16.26-39.8,24.5-59.66,66.59-47.07,106.68-163.86,19.13-210.55Zm-50.46,267.17c-48.71-15.95-97.42-31.91-146.17-47.77-1.18-.38-1.78-.97-2.14-2.16-9.45-32.23-20.04-64.6-28.97-96.75,57.65,16.37,115.54,34.21,173.28,51.03.98.29,1.35.72,1.39,1.71.1,2.88.29,5.75.41,8.63,1.12,27.25,2.21,54.5,3.55,81.74.01,0,.03,0,.04,0-.33,1.23.97,4.54-1.39,3.57Zm.41-105.97c-1.13,2.48-1.11,2.49-3.67,1.74-8.03-2.42-16.08-4.74-24.2-6.84-48.82-14.26-97.51-28.93-146.37-43.05-1.45-.38-2.34-.45-1.37-1.99,25.33-39.62,50.55-79.31,75.79-118.99.73-1.15,1.41-1.49,2.78-1.15,52.18,13.1,105.13,24.93,156.97,38.48-19.98,43.93-39.99,87.85-59.94,131.8Zm4.79,8.86c18.44-45.27,40.09-89.68,59.66-134.63.11.01.21.02.32.03-1.57,29.36-3.17,58.72-4.8,88.08-.81,6.13.38,12.84-2.11,18.59-16.68,40.48-33.15,81.06-50,121.47-1.86-30.79-1.73-62.46-3.08-93.54Z"/>
          <path fill="#e8c305" d="m246.38,367.28c-.67-6.96-4.15-11.73-9.95-16.14-3.84,5.43,3.65,18.2,9.95,16.14Z"/>
        </g>
      </g>
    </svg>
  )
}