export const SmallFlame19 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="297.34 654.47 449.09 605.11 483.62 612.78 536.82 792.89 360.14 853.02 296.87 674.09 297.34 654.47"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m530.6,759.74c42.07-50.31,40.55-138.44-25.3-168.22-24.63-17.91-41.22-44.51-62.38-66.37-6.16,13.11-15.53,22.09-25.12,32.1-44.49-2.18-82.89,15.17-101.25,57.28-4.67,10.28-12.83,16.07-20.7,23.18-26.15-11.59-52.43-22.73-75.24-42.58,11.28,39.9,18.21,79.83,16.15,121.4-.95,10.43,3.18,20.36,2.92,30.69-4.83,65.59,57.52,107.11,117.51,105.29.45,1.29.89,2.56,1.31,3.78.42,1.22.88,1.31,1.99.91,3-1.07,10.44-3.69,20.73-7.31,29.12-3.32,56.03-12.86,81.09-27.65-.09-.26-.19-.53-.28-.79,29.94-10.53,59.34-20.86,77.12-27.09,1.62-.57,1.3-1.34.98-2.44-1.72-5.81-5.18-17.47-9.54-32.19Zm-229.97-92.27c.16-3.88.18-7.77.1-11.65-.01-.61.06-1.09.23-1.48,43.98-14.97,88.17-29.27,132.25-43.93,5.3-1.73,9.46-3.1,12.09-3.96,1.96-.64,3.79-.82,5.82-.38,3.82.83,7.64,1.64,11.46,2.45,4.78,1.54,9.57,3.08,14.39,4.63-28.75,9.45-161.63,53.09-174.25,57.23-.67.22-1.12.34-1.44.36-.22-1.09-.44-2.18-.65-3.27Zm232.1,123.4c-7.26,2.57-119.71,42.05-168.66,59.28-1.31.46-1.76.22-2.22-1.08-15.19-43.87-51.86-149.53-58.35-168.04-.28-1.2-.55-2.41-.81-3.62.2-.37.66-.61,1.4-.86,43.94-14.41,161.64-53.14,176.57-58.05,2.06-.68,2.07-.68,2.71,1.47,13.96,46.96,47.43,159.43,50.2,168.71.12.4.23.8.36,1.24.04.83-.71.78-1.2.95Z"/>
          <path fill="#e8c305" d="m422.38,715.94c-11.44.08-20.35,9.15-20.32,20.69.03,10.16,8.81,18.62,19.25,18.55,11.3-.08,20.29-9.1,20.21-20.27-.07-10.58-8.61-19.03-19.15-18.96Z"/>
          <path fill="#e8c305" d="m412.94,629.85c3.43-.43,6.79-1.18,10.11-2.11,7.76-2.16,15.46-4.53,22.76-8.01.78-.37,1.65-.67,2.11-1.78-1.28-.01-2.35-.03-3.45.17-6.56,1.15-12.91,3.11-19.2,5.21-4.36,1.46-8.72,2.97-12.77,5.2-.44.24-1.14.52-.96,1.07.19.59.91.31,1.4.24Z"/>
          <path fill="#e8c305" d="m399.84,629.07c-6.89-.11-32.52,8.33-34.75,11.38,5.31.64,31.87-7.98,34.75-11.38Z"/>
          <path fill="#e8c305" d="m322.51,650.64c11.91-2.18,22.96-5.8,33.47-11.06-4.87-.84-30.71,7.71-33.47,11.06Z"/>
          <path fill="#e8c305" d="m246.85,367.31c0-7.5-4.9-14.49-10.12-14.83-1.43,6.78,3.53,14.28,10.12,14.83Z"/>
        </g>
      </g>
    </svg>
  )
}