export const NoFlame51 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="274.6 732.87 317.65 815.24 471.88 858.51 557.69 704.27 479.04 644.24 345.88 584.93 274.6 732.87"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m345.86,584.74c43.6,19.22,86.99,38.95,130.6,58.16,4.96,2.19,8.97,5.57,13.09,8.83,22.4,17.69,45.3,34.72,67.91,52.14,1.3.98,1.34,1.67.57,3.04-9.05,16.37-18.44,32.56-27.56,48.89-18.77,34.39-38.25,68.38-57.18,102.68-.66,1.22-1.27,1.41-2.57,1.05-51.26-14.45-102.53-28.89-153.81-43.26-14.67-21.73-24.73-48.12-37.83-71.39-1.62-3.17-3.2-6.36-4.9-9.49-.61-1.13-.62-2.04-.05-3.2,24.16-48.94,47.14-98.98,71.73-147.45Zm124.6,269.74c27.7-47.75,54.05-96.99,81.37-145.19.59-1.05.32-1.43-.67-1.81-37.06-15.56-74.11-31.16-111.14-46.81-10.37-4.37-20.76-8.71-31.11-13.14-1.26-.54-1.74-.38-2.35.84-27.46,54.26-55.1,108.42-82.72,162.59-.55,1.08-.74,1.61.74,2.03,48.65,13.78,97.24,27.74,145.9,41.49Zm-154.07-46.34c28.32-54.01,55.17-109.07,83.06-163.41.7-1.37.56-2.14-.54-3.18-11.9-11.2-23.74-22.45-35.6-33.69-5.78-5.47-11.57-10.94-17.32-16.43-.85-.81-1.3-.98-1.9.26-22.28,46.27-44.72,92.47-67.12,138.69-.46.95-.46,1.72.03,2.65,13.18,25.02,26.14,50.14,39.4,75.12Zm234.53-107.16c-.11-1.02-.61-1.32-.99-1.61-21.35-16.23-42.41-32.83-63.74-49.09-2.78-2.13-5.28-4.66-8.52-6.12-41.52-18.22-82.91-37.63-124.54-55.18,15.43,15.53,32.81,29.95,48.81,45.2,3.64,3.36,7.45,6.18,12.12,8.13,45.66,19.54,91.5,38.47,136.87,58.67Zm-198.21-112.33s-.08.07-.12.1c.08.12.14.12.22,0-.03-.04-.07-.07-.1-.11Z"/>
          <path fill="#e8c305" d="m352.71,588.65s.07.07.1.11c-.04.03-.08.09-.12.09-.04,0-.07-.06-.11-.1.04-.03.08-.06.12-.1Z"/>
          <path fill="#e8c305" d="m427.38,685.3c12.46.09,18.82,10.95,15.12,21.74-2.8,8.16-8.52,13.27-16.9,15.23-10.2,2.39-19.01-4.04-19.78-14.39-.35-4.73,1.33-9.01,3.98-12.8,3.56-5.08,8.45-8.34,14.63-9.46.97-.18,1.97-.22,2.96-.33Z"/>
          <path fill="#e8c305" d="m400.96,783.92c-.4,6.17-4.31,11.76-10.69,15.68-5.25,3.22-10.89,4.35-16.97,2.38-6.85-2.21-10.92-8.85-9.62-15.93,1.97-10.73,12.32-18.88,23.19-18.26,8.34.47,14.22,6.56,14.1,16.14Z"/>
          <path fill="#e8c305" d="m444.6,721.86c.02-9.85,8.71-19.75,18.5-21.04,6.98-.92,12.68,1.55,15.71,6.99,2.67,4.79,2.43,9.82.36,14.68-3.18,7.46-8.72,12.4-16.81,13.88-10.08,1.84-17.9-4.85-17.75-14.51Z"/>
          <path fill="#e8c305" d="m438.42,795.35c-.35,6.11-4.13,11.55-10.15,15.56-3.74,2.49-7.9,3.78-12.46,3.47-9.42-.64-14.95-8.01-12.86-17.16,2.16-9.46,12.15-17.14,21.85-16.8,8.11.28,13.61,5.77,13.62,14.93Z"/>
          <path fill="#e8c305" d="m515.75,729.13c.07,10.98-10.83,21.37-22.19,20.71-7.62-.44-13.11-7.08-12.34-14.89.92-9.27,8-17.4,17.07-19.47,5.29-1.21,10.11-.22,13.99,3.76,2.53,2.6,3.51,5.87,3.47,9.89Z"/>
          <path fill="#e8c305" d="m452.81,825.33c-9.21.04-14.96-7.19-12.87-16.18,2.11-9.08,11.17-16.53,20.41-16.77,9.37-.24,15.45,7.28,13.11,16.52-2.43,9.6-11.75,16.49-20.64,16.42Z"/>
          <path fill="#e8c305" d="m314.73,758.47c.73-9.51,3.4-18.24,10.14-25.37.8-.84,1.74-1.52,2.82-1.96,2.31-.94,4.26-.1,5.26,2.19,1.31,3,1.21,6.18.84,9.28-.91,7.61-3.52,14.6-8.24,20.74-.84,1.09-1.84,2.01-2.94,2.81-3.25,2.39-6.18,1.36-7.33-2.54-.5-1.68-.47-3.41-.56-5.17Z"/>
          <path fill="#e8c305" d="m347.68,629.13c-.64,8.97-3.69,16.99-9.65,23.82-.97,1.11-2.17,1.98-3.55,2.54-1.87.76-3.44.13-4.23-1.71-1-2.34-.98-4.79-.61-7.25,1.24-8.26,4.27-15.71,10.07-21.87.36-.38.76-.72,1.18-1.03,1.34-1,2.85-1.87,4.5-1.08,1.64.8,2.11,2.51,2.27,4.23.07.78.01,1.57.01,2.35Z"/>
          <path fill="#e8c305" d="m445.19,650.48c-1.97-.33-3.91-.99-5.8-1.76-8.36-3.41-16.38-7.49-23.81-12.65-1.07-.74-2.03-1.65-3.02-2.5-.35-.31-.74-.7-.48-1.21.27-.54.85-.36,1.27-.3,1.77.25,3.46.83,5.11,1.49,9.08,3.61,17.75,7.99,25.68,13.76.67.49,1.26,1.1,1.87,1.67.32.29.61.66.45,1.1-.22.6-.79.35-1.28.41Z"/>
          <path fill="#e8c305" d="m484.82,662.98c2.57.39,4.94,1.4,7.27,2.45,7.99,3.58,15.75,7.59,22.73,12.95.73.56,1.99,1.26,1.56,2.08-.55,1.05-1.82.2-2.68-.08-6.12-1.93-11.79-4.87-17.37-7.99-3.84-2.14-7.66-4.33-11.01-7.22-.4-.34-.75-.73-1.11-1.11-.21-.22-.49-.47-.34-.81.19-.43.6-.33.96-.28Z"/>
          <path fill="#e8c305" d="m382.21,606.1c1.5-.28,2.73.23,3.95.65,7.41,2.56,14.32,6.18,21.05,10.13,2.3,1.35,4.6,2.72,6.52,4.62.28.28.77.56.5,1.03-.25.43-.78.26-1.16.19-2.48-.49-4.82-1.42-7.12-2.42-7.46-3.23-14.71-6.87-21.37-11.57-.93-.66-1.93-1.26-2.37-2.63Z"/>
          <path fill="#e8c305" d="m449.75,635.98c1.17-.37,2.06-.02,2.94.28,7.15,2.48,13.78,6.03,20.26,9.87,2.2,1.31,4.39,2.65,6.28,4.39.35.32,1.04.68.68,1.24-.31.48-.96.14-1.45.02-2.49-.65-4.86-1.62-7.19-2.69-6.27-2.89-12.4-6.06-18.13-9.95-1.24-.84-2.47-1.72-3.4-3.16Z"/>
        </g>
      </g>
    </svg>
  )
}