export const NoFlame43 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="290.24 756.99 352.31 583.81 529.69 638.27 477.44 816.05 458.12 844.36 303.26 796.46 290.24 756.99"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m351.61,581.88c.75.22,1.36.38,1.96.57,37.13,11.3,74.25,22.6,111.38,33.9,21.59,6.57,43.18,13.16,64.79,19.68,1.52.46,1.92.92,1.46,2.56-11.15,39.53-22.24,79.07-33.34,118.61-5.27,18.79-10.55,37.58-15.81,56.37-.36,1.28-.92,2.42-1.72,3.49-3.02,4.09-5.98,8.21-8.98,12.31-3.62,4.95-7.26,9.88-10.87,14.83-.64.88-1.27,1-2.35.66-49.34-15.3-98.69-30.56-148.05-45.82-2.15-.67-4.28-1.39-6.45-1.99-.9-.25-1.4-.68-1.71-1.58-4.5-12.9-9.03-25.78-13.57-38.66-.3-.85-.22-1.61.08-2.43,7.51-20.6,15.01-41.2,22.51-61.8,11.96-32.85,23.92-65.69,35.88-98.54,1.34-3.69,2.67-7.38,4.03-11.06.16-.44.18-1.07.76-1.09Zm3.57,6.18c-.74-.08-.67.69-.84,1.16-4.14,11.41-8.27,22.83-12.4,34.25-15.29,42.28-30.57,84.57-45.89,126.84-.47,1.3-.31,1.72,1.04,2.13,29.43,9.03,58.84,18.11,88.26,27.18,30.27,9.33,60.53,18.65,90.79,28,1.07.33,1.51.25,1.84-.94,4.02-14.36,8.09-28.71,12.15-43.06,11.51-40.68,23.02-81.37,34.59-122.03.42-1.48.15-1.93-1.27-2.36-23.05-6.97-46.08-13.99-69.12-21.01-31-9.44-62-18.88-92.99-28.32-2-.61-4.01-1.2-6.15-1.85Zm-59.96,171.08c-1.52-.15-.83.82-.65,1.38,3.82,11.75,7.67,23.48,11.48,35.23.33,1.01.85,1.5,1.87,1.82,29.01,8.93,58.02,17.9,87.02,26.87,19.75,6.11,39.51,12.22,59.25,18.35,1.09.34,1.77.26,2.49-.79,5.79-8.59,11.65-17.14,17.49-25.7,1.07-1.56,1.06-1.58-.7-2.13-16.75-5.18-33.5-10.37-50.26-15.54-39.51-12.2-79.03-24.39-118.54-36.58-3.15-.97-6.3-1.94-9.46-2.9Z"/>
          <path fill="#e8c305" d="m445.01,731.53c11.98.13,21.26,9.41,20.54,21.02-.58,9.21-6.89,16.25-16.53,17.6-10.11,1.42-19.92-4.62-22.81-14.76-2.94-10.34,4.15-21.61,14.9-23.49,1.29-.23,2.59-.37,3.9-.37Z"/>
          <path fill="#e8c305" d="m399.9,717.71c9.43.09,17.43,6.02,19.68,14.59,2.76,10.51-3.88,21.14-14.52,23.5-8.43,1.87-17.82-1.86-22.45-9.51-5.14-8.48-1.99-20.66,6.82-25.72,3.25-1.86,6.7-2.92,10.47-2.86Z"/>
          <path fill="#e8c305" d="m354.99,742.28c-12.42-.51-20.78-9.33-20.25-19.91.53-10.63,9.44-18.57,20.2-18.47,9.63.09,17.77,6.4,19.59,15.19,2.21,10.71-5.25,21.23-16.21,22.85-1.29.19-2.58.44-3.33.35Z"/>
          <path fill="#e8c305" d="m472.7,684.44c-9.1.09-17.07-5.27-19.76-13.06-3.47-10.04,2.43-20.51,12.85-22.79,10.32-2.25,20.86,3.59,23.87,13.05,3.11,9.77-2.84,20.32-13.74,22.44-1.18.23-2.38.38-3.22.35Z"/>
          <path fill="#e8c305" d="m426.85,634.79c8.62,0,16.67,5.66,18.98,13.34,2.79,9.29-2.16,18.56-11.65,21.8-9.17,3.12-19.65-1-24.2-9.53-4.16-7.8-1.74-17.39,5.62-22.29,3.47-2.31,6.92-3.33,11.25-3.33Z"/>
          <path fill="#e8c305" d="m383.42,621.51c6.7.38,12.38,2.78,16.32,8.47,5.12,7.39,3.74,17.11-3.18,22.87-8.93,7.42-22.9,5.54-29.42-3.83-6.41-9.2-2.31-21.45,7.71-25.8,2.72-1.18,5.56-1.81,8.57-1.71Z"/>
          <path fill="#e8c305" d="m325.47,775.71c3.75.06,7.37.89,10.95,1.89,6.47,1.81,12.79,4.01,18.57,7.52.99.6,1.9,1.3,2.66,2.19.95,1.12.8,1.72-.62,2.1-2.13.56-4.29.23-6.39-.15-8.7-1.59-17.05-4.25-24.82-8.56-1.19-.66-2.32-1.41-3.31-2.37-.53-.51-.91-1.09-.65-1.8.24-.68.92-.8,1.57-.81.68-.02,1.36,0,2.04,0Z"/>
          <path fill="#e8c305" d="m368.94,798.63c3.8.07,7.46.93,11.09,1.94,6.11,1.7,12.1,3.74,17.55,7.1,1.09.67,2.75,1.47,2.35,2.76-.35,1.15-2.16.94-3.41.88-5.75-.3-11.23-1.85-16.65-3.66-4.22-1.41-8.37-3.03-12.16-5.42-.75-.47-1.52-.94-2.05-1.68-.71-.99-.44-1.62.77-1.87.15-.03.31-.05.47-.05.68,0,1.36,0,2.04,0Z"/>
          <path fill="#e8c305" d="m408.32,819.41c3.63.07,7.13.9,10.62,1.83,5.47,1.46,10.8,3.28,15.81,5.97.97.52,1.9,1.12,2.67,1.9.83.84.65,1.43-.51,1.77-1.82.53-3.66.15-5.44-.15-8.03-1.35-15.77-3.68-23.05-7.38-1.12-.57-2.2-1.21-3.06-2.15-.28-.31-.64-.61-.51-1.08.15-.55.68-.65,1.13-.69.78-.07,1.57-.02,2.35-.02Z"/>
        </g>
      </g>
    </svg>
  )
}