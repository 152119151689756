import { MouseEventHandler } from "react";
import { EditIcon } from "../assets/EditIcon";
import { SupportIcon } from "../assets/SupportIcon";
import { SignOutIcon } from "../assets/SignOutIcon";


const AccountTileStyle: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    flexGrow: "1",
    padding: "1rem",
    background: "#181c27",
    border: "2px solid #3F3D48",
    borderRadius: "6px",
    marginBottom: "1.5rem",
  },
  icon: {
    paddingRight: "1rem",
  },
  label: {
    textAlign: "center",
    color: "#c5c5c5",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
  }
};

type Props = {
  label: string,
  icon?: "edit" | "support" | "signout" | "none",
  iconSize?: number,
  onClick: MouseEventHandler<HTMLDivElement> | undefined,
}

export const AccountTile = ({
  label,
  icon = "none",
  iconSize = 19,
  onClick,
}: Props) => {
    return (
      <div onClick={onClick} style={AccountTileStyle.container} className="account-tile">
        <div style={AccountTileStyle.icon}>
          {
            icon == "edit" ?
            <EditIcon size={iconSize}></EditIcon> :
            null
          }
          {
            icon == "support" ?
            <SupportIcon size={iconSize}></SupportIcon> :
            null
          }
          {
            icon == "signout" ?
            <SignOutIcon size={iconSize}></SignOutIcon> :
            null
          }
        </div>
        <label style={AccountTileStyle.label}>{label}</label>
      </div>
    );
  };