export const CashierBankIcon = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "97"} 
      height={props?.h ?? "98"} 
      viewBox={props?.v ?? "0 0 97 98"}
    >
      <g opacity="0.5" filter="url(#filter0_d_1044_583)">
      <circle cx="36.5" cy="33.2617" r="26.5" fill="#284F91"/>
      </g>
      <g filter="url(#filter1_d_1044_583)">
      <ellipse cx="36.5049" cy="33.2669" rx="20.2686" ry="20.2686" fill="#4C80D5"/>
      </g>
      <path d="M29.3 38.3618V30.6618H31.5V38.3618H29.3ZM35.9 38.3618V30.6618H38.1V38.3618H35.9ZM26 42.7618V40.5618H48V42.7618H26ZM42.5 38.3618V30.6618H44.7V38.3618H42.5ZM26 28.4618V26.2618L37 20.7618L48 26.2618V28.4618H26ZM30.895 26.2618H43.105L37 23.2368L30.895 26.2618Z" fill="#C5C5C5"/>
      <defs>
      <filter id="filter0_d_1044_583" x="0" y="0.761719" width="97" height="97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_583"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_583" result="shape"/>
      </filter>
      <filter id="filter1_d_1044_583" x="6.23633" y="6.99829" width="84.5371" height="84.5371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_583"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_583" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}