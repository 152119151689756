export const NoFlame31 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="283.93 629.44 439.82 621.1 527.07 636.49 526.14 801.35 436.37 823.15 283.5 808.19 283.93 629.44"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m282.07,718.54c0-29.8.01-59.6-.04-89.41,0-1.6.44-2,1.97-2.08,15.23-.74,30.45-1.54,45.68-2.35,21.24-1.12,42.48-2.26,63.72-3.4,14.65-.78,29.29-1.59,43.94-2.37,3.38-.18,6.65.75,9.95,1.25,15.16,2.31,30.31,4.72,45.46,7.09,11.33,1.77,22.65,3.58,33.98,5.3,1.39.21,1.17,1.03,1.17,1.9-.11,17.65-.22,35.3-.34,52.95-.12,18.64-.24,37.29-.36,55.93-.11,17.18-.22,34.36-.34,51.53-.02,2.36-.17,4.72-.06,7.07.07,1.41-.5,1.84-1.76,2.15-22.69,5.53-45.37,11.1-68.05,16.66-5.75,1.41-11.51,2.74-17.22,4.27-2.18.58-4.27.2-6.38,0-18.77-1.82-37.53-3.69-56.3-5.55-18.4-1.83-36.8-3.68-55.2-5.49-6.88-.68-13.77-1.26-20.65-1.94-6.09-.6-12.18-1.3-18.27-1.87-1.36-.13-1.11-.97-1.1-1.77.03-12.57.08-25.14.1-37.71.02-17.39,0-34.78,0-52.17h.11Zm151.21,3.64h.19c0-11.94-.03-23.88,0-35.82.06-19.85.17-39.7.26-59.54,0-.92.27-1.87-1.34-1.77-7.06.44-14.12.79-21.18,1.13-15.12.75-30.23,1.47-45.35,2.21-10.67.52-21.34,1.04-32.01,1.56-15.38.75-30.76,1.51-46.14,2.2-1.31.06-1.64.44-1.64,1.72-.05,56.56-.12,113.12-.25,169.69,0,1.5.54,1.86,1.87,1.94,2.92.18,5.84.48,8.76.76,9.48.9,18.97,1.81,28.45,2.71,8.23.78,16.47,1.53,24.7,2.31,9.54.9,19.07,1.82,28.61,2.72,8.18.77,16.37,1.52,24.55,2.3,9.48.9,18.97,1.79,28.45,2.74,1.38.14,1.78-.19,1.78-1.63.05-22.36.18-44.72.26-67.09.03-9.37,0-18.75,0-28.12Zm7.87-.35c0,31.74,0,63.47-.01,95.21,0,1.06-.09,1.73,1.46,1.36,26.84-6.33,53.7-12.59,80.55-18.84,1.04-.24,1.47-.6,1.46-1.74-.01-4.55.11-9.11.14-13.66.12-17.8.22-35.61.34-53.41.1-15.08.21-30.16.29-45.24.08-15.55.12-31.11.22-46.66,0-1.18-.34-1.66-1.5-1.82-9.53-1.35-19.06-2.74-28.59-4.12-11.96-1.74-23.92-3.47-35.88-5.21-5.28-.77-10.57-1.51-15.84-2.33-1.39-.22-1.83.15-1.79,1.59.07,2.82-.08,5.65-.1,8.48-.15,28.8-.04,57.61-.75,86.41Z"/>
          <path fill="#e8c305" d="m410.42,769.4c.05,6.26-1.61,11.16-5.45,15.29-5.9,6.34-15.79,5.81-21.74-.63-6.67-7.23-7.97-18.57-3.09-27.13,3.27-5.74,10.17-9.46,16.73-7.43,4.88,1.51,8.34,4.63,10.74,9.02,1.97,3.6,3.02,7.44,2.81,10.88Z"/>
          <path fill="#e8c305" d="m371.38,721.82c-.25,5.31-1.96,10.92-6.99,15.06-5.83,4.8-13.73,4.11-18.83-1.6-6.94-7.75-7.56-19.92-1.45-28.3,5.87-8.05,17.06-7.93,22.94.24,2.94,4.09,4.31,8.66,4.34,14.6Z"/>
          <path fill="#e8c305" d="m304.74,674.8c.06-5.69,1.59-10.86,5.66-15.01,6.05-6.18,14.84-5.48,19.95,1.51,5.84,7.99,5.3,20.35-1.21,27.7-5.79,6.54-15.3,5.97-20.39-1.23-2.75-3.88-4.07-8.21-4-12.97Z"/>
          <path fill="#e8c305" d="m474.89,674.51c-.02,4.81-.74,9.47-2.84,13.86-.48,1-1.07,1.91-1.76,2.78-2.99,3.78-7.2,3.78-10.2,0-2.06-2.6-3.23-5.61-3.91-8.81-1.44-6.83-1.35-13.6,1-20.25.65-1.84,1.55-3.54,2.8-5.05,2.78-3.37,6.63-3.53,9.58-.32,3.15,3.43,4.2,7.74,4.94,12.17.31,1.86.39,3.74.39,5.63Z"/>
          <path fill="#e8c305" d="m454.76,769.39c.12-5.87,1.06-11.55,4.14-16.69.78-1.31,1.75-2.46,3-3.37,2.41-1.75,4.9-1.68,7.16.26,1.68,1.44,2.76,3.33,3.43,5.37,3.11,9.36,2.64,18.54-1.82,27.39-1.16,2.3-2.75,4.32-5.48,5.04-2.15.56-3.93-.02-5.44-1.56-2.64-2.7-3.71-6.13-4.39-9.72-.42-2.21-.7-4.44-.61-6.71Z"/>
          <path fill="#e8c305" d="m477.95,720.7c.06-4.86.71-9.59,2.85-14.03.74-1.52,1.66-2.91,2.95-4.03,2.25-1.95,4.89-1.92,7.05.16,1.58,1.52,2.59,3.42,3.26,5.48,2.69,8.22,2.46,16.37-.68,24.42-.57,1.47-1.4,2.82-2.46,3.99-2.8,3.12-6.39,2.91-8.9-.45-1.86-2.5-2.79-5.38-3.39-8.37-.48-2.36-.74-4.76-.68-7.18Z"/>
          <path fill="#e8c305" d="m515.44,676.7c.13,4.12-.51,8.02-1.96,11.78-.57,1.48-1.33,2.85-2.36,4.05-2.15,2.49-4.75,2.59-7.08.3-1.89-1.87-2.88-4.23-3.57-6.72-1.92-6.88-1.87-13.75.23-20.58.62-2.01,1.52-3.91,3.01-5.45,2.32-2.4,5.16-2.33,7.32.22,2.5,2.95,3.41,6.55,4.04,10.24.35,2.07.46,4.16.37,6.17Z"/>
          <path fill="#e8c305" d="m498.64,762.16c.03-3.73.71-8.31,2.65-12.64.74-1.64,1.68-3.14,3.06-4.32,2.3-1.96,4.78-1.73,6.71.63,2.14,2.6,3.01,5.72,3.51,8.96,1.01,6.54.46,12.94-2.03,19.11-.69,1.71-1.63,3.29-3,4.56-2.25,2.09-4.68,2.04-6.75-.21-2.34-2.53-3.12-5.75-3.71-9.02-.36-2.01-.44-4.06-.44-7.07Z"/>
        </g>
      </g>
    </svg>
  )
}