export const CashierAmountIcon = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "97"} 
      height={props?.h ?? "97"} 
      viewBox={props?.v ?? "0 0 97 97"}
    >
      <g opacity="0.7" filter="url(#filter0_d_1044_574)">
      <circle cx="36.5" cy="32.5" r="26.5" fill="#284F91"/>
      </g>
      <g filter="url(#filter1_d_1044_574)">
      <circle cx="36.5049" cy="32.505" r="20.2686" fill="#4C80D5"/>
      </g>
      <g filter="url(#filter2_d_1044_574)">
      <path d="M35.3002 46.505V43.2889C34.401 43.2573 33.5017 43.1358 32.6025 42.9246C31.7033 42.7028 30.8781 42.4123 30.127 42.0532V38.1876C30.8463 38.6628 31.6662 39.0853 32.5866 39.455C33.5176 39.8141 34.4221 40.0095 35.3002 40.0412V34.2427C34.1048 33.7674 33.1103 33.2499 32.3169 32.6901C31.534 32.1198 30.9521 31.4596 30.5713 30.7097C30.1904 29.9493 30 29.0568 30 28.0323C30 26.9339 30.2222 25.9886 30.6665 25.1964C31.1214 24.4043 31.7456 23.7706 32.539 23.2953C33.343 22.82 34.2634 22.519 35.3002 22.3923V20H37.3473V22.3606C38.1407 22.4028 38.9395 22.5401 39.7435 22.7725C40.5475 23.0049 41.3198 23.3217 42.0604 23.7231L41.0765 26.9392C40.4946 26.6117 39.8863 26.353 39.2516 26.1629C38.6168 25.9622 37.9821 25.8302 37.3473 25.7668V31.3118C38.5216 31.7659 39.4843 32.2571 40.2354 32.7852C40.9972 33.3027 41.5579 33.9153 41.9175 34.6229C42.2878 35.3306 42.473 36.1861 42.473 37.1895C42.473 38.7315 42.0286 40.0412 41.14 41.1185C40.2513 42.1958 38.9871 42.8876 37.3473 43.1939V46.505H35.3002ZM37.3473 39.7877C37.9503 39.6081 38.3947 39.3124 38.6803 38.9005C38.9659 38.4886 39.1088 38.0133 39.1088 37.4746C39.1088 37.105 39.04 36.7828 38.9025 36.5082C38.7755 36.2231 38.5798 35.9696 38.3153 35.7478C38.0614 35.5154 37.7387 35.3042 37.3473 35.1141V39.7877ZM35.3002 30.4404V25.7985C34.8665 25.883 34.5121 26.0308 34.237 26.2421C33.9725 26.4533 33.7768 26.7174 33.6498 27.0342C33.5335 27.3511 33.4753 27.7102 33.4753 28.1115C33.4753 28.4706 33.5335 28.7875 33.6498 29.0621C33.7768 29.3261 33.9725 29.5691 34.237 29.7909C34.5121 30.0021 34.8665 30.2186 35.3002 30.4404Z" fill="#C5C5C5"/>
      </g>
      <defs>
      <filter id="filter0_d_1044_574" x="0" y="0" width="97" height="97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_574"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_574" result="shape"/>
      </filter>
      <filter id="filter1_d_1044_574" x="6.23633" y="6.23645" width="84.5371" height="84.5371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_574"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_574" result="shape"/>
      </filter>
      <filter id="filter2_d_1044_574" x="20" y="14" width="56.4727" height="70.505" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_574"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_574" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}