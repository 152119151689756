export const BigFlame38 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="273.93 697.51 360.41 584.26 522.29 619.84 520.5 741.55 461.74 866.2 305.53 815.49 273.93 697.51"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m408.81,592.78c-21.91-4.86-40.23-8.92-47.22-10.47-1.29-.29-1.96.04-2.72,1.03-2.83,3.68-10.37,13.42-19.9,25.73-27.02.83-52.76.97-73.72-20.03-3.88,38.21-6.91,74.07-13.66,111.7-12.22-1.87-23.4-4.46-30.43-15.52-5.67,23.86,14.11,44.56,26.46,63.07,4.69,6.64,7.7,13.37,7.87,21.98,1.46,60.56,73.04,78.24,122.62,69.61,27.29,8.91,54.58,17.82,81.86,26.75,1.42.46,1.94.14,2.55-1.15,4.09-8.77,21.6-45.91,36.99-78.53,30.47-23.21,51.21-56.08,58.29-93.7,24.17-101.91-69.25-127.85-148.99-100.47Zm-47.55-5.77c.41-.53.72-1.21,1.6-1.03,3.58.78,136.27,30.1,152.37,33.66,1.87.41,1.86.41.89,2.18-9.56,17.51-52.54,96.18-64.43,118.03-.56,1.04-1.12,1.07-2.12.8-36.12-10-148.53-41.02-168.61-46.56q-1.62-.45-.48-1.94c23.67-30.81,77.52-100.9,80.77-105.14Zm94.32,275.25c-1.44-.49-2.89-.93-4.33-1.4-21.96-7.13-126.15-41-141.35-45.89-1.19-.38-1.75-.97-2.07-2.18-3.98-14.8-23.88-87.84-29.79-109.57-.13-.47-.61-1.04-.15-1.45.42-.38.99.05,1.48.18,25.4,7.11,137.08,38.39,167.54,46.85,1.65.46,2.15,1.15,2.25,2.84.71,11.39,7.03,102.06,7.48,108.69.02,0,.04,0,.06,0,0,.37-.03.73,0,1.1.08.91-.31,1.1-1.13.83Zm7.97-3.22c-.22-3.32-5.57-96.34-6.45-108.7-.19-2.6.33-4.72,1.54-6.97,15.9-29.5,57.38-106.83,62.3-115.96.23-.43.3-1.01.93-1.23.26,1.33-.05,2.61-.08,3.89-.09,4.38-1.72,82.68-2.15,104.73-.63,4.58-1.78,9-3.28,13.32-10.29,21.76-41.59,87.87-51.95,109.73-.19.39-.19.94-.86,1.19Z"/>
          <path fill="#e8c305" d="m225.25,286.51c-14.24-4.44-26.79-13.06-35.19-25.52-4.64,51.51,7.02,121.1,22.13,137.93,4.18-22.26,13.13-42.28,25.1-61.1,9.44-16.19,10.03-45.58-12.04-51.3Z"/>
          <path fill="#e8c305" d="m306.34,444.03c-4.87-37.9-30.8-20.62-40.31-52-5.08,45.51,24.9,35.13,40.31,52Z"/>
          <path fill="#e8c305" d="m408.81,689.88c-5.03,5.33-4.4,12.52,1.42,16.95,4.14,3.15,8.92,4.18,13.73,4.39,6.56-.12,12.43-1.62,17.2-5.94,3.31-2.99,5.44-6.63,4.46-11.4-.65-3.16-2.55-5.45-5.09-7.28-3.27-2.36-6.97-3.57-10.96-3.85-7.91-.55-15.1,1.14-20.75,7.13Z"/>
          <path fill="#e8c305" d="m368.24,696.22c3.02,1.94,6.39,2.84,9.92,3.11,7.95.62,15.05-1.37,20.78-7.15,4.77-4.81,4.46-11.7-.71-16.01-3.98-3.32-8.74-4.3-14.62-4.37-5.96.23-12.13,2.01-17.14,6.93-5.62,5.53-4.86,13.21,1.76,17.48Z"/>
          <path fill="#e8c305" d="m325.27,684.18c4.08,3.2,8.84,4.2,13.88,4,6.94-.28,13.18-2.4,18-7.63,4.62-5.01,4.43-11.63-1.41-15.75-3.61-2.54-7.7-3.41-12.03-3.3-.89,0-1.79-.09-2.67.01-7.06.81-13.3,3.19-17.62,9.24-3.18,4.45-2.44,10.06,1.86,13.43Z"/>
          <path fill="#e8c305" d="m447.66,646.4c3.93,2.59,8.36,3.49,13,3.47,6.11-.03,11.45-1.7,15.48-5.12,4.9-4.15,5.85-10.38.5-15.01-2.8-2.42-6.11-3.61-9.66-4.22-7.11-1.22-13.79-.14-19.71,4.16-6.57,4.77-6.38,12.26.39,16.71Z"/>
          <path fill="#e8c305" d="m407.28,637.07c3.59,2.18,7.59,2.88,11.03,2.97,6.77,0,12.37-1.87,16.62-5.31,5.68-4.59,5.4-11.42-.62-15.52-2.26-1.54-4.78-2.38-7.43-2.83-7.45-1.28-14.42-.13-20.52,4.56-6.26,4.81-5.82,12.02.92,16.12Z"/>
          <path fill="#e8c305" d="m368.29,628.04c2.28,1.22,4.72,1.97,7.31,2.21,7.03.66,13.58-.55,19.23-5.05,6.35-5.06,5.54-12.41-1.74-16-3.16-1.56-6.54-2.14-11.32-2.13-5.26-.03-11.32,2.08-15.38,5.79-5.5,5.03-4.67,11.66,1.9,15.18Z"/>
          <path fill="#e8c305" d="m333.21,736.72c-5.07-4.04-10.73-6.65-17.3-6.94-.52,0-1.05-.04-1.57,0-4.49.44-7.11,3.25-6.77,7.39.12,1.45.43,2.92,1.15,4.21,5.35,9.54,13.52,15.1,24.35,16.04,7.05.61,10.72-4.04,7.9-11.01-1.61-3.99-4.44-7.06-7.76-9.69Z"/>
          <path fill="#e8c305" d="m377.78,777.54c-4.79-3.18-9.98-5.35-15.83-5.3-6.72.06-9.58,4.53-6.94,10.62,1.43,3.3,3.77,5.88,6.51,8.11,5.23,4.27,11.11,7.01,17.56,7.45.53,0,.69,0,.85,0,7.16-.13,10.09-4.93,6.9-11.31-2.05-4.09-5.31-7.09-9.05-9.58Z"/>
          <path fill="#e8c305" d="m420.88,817.15c-4.75-2.74-9.79-4.49-15.39-4.15-6,.37-8.56,4.68-5.95,10.06,1.42,2.94,3.56,5.28,6.09,7.29,5.34,4.24,11.32,6.88,17.76,7.24,1.25-.06,2.03-.06,2.81-.15,3.21-.37,5.56-2.15,6.04-4.67.35-1.85,0-3.65-.85-5.33-2.35-4.61-6.15-7.76-10.51-10.28Z"/>
          <path fill="#e8c305" d="m474.9,760.43c2.15-3.45,3.65-7.19,4.83-11.05,1.96-6.42,3.56-12.92,2.95-19.72-.13-1.39-.24-3.09-1.8-3.57-1.58-.49-2.56.86-3.41,2-3.11,4.19-4.87,9-6.36,13.92-1.51,4.97-2.54,10.04-2.71,15.22.19,1.79-.06,3.6.6,5.31.68,1.76,1.96,2.1,3.41.9,1.02-.84,1.8-1.89,2.5-3.01Z"/>
          <path fill="#e8c305" d="m504.5,701.14c.96-.84,1.68-1.87,2.26-2.99,4.22-8.11,6.31-16.79,6.85-25.87-.09-1.73.11-3.48-.58-5.13-.62-1.45-1.64-1.7-2.83-.67-.96.83-1.67,1.87-2.27,2.98-4.18,7.78-6.15,16.18-6.91,24.9-.18,2.04-.12,4.09.58,6.05.56,1.55,1.66,1.81,2.91.72Z"/>
          <path fill="#e8c305" d="m497.23,726.02c-1.2-.38-1.88.65-2.6,1.39-.29.3-.51.66-.75,1.01-1.22,1.78-2.17,3.7-2.97,5.69-2.86,7.08-4.69,14.4-5.04,22.05-.1,1.12.11,2.2.35,3.27.18.8.38,1.72,1.33,1.97.99.26,1.71-.38,2.29-1.06.54-.63,1.04-1.31,1.47-2.03,3.32-5.49,5.13-11.55,6.42-17.76.82-3.93,1.61-7.89.96-11.96-.17-1.04-.33-2.21-1.48-2.57Z"/>
          <path fill="#e8c305" d="m480.92,786c-.8.7-1.44,1.52-1.97,2.43-4.8,8.25-7.16,17.23-8.02,26.65.16,1.37-.03,2.75.43,4.07.58,1.64,1.66,1.94,2.98.76,1.47-1.32,2.43-3.01,3.3-4.74,3.52-7.05,5.63-14.52,6.53-22.32.23-1.97.32-3.98-.23-5.93-.49-1.75-1.68-2.09-3.02-.92Z"/>
          <path fill="#e8c305" d="m510.04,726.43c-.81.68-1.42,1.54-1.92,2.46-4.21,7.75-6.27,16.1-7.01,24.39.16,1.91-.09,3.4.47,4.81.49,1.24,1.27,1.43,2.29.56,1.09-.93,1.84-2.13,2.49-3.39,3.6-7.02,5.53-14.53,6.41-22.31.22-1.95.38-3.98-.33-5.91-.48-1.31-1.34-1.51-2.41-.61Z"/>
        </g>
      </g>
    </svg>
  )
}