export const NoFlame01 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="286.56 628.49 439.59 623.82 528.51 634.14 526.54 800.19 438.36 822.78 284.84 806.08 286.56 628.49"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m283.92,807.85v-182.54c1.92,0,138.35-4.92,155.02-5.34,3.99-.1,87.46,10.81,90.62,11.21,0,.57-2.08,158.38-2.18,168.76-.01,1.23-.28,1.79-1.58,2.14-28.55,7.72-57.08,15.55-85.65,23.19-2.16.58-4.67.1-6.98-.16-14.42-1.61-141.96-16.42-149.24-17.27Zm4.24-177.28c-.04.55-.55,163.95-.58,171.08,0,.83-.04,1.48,1.13,1.61,10.36,1.11,141.62,15.68,144.85,16.02.58-64.49,1.16-128.79,1.74-193.3-49.17,1.54-98.12,3.06-147.15,4.6Zm155.54-4.37c-.6,64.38-1.19,128.52-1.79,192.84.93-.22,54.35-14.12,80.4-20.91q2.82-.74,2.97-3.72c.03-.63,1.84-137.04,2.09-156.8,0-.56,0-1.12,0-1.75-27.96-3.23-55.72-6.43-83.66-9.66Z"/>
          <path fill="#e8c305" d="m412.18,673.92c-.05,6.06-1.93,11.41-6.37,15.68-6.25,6.01-15.15,5.73-21.13-.6-7.58-8.03-7.46-22.46.16-30.43,7.06-7.38,17.98-6.21,23.67,2.41,2.66,4.03,3.66,7.78,3.66,12.94Z"/>
          <path fill="#e8c305" d="m306.37,761.59c-.16-3.21.76-6.78,2.65-10.07,4.71-8.22,14.06-9.43,20.72-2.73,6.79,6.83,7.92,19.54,2.43,27.47-5.06,7.32-14.35,7.88-20.29,1.21-3.88-4.35-5.59-9.49-5.52-15.87Z"/>
          <path fill="#e8c305" d="m476.4,673.63c-.12,5.51-.71,10.5-3.12,15.06-.72,1.37-1.67,2.68-2.77,3.77-2.56,2.56-5.6,2.51-8.21-.02-2.63-2.54-3.88-5.83-4.61-9.3-1.62-7.67-1.39-15.22,1.95-22.45.61-1.31,1.46-2.56,2.42-3.65,2.65-2.99,6.52-2.94,9.17.06,3.05,3.45,4.13,7.7,4.76,12.11.23,1.6.3,3.23.4,4.42Z"/>
          <path fill="#e8c305" d="m475.47,764.59c-.27,6.92-1.04,12.35-3.78,17.28-1.35,2.44-2.92,4.71-5.93,5.44-1.99.48-3.61-.03-5.02-1.42-2.59-2.54-3.71-5.79-4.3-9.24-1.25-7.28-.82-14.43,2.3-21.2.94-2.04,2.34-4,3.95-5.57,2.59-2.52,5.85-2.35,8.1.48,1.46,1.84,2.62,4.08,3.28,6.33.87,2.98,1.12,6.15,1.41,7.9Z"/>
          <path fill="#e8c305" d="m517.13,675c-.14,4.09-.56,8.13-2.15,11.88-.82,1.93-2.01,3.81-3.43,5.35-1.73,1.89-4.26,1.82-5.97-.1-1.26-1.41-2.42-3.1-3.04-4.87-2.86-8.11-2.71-16.26.46-24.27.6-1.51,1.66-2.93,2.79-4.13,1.84-1.94,4.35-1.88,6.29,0,2.53,2.45,3.52,5.68,4.16,8.99.46,2.35.61,4.77.89,7.16Z"/>
          <path fill="#e8c305" d="m499.67,762.41c-.06-4.76.61-9.42,2.66-13.74.75-1.58,1.78-3.12,3-4.36,2.13-2.17,4.83-2.08,6.65.36,1.28,1.72,2.33,3.78,2.86,5.85,1.87,7.24,1.41,14.44-1.29,21.42-.61,1.58-1.53,3.11-2.58,4.46-2.56,3.29-5.94,3.08-8.26-.4-2.06-3.11-2.68-6.67-3.03-10.28-.1-1.09-.02-2.2-.02-3.3Z"/>
        </g>
      </g>
    </svg>
  )
}