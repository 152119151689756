export const SmallFlame22 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="302.98 635.11 461.98 578.01 510.65 722.61 529.33 810.41 356.59 864.5 279.41 713.65 302.98 635.11"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m521.82,771.18c62.07-64.6,51.37-160.19-32.3-200.26-36.22-15.58-58.32-46.08-75.01-80.63-2.19-3.73-4.28-7.73-8.63-10.75-10.45,22.5-22.36,43.76-31.22,67.35-15.13,1.18-31.15,3.67-46.52,8.05,2.86,23.07-5.48,42.29-17.91,60.64-27.87-12.96-55.59-25.09-85.31-32.49,44.09,52.4-16.51,117.58,6.84,175.24,16.54,64.44,50.72,93.67,117.48,95.39,1.83,3.63,3.67,7.26,5.5,10.9.61,1.21,1.15,1.34,2.38.97,23.97-7.37,47.96-14.69,71.95-22.01,32.79-10.01,65.57-20.02,98.36-30.03q3.44-1.05,2.68-4.56c-2.75-12.6-5.51-25.19-8.27-37.79Zm-236.84-61.99c-.38-.35-.04-.78.07-1.16,1.36-4.62,15.09-51.02,20.55-69.62.33-1.12.9-1.72,2.01-2.11,34.8-12.15,132.26-46.3,146.19-51.18,2.01-.7,1.99-.69,2.28,1.4,2.26,16.43,7.86,57.27,8.33,60.82.27,1.54-.65,1.58-1.29,1.8-5.2,1.79-10.41,3.54-15.62,5.3-27.48,9.3-134.55,45.55-160.62,54.37-.25.08-.49.18-.75.24-.39.1-.8.47-1.17.13Zm237.68,101.01c-52.16,15.96-160.86,49.27-163.06,49.95-.3.09-.6.18-.94.28-.76,0-.8-.71-1.03-1.18-4.93-9.77-51.97-103.45-70.58-140.51q-.87-1.73,1.01-2.37c39.05-13.16,156.75-52.82,176.55-59.5,2.05-.69,2.04-.69,2.82,1.41,17.28,46.29,54.64,146.43,56.1,150.19.4,1.03.21,1.39-.87,1.73Zm4.72-8.74c-.74-1.97-49.58-134.16-54.7-148.17-1.25-3.41-9.21-57.02-10.07-62.49-.04-.25-.05-.44,0-.55,17.15,47.68,37.74,94.33,50.36,143.3,2.29,10.52,4.57,21.04,6.86,31.56.4,2.32.79,4.64,1.15,6.97.1-.1.2-.21.3-.31,2.1,9.67,4.21,19.34,6.31,29.01.04.2,0,.41-.2.68Z"/>
          <path fill="#e8c305" d="m477.51,656.98c.95,4.4,2.23,8.71,3.63,12.98,2.17,6.66,4.33,13.33,7.31,19.7.28.59.41,1.41,1.73,1.68-2.99-12.92-6.95-25.19-12.57-36.93-.37.93-.27,1.74-.09,2.56Z"/>
          <path fill="#e8c305" d="m514.36,754.18c-2.33-7.61-4.66-15.21-7.92-22.48-.27-.59-.39-1.41-1.28-1.61-.18,4.65,8.92,32.38,11.68,35.53.16-.74.07-1.37-.07-2.03-.65-3.18-1.46-6.32-2.41-9.42Z"/>
          <path fill="#e8c305" d="m487.68,674.23c2.86,12.12,6.54,23.52,11.65,34.46.38-4.89-8.96-32.8-11.65-34.46Z"/>
          <path fill="#e8c305" d="m483.2,653.88c-2.7-11.51-6.41-22.68-11.56-33.37,2.32,11.64,6.08,22.79,10.78,33.63.26-.09.52-.17.78-.26Z"/>
          <path fill="#e8c305" d="m508.3,723.58c-2.53-10.97-5.88-21.45-10.55-31.51-.37.86-.34,1.66-.11,2.47.7,2.4,1.43,4.8,2.08,7.22,1.81,6.69,4.08,13.22,6.65,19.65.32.8.48,1.86,1.93,2.17Z"/>
          <path fill="#e8c305" d="m410.13,747.79c-5.51-.01-9.78,1.14-13.6,3.78-9.28,6.43-9.77,18.38-1.08,25.51,7.84,6.43,20.79,5.97,28.18-1,5.92-5.58,6.8-13.71,2.04-20.3-4.02-5.56-9.79-7.71-15.54-8Z"/>
          <path fill="#e8c305" d="m402.62,654.6c1.99.81,4.08.99,6.17.76,8.94-.97,17.02-4.15,24.14-9.66,1.54-1.19,2.88-2.59,3.81-4.33,1.29-2.39.6-4.27-1.92-5.29-1.49-.61-3.05-.68-4.66-.7-9.68.81-18.39,4.1-25.96,10.25-1.46,1.19-2.71,2.62-3.46,4.4-.87,2.06-.2,3.72,1.87,4.56Z"/>
          <path fill="#e8c305" d="m361.88,651.16c1.15.38,2.34.55,3.57.53,8.81-.73,16.74-3.83,23.86-9.05,1.61-1.18,3.08-2.52,4.13-4.25,1.57-2.59.85-4.38-2.04-5.19-.77-.22-1.54-.29-2.33-.28-9.18.2-17.17,3.7-24.51,8.91-1.84,1.3-3.51,2.83-4.54,4.9-1.02,2.07-.32,3.69,1.87,4.42Z"/>
          <path fill="#e8c305" d="m325.38,648.03c.88.24,1.77.18,2.85.22.59-.04,1.39-.01,2.16-.14,7.47-1.22,14.24-4.14,20.36-8.56,1.83-1.32,3.53-2.81,4.51-4.93.78-1.69.24-3-1.5-3.59-1.25-.42-2.55-.59-3.87-.45-8.05.87-15.25,3.91-21.83,8.53-1.76,1.24-3.4,2.65-4.51,4.53-1.29,2.19-.61,3.71,1.83,4.38Z"/>
          <path fill="#e8c305" d="m171.41,511.88c-1.85,41.09-3.99,47.32,36.9,62.69,1.37-1.97.23-3.07-.65-4.28-16.02-24.18-10.52-35.58-36.25-58.41Z"/>
        </g>
      </g>
    </svg>
  )
}