export const SmallFlame11 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="318.08 620.14 462.82 577.33 561.85 717.13 485.64 796.04 357.7 860.31 273.11 722 318.08 620.14"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m563.83,714.3c-12.59-17.22-25.07-34.62-37.53-52.04-5.49-29.82-26.24-52.17-49.15-70.77-.83-.66-1.75-1.38-2.96-1.37-3.69-5-7.38-9.99-11.09-14.94-2.95.87-5.89,1.74-8.84,2.61-59.6-31.2-107.27,3.05-156.11-30.11-10.1-6.65-20.24-13.36-31.83-17.87-38.7,41.73-91.17,118.75-35.18,166.97,6.22,5.02,12.04,10.47,17.77,16.04,3.98,3.86,6.6,8.05,6.89,13.77,6.61,45.18,38.37,86.06,81.49,102.27,6.47,10.77,12.95,21.54,19.42,32.31.78,1.48,2.84-.1,4.47-.8,13.19-6.94,26.39-13.55,39.58-20.07,52.19-3.03,96.87-37.75,113.22-72.19-.28.27-.58.55-.86.82,16.87-17.44,33.86-35.09,50.56-51.93,1.02-1,.92-1.62.15-2.7Zm-206.57,140.56c-.92,1.23-1.2.69-1.76-.24-4.92-8.17-9.85-16.34-14.77-24.51.1.03.2.07.3.11-7.74-12.72-15.48-25.44-23.22-38.16-14.07-23.36-28.14-46.71-42.22-70.07-.55-.9-.65-1.6-.2-2.6,14.28-31.06,28.28-62.2,42.61-93.24,32.88,50.91,65.55,101.8,98.43,152.7.59.92.63,1.5-.11,2.43-19.69,24.52-39.44,49-59.05,73.59Zm67.24-70.91c43.69-21.81,88.66-42.14,132.88-62.95-21.29,23.2-45.11,46.55-67.14,69.58-5.51,4.15-11.32,7.9-17.32,11.38l-36.15,18.34c-9.25,4.42-18.45,8.88-27.27,13.84l-45.01,22.84c19.48-24.61,40.19-48.56,60-73.02Zm132.96-63.02s0,0,0,0h0c.02-.07.05-.12.15-.1-.05.03-.1.07-.15.1Zm-131.55,53.48c-1.39.65-2.05.55-2.94-.83-20.5-31.71-41.05-63.37-61.6-95.05-12.02-18.54-24.05-37.08-36.07-55.62-1.04-1.61-1.04-1.6.81-2.15,45.03-13.49,90.08-26.93,135.1-40.46,33.87,41.1,64.25,89.23,96.79,132.66-43.69,20.66-88.19,40.84-132.1,61.45Z"/>
          <path fill="#e8c305" d="m557.46,720.92c.05-.03.1-.06.15-.1-.1-.03-.13.03-.15.1Z"/>
          <path fill="#e8c305" d="m440.15,652.55c-8.64-.51-15.92,7.56-12.48,16.29,3.83,11.67,18.63,20.55,30.02,14.07,14.21-10.67-3.48-30.97-17.54-30.36Z"/>
          <path fill="#e8c305" d="m380.51,750.57c-6.81-9.31-17.23-9.13-16.63,4.14.95,10.43,5.8,22.63,16.22,26.62,5.77,1.7,8.51-5.12,8.18-9.79-.26-7.85-3.07-14.78-7.77-20.97Z"/>
          <path fill="#e8c305" d="m301,716.4c-4.54-1.71-6.55,4.35-6.37,7.54.02,6.63,2.95,14.97,6.87,20.08,5.29,7.68,13.25,8.38,13.02-2.67-.77-9.42-4.46-20.6-13.51-24.95Z"/>
          <path fill="#e8c305" d="m427.92,798.31c11.82-2.99,22.61-10.71,30.85-19.56.55-.74,1.29-1.63.74-2.56-1.24-1.59-4.56.33-6.17.76-3.05.04-34.39,20.15-25.41,21.36Z"/>
          <path fill="#e8c305" d="m495.02,765.93c9.91-2.68,18.44-9.35,26.05-16.06,5.03-4.69,4.56-7.38-2.4-4.19-3.02.48-31.41,18.66-23.65,20.26Z"/>
          <path fill="#e8c305" d="m419.73,822.11c3.53-2.47,10.02-9.75.66-6.22-2.71-.06-32.98,18.75-24.44,19.51,8.86-2.11,16.6-7.87,23.78-13.29Z"/>
          <path fill="#e8c305" d="m459.75,803.32c9.9-2.91,19.05-9.67,26.31-16.93.57-.63,1.23-1.38.75-2.17-1.25-1.43-5.63,1.21-7.2,1.8-2.45.71-26.7,15.95-19.86,17.3Z"/>
          <path fill="#e8c305" d="m271.63,406.49c-36.44,36.33-8.17,44.05,9.8,78.9,30.08-40.28,12.11-43.11-9.8-78.9Z"/>
          <path fill="#e8c305" d="m335.53,436.11c11.49-13.79,14.6-29.42,6.93-38.41-6.2,11.07-21.15,28.77-6.93,38.41Z"/>
        </g>
      </g>
    </svg>
  )
}