export const BigFlame60 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="288.13 813.04 445.22 804.83 531.48 795.37 518.53 628.91 425.11 605.79 278.39 636.2 288.13 813.04"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m283.75,406.56c-.91-6.66-2.01-12.76-7.11-17.04-6.78-5.77-8.06-12.67-12.26-20.97-1.08,17.34,6.03,29.01,19.37,38.01Z"/>
          <path fill="#e8c305" d="m529.72,761.1c22.79-24.82,39.81-54.08,39.7-88.62-4.02-39.76-17.09-79.55-58.46-93.27-26.15-9.01-52.49-15.83-76.82-29.72,0,7.35,2.29,14.11,1.13,21.33-48.09-6.86-92.28-27.18-140.62-32.44.89,35.7-5.43,69.49-20.26,101.71-17.54-3.22-33.14-10.1-48.18-18.68,13.43,28.33,31.37,62.15,17.3,93.54-5.26,9.49-7.01,20.29-9.48,30.73-8.33,34.93,9.99,70.85,42.35,85.51,64.92,29.6,143.03,9.16,200.96-27.69,17.84-1.79,35.62-3.89,53.33-6.47,1.46-.17,1.8-.63,1.68-2.01-.94-11.3-1.78-22.6-2.64-33.91Zm-93.78,40.68c-14.69.76-29.38,1.58-44.08,2.38-30.07,1.55-60.13,3.24-90.2,4.79-3.29.17-6.59.32-9.88.57-1.11.08-1.48-.27-1.53-1.35-1.52-29.85-3.24-59.68-4.77-89.53-1.5-26.33-2.79-52.69-4.3-79.01-.12-1.27.18-1.75,1.42-1.98,46.96-9.1,93.88-18.43,140.82-27.64.97-.19,1.39-.06,1.46,1.08,4.17,63,8.64,125.9,12.84,188.91.53,1.55-.39,1.72-1.79,1.79Zm92.46-9.53c-16.08,1.69-32.16,3.4-48.24,5.12-10.77,1.15-21.54,2.29-32.31,3.49-1.22.14-1.6-.15-1.69-1.42-.87-13.16-1.83-26.31-2.73-39.47-3.34-50.15-6.98-100.14-10.33-150.31,27.74,7.12,55.24,14.41,82.95,21.56,1.2.31,1.53.81,1.62,1.99,2.79,36.47,5.67,72.93,8.45,109.39,1.27,16.01,2.47,32.03,3.76,48.04.11,1.22-.41,1.5-1.47,1.62Z"/>
          <path fill="#e8c305" d="m403.38,651.34c-14.38-23.56-39.6,5.96-27.4,23.66,10.56,16.94,31.31,2.51,29.72-13.81.06-3.49-.7-6.78-2.32-9.84Z"/>
          <path fill="#e8c305" d="m310.15,751.27c-11.04,14.54,5.2,43.99,21.66,25.97,3.02-3.85,4.24-8.33,4.35-14.02.37-14.08-15.53-26.6-26.01-11.94Z"/>
          <path fill="#e8c305" d="m460.57,735.37c-5.91,8.28-4.91,19.34-1.68,28.47,1.49,5.08,7.5,12.04,12.75,7,3.94-4.1,4.43-10.04,4.7-15.5-.32-7.22-1.68-15.36-7.29-20.53-2.75-2.4-6.06-2.17-8.48.56Z"/>
          <path fill="#e8c305" d="m458.7,678.63c8.55,4.66,11.24-8.26,11.06-14.6-.24-5.43-1.25-11-3.94-16.15-4.15-8.75-12.62-10.05-15.17.46-2.17,10.39-.56,23.26,8.06,30.29Z"/>
          <path fill="#e8c305" d="m510.51,735.56c-10.73-6.58-11.23,17.13-9.05,22.98,4.89,19.28,16.53,12.61,15.56-4.99-.08-4.88-1.75-14.04-6.51-17.99Z"/>
          <path fill="#e8c305" d="m510.69,671.87c-.1-5.23-.92-10.31-3.12-15.1-1.55-4.44-7.54-10.33-11.15-4.46-4.46,9.04-2.69,19.82,1.95,28.48,7.27,11.19,12.91-.73,12.33-8.93Z"/>
        </g>
      </g>
    </svg>
  )
}