export const CashierDepositIcon = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "65"} 
      height={props?.h ?? "66"} 
      viewBox={props?.v ?? "0 0 65 66"}
    >
      <g filter="url(#filter0_d_1044_634)">
      <path d="M27.46 9.84177H15.8427C15.0698 9.84177 14.9879 8.85229 15.6427 8.68898H27.46C27.6282 8.68898 27.801 8.69858 27.9693 8.7226C27.7374 6.98861 26.2142 5.78299 24.5773 6.03276C24.5318 6.03756 24.4909 6.04717 24.4454 6.05678L12.9464 8.12699C12.7918 8.15101 12.6326 8.17983 12.478 8.21345H12.469C12.4098 8.22306 12.3553 8.23747 12.2962 8.25188C10.5365 8.69858 10.0227 10.3701 10.0227 11.3788V12.5412C10.0091 12.6661 10 12.791 10 12.9159V22.1382C10 23.8337 11.305 25.2123 12.91 25.2123H27.46C29.065 25.2123 30.37 23.8337 30.37 22.1382V12.9159C30.37 11.2203 29.065 9.84177 27.46 9.84177ZM25.3002 19.0641C24.4954 19.0641 23.8452 18.3772 23.8452 17.527C23.8452 16.6768 24.4954 15.99 25.3002 15.99C26.105 15.99 26.7552 16.6768 26.7552 17.527C26.7552 18.3772 26.105 19.0641 25.3002 19.0641Z" fill="#4C80D5"/>
      </g>
      <g filter="url(#filter1_d_1044_634)">
      <path d="M17.3107 12.9207L14.8324 16.5971C14.7712 16.6878 14.7356 16.7935 14.7293 16.9029C14.7231 17.0124 14.7464 17.1215 14.7969 17.2186C14.8474 17.3158 14.9231 17.3973 15.016 17.4547C15.1089 17.512 15.2155 17.5428 15.3245 17.544L16.0367 17.5503L15.9667 25.865C15.9697 26.1083 16.0661 26.3411 16.2356 26.5146C16.4051 26.6882 16.6348 26.7891 16.8766 26.7964L18.5087 26.8108C18.629 26.8125 18.7484 26.7899 18.8599 26.7444C18.9713 26.6988 19.0726 26.6312 19.1576 26.5456C19.2443 26.4611 19.3134 26.3601 19.3609 26.2485C19.4085 26.1369 19.4335 26.0169 19.4346 25.8954L19.5046 17.5807L20.2189 17.5891C20.3281 17.589 20.4352 17.5595 20.5293 17.5037C20.6233 17.4479 20.7008 17.3678 20.7537 17.2718C20.8058 17.1754 20.831 17.0666 20.8267 16.957C20.8224 16.8473 20.7888 16.7409 20.7295 16.6488L18.3133 12.9274C18.2911 12.8962 18.2665 12.8668 18.2399 12.8393C18.1845 12.7817 18.1182 12.7358 18.045 12.7044C17.9717 12.673 17.8929 12.6567 17.8132 12.6564C17.7144 12.6557 17.6168 12.6794 17.5292 12.7255C17.4415 12.7716 17.3665 12.8386 17.3107 12.9207Z" fill="#4C80D5"/>
      <path d="M17.816 12.1549C17.6358 12.1538 17.458 12.1971 17.2983 12.2811C17.1382 12.3652 17.0012 12.4877 16.8993 12.6376L16.8981 12.6393L14.4201 16.3153C14.3082 16.4811 14.2431 16.6742 14.2317 16.8742C14.2202 17.0741 14.2629 17.2735 14.3551 17.451C14.4474 17.6285 14.5857 17.7775 14.7555 17.8822L15.016 17.4547L14.7555 17.8822C14.9252 17.9869 15.12 18.0433 15.3191 18.0455L15.3201 18.0455L15.534 18.0474L15.4683 25.8608L15.4681 25.8608L15.4683 25.8713C15.4729 26.2439 15.6204 26.6004 15.88 26.8661C16.1397 27.1319 16.4913 27.2866 16.8616 27.2977L16.8616 27.2978L16.8721 27.2979L18.5031 27.3122C18.6896 27.3147 18.8746 27.2796 19.0474 27.209C19.2194 27.1387 19.3758 27.0346 19.5074 26.9029C19.6401 26.7727 19.7461 26.6175 19.8191 26.446C19.8927 26.2733 19.9314 26.0876 19.933 25.8998L19.933 25.8996L19.9988 18.0881L20.213 18.0906L20.213 18.0906L20.2195 18.0906C20.4175 18.0904 20.6119 18.0369 20.7825 17.9357C20.953 17.8345 21.0936 17.6892 21.1897 17.515L21.1897 17.515L21.1919 17.5109C21.2867 17.3352 21.3326 17.137 21.3248 16.9373C21.317 16.7375 21.2558 16.5435 21.1477 16.3758L21.1468 16.3745L18.7307 12.6531L18.7247 12.6439L18.7183 12.635C18.6817 12.5837 18.6414 12.5352 18.5975 12.49M17.816 12.1549C17.9619 12.1556 18.1062 12.1855 18.2404 12.2431C18.3746 12.3006 18.4961 12.3846 18.5975 12.49M17.816 12.1549C17.8163 12.1549 17.8167 12.155 17.817 12.155L17.8132 12.6564L17.8149 12.1549C17.8152 12.1549 17.8156 12.1549 17.816 12.1549ZM18.5975 12.49C18.5978 12.4903 18.5981 12.4905 18.5983 12.4908L18.2399 12.8393L18.5966 12.489C18.5969 12.4893 18.5972 12.4897 18.5975 12.49Z" stroke="#181C27"/>
      </g>
      <defs>
      <filter id="filter0_d_1044_634" x="0" y="0" width="64.3701" height="63.2123" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_634"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_634" result="shape"/>
      </filter>
      <filter id="filter1_d_1044_634" x="3.73145" y="5.65344" width="52.0928" height="60.1604" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_634"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_634" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}