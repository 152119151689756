export const SearchIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "63"} 
      height={props?.h ?? "63"} 
      viewBox={props?.v ?? "0 0 63 63" }
      fill="none"
    >
      <g filter="url(#filter0_d_2126_2092)">
        <path d="M25.6448 19.9028L22.1962 16.4067C22.7821 15.4611 23.1222 14.3434 23.1222 13.144C23.1222 9.75056 20.4084 7 17.061 7C13.7135 7 11 9.75056 11 13.144C11 16.5375 13.7134 19.2878 17.061 19.2878C18.3487 19.2878 19.5414 18.8797 20.5228 18.1863L23.9304 21.6408C24.1672 21.8806 24.4776 22 24.7876 22C25.098 22 25.408 21.8806 25.6452 21.6408C26.1183 21.1606 26.1183 20.3828 25.6448 19.9028ZM17.061 17.2973C14.7984 17.2973 12.964 15.4379 12.964 13.1442C12.964 10.8506 14.7984 8.99107 17.061 8.99107C19.3237 8.99107 21.1579 10.8506 21.1579 13.1442C21.1579 15.4379 19.3237 17.2973 17.061 17.2973Z" fill="#C5C5C5"/>
      </g>
      <defs>
        <filter id="filter0_d_2126_2092" x="0.264709" y="0.558826" width="62.2353" height="62.2353" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="12.8823" dy="17.1765"/>
          <feGaussianBlur stdDeviation="11.8088"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2126_2092"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2126_2092" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}