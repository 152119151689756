export const EditIcon = (props) => {
    const { size } = props;
    return (
      <svg
        width={size ? size : 26}
        height={size ? size : 26}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.3375 22C1.69469 22 1.1444 21.7849 0.686641 21.3546C0.22888 20.9243 0 20.407 0 19.8027V4.42197C0 3.81773 0.22888 3.30046 0.686641 2.87016C1.1444 2.43987 1.69469 2.22472 2.3375 2.22472H12.7686L10.4311 4.42197H2.3375V19.8027H18.7V12.1673L21.0375 9.97004V19.8027C21.0375 20.407 20.8086 20.9243 20.3509 21.3546C19.8931 21.7849 19.3428 22 18.7 22H2.3375ZM7.0125 15.4082V10.7391L17.7358 0.659176C17.9695 0.439451 18.2325 0.274657 18.5247 0.164794C18.8169 0.0549313 19.1091 0 19.4013 0C19.7129 0 20.01 0.0549313 20.2924 0.164794C20.5749 0.274657 20.833 0.439451 21.0667 0.659176L22.703 2.22472C22.9172 2.44444 23.0828 2.68706 23.1997 2.95256C23.3166 3.21806 23.375 3.48814 23.375 3.7628C23.375 4.03745 23.3214 4.30753 23.2143 4.57303C23.1072 4.83854 22.9367 5.08115 22.703 5.30087L11.9797 15.4082H7.0125ZM9.35 13.211H10.9863L17.765 6.83895L16.9469 6.06991L16.0995 5.30087L9.35 11.6454V13.211Z"
          fill="#C5C5C5"
        />
      </svg>
    );
  };