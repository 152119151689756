export const CashierWithdrawIcon = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg"
      width={props?.w ?? "65"} 
      height={props?.h ?? "67"} 
      viewBox={props?.v ?? "0 0 65 67"}
    >
      <g filter="url(#filter0_d_1044_680)">
      <path d="M27.46 9.84177H15.8427C15.0698 9.84177 14.9879 8.85229 15.6427 8.68898H27.46C27.6282 8.68898 27.801 8.69858 27.9693 8.7226C27.7374 6.98861 26.2142 5.78299 24.5773 6.03276C24.5318 6.03756 24.4909 6.04717 24.4454 6.05678L12.9464 8.12699C12.7918 8.15101 12.6326 8.17983 12.478 8.21345H12.469C12.4098 8.22306 12.3553 8.23747 12.2962 8.25188C10.5365 8.69858 10.0227 10.3701 10.0227 11.3788V12.5412C10.0091 12.6661 10 12.791 10 12.9159V22.1382C10 23.8337 11.305 25.2123 12.91 25.2123H27.46C29.065 25.2123 30.37 23.8337 30.37 22.1382V12.9159C30.37 11.2203 29.065 9.84177 27.46 9.84177ZM25.3002 19.0641C24.4954 19.0641 23.8452 18.3772 23.8452 17.527C23.8452 16.6768 24.4954 15.99 25.3002 15.99C26.105 15.99 26.7552 16.6768 26.7552 17.527C26.7552 18.3772 26.105 19.0641 25.3002 19.0641Z" fill="#4C80D5"/>
      </g>
      <g filter="url(#filter1_d_1044_680)">
      <path d="M18.2775 27.4788L20.7124 23.7737C20.7725 23.6823 20.8069 23.5762 20.8119 23.4666C20.8168 23.3571 20.7922 23.2483 20.7406 23.1517C20.689 23.0552 20.6123 22.9745 20.5187 22.9183C20.4251 22.8621 20.3182 22.8325 20.2092 22.8326L19.4969 22.8347L19.4693 14.5197C19.4635 14.2764 19.3644 14.0448 19.1929 13.8733C19.0213 13.7017 18.7905 13.6035 18.5487 13.599L16.9164 13.6038C16.7961 13.6035 16.677 13.6275 16.5661 13.6744C16.4552 13.7212 16.3547 13.79 16.2707 13.8766C16.185 13.9621 16.1171 14.0639 16.0709 14.1761C16.0246 14.2882 16.001 14.4085 16.0014 14.53L16.029 22.8449L15.3146 22.8449C15.2055 22.8463 15.0987 22.8771 15.0053 22.934C14.9119 22.9909 14.8353 23.0718 14.7835 23.1685C14.7327 23.2655 14.7087 23.3746 14.7143 23.4842C14.7199 23.5937 14.7547 23.6998 14.8151 23.7911L17.2748 27.4839C17.2974 27.5148 17.3223 27.544 17.3493 27.5711C17.4053 27.6281 17.4721 27.6732 17.5457 27.7038C17.6194 27.7343 17.6984 27.7497 17.778 27.749C17.8769 27.7486 17.9742 27.7237 18.0612 27.6766C18.1483 27.6295 18.2226 27.5616 18.2775 27.4788Z" fill="#4C80D5"/>
      <path d="M17.7811 28.2505C17.9614 28.2496 18.1386 28.2042 18.2973 28.1183C18.4564 28.0323 18.5919 27.9083 18.6922 27.7571L18.6933 27.7554L21.128 24.0505C21.2379 23.8835 21.3008 23.6896 21.3098 23.4895C21.3189 23.2894 21.2739 23.0906 21.1796 22.9142C21.0853 22.7378 20.9452 22.5904 20.7742 22.4877L20.5187 22.9183L20.7742 22.4877C20.6033 22.385 20.4079 22.3309 20.2088 22.3311L20.2077 22.3311L19.9938 22.3317L19.9678 14.5181L19.9679 14.5181L19.9677 14.5076C19.9587 14.135 19.807 13.7803 19.5443 13.5176C19.2815 13.2549 18.9281 13.1044 18.5577 13.0976L18.5577 13.0975L18.5472 13.0975L16.9162 13.1023C16.7297 13.1021 16.545 13.1393 16.3731 13.212C16.2019 13.2843 16.0468 13.3902 15.9168 13.5234C15.7855 13.6552 15.6814 13.8117 15.6104 13.984C15.5389 14.1575 15.5023 14.3436 15.5029 14.5315L15.5029 14.5316L15.5288 22.3434L15.3146 22.3434L15.3146 22.3434L15.3082 22.3434C15.1101 22.346 14.9164 22.4018 14.747 22.505C14.5776 22.6082 14.4388 22.7551 14.3448 22.9305L14.3447 22.9305L14.3426 22.9345C14.2499 23.1113 14.2063 23.31 14.2164 23.5097C14.2266 23.7094 14.29 23.9026 14.4002 24.069L14.401 24.0703L16.8607 27.7631L16.8668 27.7722L16.8732 27.7811C16.9104 27.8319 16.9514 27.8799 16.9957 27.9247M17.7811 28.2505C17.6352 28.2516 17.4906 28.2233 17.3557 28.1674C17.2208 28.1114 17.0984 28.0289 16.9957 27.9247M17.7811 28.2505C17.7808 28.2505 17.7805 28.2505 17.7802 28.2505L17.778 27.749L17.7823 28.2505C17.7819 28.2505 17.7815 28.2505 17.7811 28.2505ZM16.9957 27.9247C16.9954 27.9244 16.9952 27.9241 16.9949 27.9239L17.3493 27.5711L16.9967 27.9256C16.9963 27.9253 16.996 27.925 16.9957 27.9247Z" stroke="#181C27"/>
      </g>
      <defs>
      <filter id="filter0_d_1044_680" x="0" y="0" width="64.3701" height="63.2123" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_680"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_680" result="shape"/>
      </filter>
      <filter id="filter1_d_1044_680" x="3.7168" y="6.59595" width="52.0928" height="60.1561" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_680"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_680" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}