import axios from "axios";

export const fetcher = async (method, authHead, route, data = {}) => {
  try {
    const response = await axios({
      method: method.toUpperCase(),
      url: route,
      data,
      headers: {
        Authorization: authHead,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    // Re-throw the error to allow the caller to handle it
    throw error;
  }
};
