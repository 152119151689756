export const BigFlame45 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="297.88 785.1 451.42 831.42 486.42 832.74 535.56 651.24 358.69 593.12 297.88 785.1"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m554.12,641.52c-6.09-12.36-8.74-51.5-23.58-52.95-.13,3.66,1.02,7.63-3.63,10.08-9.95-9.72-22.27-15.99-34.33-22.6-33.1-21.12-12.36-80.2-60.38-83.7-19.15-2.19-36.55-9.7-51.98-21.45-1.3-.99-2.58-2.13-4.56-.93,12.25,17.55,20.73,36.59,23.92,58.55-46.05-17.49-91.1-35.7-136.9-54.01,24.48,46.23,32.51,92.68,9.48,141.13-20.88-4.83-49.53-35.61-59.33-63.61,5.67-20.48,19.7-54.39,4-72.58-22.77-20.44-34.58-48.01-47.61-75.28-10.22,17.08-43.24,67.61-17.55,80.83,26.07,14.85,39.98,43.42,45.96,71.75-1.28,30.43-30.21,69.46.04,93.58,20.9,18.11,32.5,47.13,31.13,74.85-1.6,18.79,1.87,36.79,10.69,53.61,35.92,66.76,127.7,73.21,191.27,46.79-44.37-13.52-88.11-26.84-132.17-40.27.92-2.63,2.74-4.74,4.07-7.14.35-.63.99-.43,1.55-.26,5.27,1.55,160.69,48.36,174.06,52.41.64.19,1.27.4,1.92.92-.76.56-1.47.05-2.12.05-6.54-.07-13.07-.19-19.61-.32-2.3-.05-4.58-.42-6.89-.27-.51.03-1.05-.17-1.52.18,1.48.87,3.14.68,4.75.75,10.52.49,21.04.94,31.56,1.42,1.17.05,1.88-.17,2.24-1.51,1.38-5.08,6.54-23.86,13.17-47.97,39.92-34.07,69.21-89.39,52.36-142.06Zm-67.46,176c-.63,2.27-1.3,4.53-1.86,6.82-.28,1.17-.71,1.5-1.95,1.12-16.18-4.93-128.25-38.8-168.09-50.84-2.66-.8-5.29-1.71-7.97-2.39-1.47-.37-1.57-.97-1.15-2.28,4.11-12.75,40.52-127.27,54.67-171.76.73-2.29.47-2.02,2.66-1.3,53.1,17.52,165.17,54.5,168.1,55.47.46.15,1.09.18,1.01.89-.16.57-.31,1.07-.45,1.57-14.99,54.23-29.98,108.47-44.97,162.7Z"/>
          <path fill="#e8c305" d="m327.17,787.59c12.08,4.7,24.39,8.49,37.37,11.16-5.08-3.01-32.94-11.41-37.37-11.16Z"/>
          <path fill="#e8c305" d="m363.56,720.99c-10.87.2-19.6,8.26-19.86,19.57-.26,11.25,8.34,20.2,20.17,20.43,11.07.22,19.88-8.8,19.99-20.07.11-10.75-9.17-19.86-20.3-19.93Z"/>
          <path fill="#e8c305" d="m408.5,735.07c-10.29-.01-19.29,8.86-19.37,19.18-.09,11.86,8.8,20.25,19.82,20.68,11.08-.02,19.91-8.84,19.83-19.83-.08-11.13-9.07-20.02-20.28-20.04Z"/>
          <path fill="#e8c305" d="m453.28,748.95c-10.42.09-19.01,8.22-19.2,19.06-.2,11.37,8.05,20.31,19.5,20.58,10.74.26,19.12-7.74,19.52-19.34.38-10.98-8.72-20.1-19.81-20.3Z"/>
          <path fill="#e8c305" d="m391.57,671.7c10.78.03,18.74-8.17,19.03-18.22.31-11.08-8.38-20.32-20.15-20.43-10.35-.1-18.57,7.57-19.07,18.52-.11,11.01,8.81,20.1,20.19,20.13Z"/>
          <path fill="#e8c305" d="m435.93,685.92c10.48-.12,18.46-8.04,18.56-18.43.11-11.47-8.8-19.63-19.52-20.1-10.84-.16-18.88,8-19.11,18.19-.26,11.25,8.89,20.47,20.07,20.34Z"/>
          <path fill="#e8c305" d="m478.52,661.59c-10.3-.36-18.65,7.85-18.76,18.06-.13,11.13,8.56,20.09,19.62,20.24,10.23.14,18.31-7.91,18.45-18.37.02-10.66-8.11-19.54-19.3-19.93Z"/>
        </g>
      </g>
    </svg>
  )
}