export const NoFlame10 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="314.69 620.91 452.74 577.27 561.06 704.62 487.61 796.99 365.68 864.78 271.82 730.63 314.69 620.91"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m453.07,574.62c37.11,41.78,73.61,85.71,110.43,128-22.01,31.77-51.1,62.56-74.79,94.32-33.47,19.97-68.34,38.06-102.27,57.4-6.63,3.69-13.28,7.36-19.89,11.1-1.08.61-1.71.53-2.43-.5-30.42-45.66-66.39-89.97-94.16-136.29,14.31-36.7,28.59-73.42,42.86-110.14,45.09-16.93,93.93-28.69,140.26-43.9Zm-81.33,286.19c38.53-21.35,77.71-42.3,115.69-64.51,23.05-29.21,47.33-58.36,69.83-87.56l-.02.23c-41.72,20.15-84.28,40.9-125.32,62.55-19.99,29.78-40.73,59.14-60.31,89.15-.31.12-.12.47.12.13Zm59.53-98.4c41.43-20.16,82.79-40.49,124.2-60.71q1.74-.85.49-2.3c-34.11-39.75-68.17-79.54-102.22-119.34-43.16,9.5-87.46,27.08-130.98,39.24-1.75.54-1.75.55-.67,1.95,36.52,46.88,72.31,94.75,109.18,141.15Zm-67.69,97.01c20.41-29.62,39.85-60.23,59.99-90.15.62-.93.67-1.55-.05-2.46-27.53-35.83-54.93-71.76-82.42-107.63-8.76-11.1-17.11-23.03-26.06-33.73-.6.08-.55.91-.84,1.37-13.54,34.23-27.04,68.49-40.62,102.71-.43,1.08-.44,1.92.31,2.88,29.97,42.27,59.76,84.7,89.68,127.01Z"/>
          <path fill="#e8c305" d="m557.23,708.98l.02-.23c.27.13,0,.15-.05.21,0,0,.03.02.03.02Z"/>
          <path fill="#e8c305" d="m371.73,860.82c-.24.34-.44,0-.13-.13.04.04.08.09.13.13Z"/>
          <path fill="#e8c305" d="m446.87,676.94c-8.49-.94-16.34-5.29-20.79-14.61-2.59-5.43-1.11-10.74,3.54-13.64,13.58-8.59,41.79,12.55,27.11,26-1.84,1.39-5.12,2.28-9.86,2.25Z"/>
          <path fill="#e8c305" d="m367.6,748.88c-.15-12.49,10.93-13.24,17.8-4.67,6.27,7.48,11.27,18.7,6.75,28.18-2.17,3.5-5.1,4.53-8.98,3.06-3.51-1.32-6.06-3.84-8.4-6.64-4.16-4.99-7.18-13.18-7.17-19.93Z"/>
          <path fill="#e8c305" d="m293.97,731.02c-.37-4.84,2.69-11.44,8.42-8.51,9.27,5.11,15,19.19,12.17,29.39-2.35,6.6-10.34,2.81-13.1-1.46-4.57-5.65-7.18-12.11-7.49-19.42Z"/>
          <path fill="#e8c305" d="m462.51,765.84c10.36,1.52-19.18,23.67-23.04,24.1-8.46,3.96-7.77-1.52-2.98-6.47,7.25-7.56,15.66-15.02,26.02-17.64Z"/>
          <path fill="#e8c305" d="m523.97,734.4c8.65,1.33-16.66,20.99-19.8,21.9-7.2,4.19-10.67,2.47-4.48-4.66,6.77-7.11,14.64-14.54,24.27-17.24Z"/>
          <path fill="#e8c305" d="m403.05,835.91c-9.18-1.38,19.53-22.12,23.07-22.27,6.46-2.82,5.48,1.7,2.06,5.18-7.04,7.23-15.25,14.36-25.13,17.09Z"/>
          <path fill="#e8c305" d="m463.87,802.84c-8-1.54,17.92-20.5,21.16-21.02,3.75-1.92,6.39-1.02,3.2,3.03-6.57,7.74-14.69,14.66-24.36,17.99Z"/>
        </g>
      </g>
    </svg>
  )
}