export const NoFlame58 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="408.98 829.04 545.08 781.8 505.31 624.69 406.32 581.8 271.07 654.59 298.51 824.04 408.98 829.04"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m395.84,829.03c-32.35-1.22-64.67-3.11-97.02-4.48-1.06-.04-1.52-.3-1.71-1.47-4.32-26.7-8.7-53.39-13.06-80.08-4.83-29.38-9.47-58.79-14.39-88.15-.18-.99,0-1.47.91-1.96,41.4-21.98,82.75-44.04,124.13-66.05,2.9-1.05,12.57-7.87,14.4-6.56,31.43,14.34,65.41,29.93,96.92,44.08,12.67,52.75,26.44,106.5,39.53,156.44.37,1.44-.42,1.55-1.32,1.86-44.99,15.51-89.98,31-134.97,46.52-4.36,1.05-8.98-.17-13.44-.1v-.04Zm47.76-66.55c-12.77-57.72-25.74-115.38-38.57-173.09-.49-2.21-.5-2.22-2.53-1.14-29.33,15.62-58.85,30.89-88.16,46.53-13.1,6.93-26.21,13.85-39.34,20.74-.97.51-1.15,1.03-.97,2.1,1.56,9.18,3.04,18.37,4.55,27.56,7.18,43.5,14.35,87,21.5,130.51.34,2.03.37,2.02,2.33,1.27,46.95-18.21,94.52-36.12,141.18-54.47Zm-30.99-176.11c13.86,57.97,24.94,118.33,40.57,175.06,29.3,4.99,58.57,10.11,87.85,15.16.97.17,1.2.06.91-.97-12.7-48.69-25.25-97.43-37.88-146.14-.34-1.31-.88-2.14-2.19-2.73-29.84-13.45-59.4-26.88-89.26-40.38Zm-106.91,236.58c32.47,2.22,65.01,3.15,97.5,5.02,3.16.24,6.24.38,9.24-.97,41.95-14.68,84.07-28.94,126.02-43.63-29.5-5.21-59.11-9.42-88.67-14.28-48.36,16.38-95.95,36.23-144.09,53.87Z"/>
          <path fill="#e8c305" d="m393.76,643.16c-.43,8.17-3.46,15-10.46,19.68-21.44,11.33-26.98-23.58-11.34-33.11,10.84-8.53,22.57,1.35,21.81,13.43Z"/>
          <path fill="#e8c305" d="m339.98,760.83c1.25,13.82-15.86,30.29-26.01,14.74-10.31-13.75,7.14-44.7,22.52-26.61,2.45,3.56,3.51,7.55,3.49,11.86Z"/>
          <path fill="#e8c305" d="m478.42,731.4c.41,11.59-8.39,12.11-14.49,3.91-5.98-8.1-10.71-18.9-7.17-28.92,1.46-2.75,4.24-3.66,7.07-2.35,2.74,1.26,4.83,3.29,6.61,5.65,4.86,6.43,7.57,13.66,7.98,21.72Z"/>
          <path fill="#e8c305" d="m458.28,647.06c.45,4.91-3.05,10.26-8.29,7.03-9.22-6.16-13.93-16.82-14.44-27.72.57-12.51,11.91-5.62,15.73,1.04,4.1,5.91,6.72,12.37,7.01,19.65Z"/>
          <path fill="#e8c305" d="m502.79,724.23c-.39-4.05,2.23-10.02,7.06-7.16,8.75,5.64,14.48,20.38,12.1,30.47-1.13,3.21-3.65,4.17-6.61,2.47-8.22-5.6-11.94-16.17-12.55-25.78Z"/>
          <path fill="#e8c305" d="m502.78,664.18c-1.48,22.64-25.22-11.98-18.73-23.64,2.12-3.88,6.93-.81,9.02,1.55,5.93,6.45,8.97,14.13,9.71,22.09Z"/>
          <path fill="#e8c305" d="m424.17,792.6c-8.43.33-9.22-3.1-1.39-6.33,6.5-2.75,27.49-8.9,32.84-3.97-1.28,6.5-25.24,10.23-31.45,10.3Z"/>
          <path fill="#e8c305" d="m449.48,795.36c-13.91.08-1.52-6.44,4.14-7.76,2.51-1.29,30.05-7.35,25.49-.54-8.49,5.77-19.59,7.57-29.63,8.29Z"/>
          <path fill="#e8c305" d="m354.51,818.05c-2.4-.08-9.14.24-6.63-2.98,7.4-5.1,16.88-6.33,25.64-7.44,2.58-.08,9.04-1.09,10.4,1.47-2.33,5.89-23.67,8.83-29.41,8.96Z"/>
          <path fill="#e8c305" d="m472.28,797.93c-10.64.15-3.51-4.66,1.82-6.28,2.38-1.52,31.52-8.59,27.03-1.75-8.45,5.52-18.94,7.18-28.85,8.02Z"/>
          <path fill="#e8c305" d="m380.68,819.62c-12.91.02-1.7-5.57,3.7-6.77,7.8-2.07,16.12-4.33,24.22-2.84,6.64,4.52-24.95,10.38-27.91,9.61Z"/>
          <path fill="#e8c305" d="m405.69,821.18c-9.26.18-5.59-3.33.26-5.28,5.63-1.9,22.89-6.94,27.45-3.12-2.31,5.27-22.3,8.13-27.71,8.4Z"/>
        </g>
      </g>
    </svg>
  )
}