export const BigFlame59 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="400.52 817.69 538.55 790.38 511.03 627.35 418.62 593.01 275.29 645.37 295.4 820.6 400.52 817.69"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m563.39,668.58c2.63-20.27-2.74-38.68-13.35-55.57-29.48-42.87-89.06-38.03-135.28-39.3,1.77,5.82,3.44,11.28,5.09,16.72-13.6,4.12-27.15,8.7-40.68,13.52-35.79.68-70.68-2.27-102.48-23.77-1.32,20.95,2.64,41.07-.25,61.57-.71.25-1.42.5-2.14.74-1.23.45-1.38,1.06-1.24,2.2.3,2.74.6,5.49.91,8.24-1.58,4.13-3.16,8.25-5.38,12.32-17.23,5.52-31.54-.14-44.62-12.81,3.24,25.15,20.34,44.83,24.56,69.13-2.64,11.94-2.71,24.12-3.56,36.23-.41,118.8,174.87,93.62,234.36,44.33,19.6-3.92,39.2-7.84,58.8-11.62,1.31-.26,1.51-.71,1.29-1.97-1.88-10.98-3.73-21.96-5.58-32.95,20.86-25.7,32.05-54.6,29.54-87Zm-147.88-69.64c8.88,60.92,17.73,121.85,26.56,182.78.18,1.08-.72.97-1.31,1.1-47.9,10.48-95.85,20.76-143.75,31.25-1.1.24-1.4.09-1.53-1.08-4.86-44.99-9.84-89.96-14.77-134.94-1.1-9.98-2.18-19.97-3.39-29.94-.22-1.79.88-1.95,1.98-2.34,14.24-5.13,28.47-10.26,42.71-15.4,30.38-10.96,60.77-21.91,91.15-32.87,1.99-.72,2.03-.71,2.34,1.43Zm-6.83,216.94s-.86.18-1.13.24c-9.98,1.63-20.17,1.42-30.33,1.67-25.6.71-50.57,1.01-76.29,1.87,47.93-10.69,96.06-20.53,144.13-30.69,3.76-.71,7.63-.13,11.44-.06,24.96.95,49.98.73,74.92,2.31-40.77,8.7-82.14,15.72-122.75,24.66Zm124.89-30.83s0-.05,0-.08c-27.26-1.06-54.51-2.25-81.77-3.38-.84-.03-1.16-.26-1.28-1.13-2.62-17.9-5.32-35.79-7.93-53.69-6.32-42.88-12.57-85.78-18.97-128.64-.25-1.77-.18-1.84,1.51-1.21,28,11.56,58.98,19.97,85.53,33.12,8.5,51.22,17.13,102.43,25.74,153.63.5,2.07-1.63,1.2-2.84,1.38Z"/>
          <path fill="#e8c305" d="m233.6,282.07c-1.79-4.26-3.96-8.24-8.81-9.49-17.16-4.64-25.1-19.23-35.5-32.67-9.43,25.2-14.29,61.22,15.17,73.76,19.57,8.54,18.85,32.97,21.94,50.95,7.42-27.2,21.04-55.34,7.21-82.55Z"/>
          <path fill="#e8c305" d="m291.12,426.54c-5.95-29.41-21.08-16.84-21.4-50.49-10.97,38.32,11.85,32.55,21.4,50.49Z"/>
          <path fill="#e8c305" d="m418.14,800.4c8.64-.29,17.16-1.5,25.62-3.26,1.53-.43,7.93-1.37,8.22-2.79-1.26-3.52-54.5,6.12-33.84,6.05Z"/>
          <path fill="#e8c305" d="m443.09,800.72s0-.04,0-.06c6.14.09,12.25-1.11,18.29-2.18,1.82-.63,13.53-2.12,13.21-3.79-1.06-2.95-51.86,5.76-31.5,6.02Z"/>
          <path fill="#e8c305" d="m378.12,809.75c-.07-.68-.81-.49-1.3-.51-8.84-.19-17.61,1.16-26.29,2.73-3.33.39-15.15,3.48-4.27,3.14,10.35-.46,20.69-1.72,30.7-4.5.46-.12,1.21-.25,1.15-.85Z"/>
          <path fill="#e8c305" d="m462.44,800.68c10.73.14,21.39-1.94,31.77-4.47.57-.14,1.55-.43,1.46-1.14-.33-.94-1.79-.4-2.71-.54-10.21.62-20.84,1.86-30.74,4.86-1.67.3-1.41,1.67.22,1.3Z"/>
          <path fill="#e8c305" d="m402.91,809.4c-.13-.75-1.16-.43-1.8-.44-4.97-.1-9.87.61-14.79,1.22-5.6.7-11.13,1.7-16.58,3.17-.58.16-1.09.24-1.38,1,5.47,1.11,11.11-.36,16.6-.8,5.53-.73,11.04-1.58,16.4-3.18.59-.18,1.67-.21,1.54-.97Z"/>
          <path fill="#e8c305" d="m415.66,809.27c-7.03.67-13.97,1.83-20.82,3.53-9.62,4.05,30.67-1.53,31.34-3.58-3.48-1.3-6.97-.05-10.52.05Z"/>
          <path fill="#e8c305" d="m382.7,672.87c27.4-1.8,20.63-53.71-6.56-35.27-11.45,9.07-10.84,34.29,6.56,35.27Z"/>
          <path fill="#e8c305" d="m309.07,763.53c.11,4.36.76,8.18,2.72,11.72,14.81,21.45,34.78-8.41,23.58-24.16-9.9-15.32-26.86-1.57-26.3,12.45Z"/>
          <path fill="#e8c305" d="m468.61,719.88c-4.8-4.35-9.86-.36-10.81,5.06-1.85,10.85.95,23.21,9.49,30.52,7.48,5.29,11.31-4.84,11-10.84-.26-9.14-2.74-18.59-9.68-24.75Z"/>
          <path fill="#e8c305" d="m464.52,655.24c-.11-6.63-1.98-12.78-5.29-18.5-1.48-2.55-3.29-4.86-5.77-6.54-3.78-2.54-7.29-1.47-8.95,2.77-2.86,9.91.34,20.06,6.06,28.31,7.06,9.64,14.49,4.53,13.95-6.05Z"/>
          <path fill="#e8c305" d="m502.4,736.71c.33,8.36,2.58,17.85,9.44,23.23,4.02,2.88,7.42-1.23,7.95-5.11,1.44-9.74-.91-21.04-8.26-27.95-6.54-5.39-9.71,4.57-9.13,9.82Z"/>
          <path fill="#e8c305" d="m506.9,667.69c-.32-6.09-1.95-12.2-5.27-17.81-4.13-7.61-11.92-9.45-12.65,1.13-.23,9.4,2.41,20.15,10.19,26.07,6.02,3.52,8-4.63,7.74-9.39Z"/>
        </g>
      </g>
    </svg>
  )
}