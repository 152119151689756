import { io } from "socket.io-client";

const URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PROD
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? process.env.REACT_APP_STAGING
    : "http://localhost:4000";

export const server = io(URL);
export const deposit = io(URL + "/deposit");
export const game = io(URL + "/game");
export const socket = (route) => {return io(URL+route)}