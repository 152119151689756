export const SmallFlame21 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="302.09 642.49 455.43 589.77 474.77 638.28 530.39 806.19 356.7 861.77 285.15 699.2 302.09 642.49"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m522.74,773.55c40.26-47.67,63.54-113.91,17-166.26-46.98-61.06-95.54-26.19-116.62-128.19-24.85,11.43-28.7,45.47-26.06,69.93-12.76,1.14-25.46,2.53-37.44,7.68-11.88,26.09-27.86,49.1-48.86,68.69-25.97-19.75-52.99-37.67-82.74-51.29-3.46-1.6-3.48-1.57-6.82-1.14,33.22,34.28,11.28,84.76.65,123.96-8.76,44.91,7.51,83.78,31.54,119.72,21.46,30.62,61.35,39.19,97.39,38.33,1.29,2.97,2.58,5.94,3.86,8.91.46,1.06.87,1.28,1.94.91,4.81-1.62,9.65-3.16,14.48-4.73,39.74-12.95,79.47-25.9,119.21-38.85,13.88-4.52,27.76-9.05,41.67-13.5,1.52-.49,1.78-.96,1.27-2.49-3.51-10.56-7-21.12-10.48-31.69Zm-232.93-77.9s0,0,0,0c.72-3.7,1.94-7.27,2.98-10.88,3.92-13.68,7.93-27.34,11.85-41.02.18-.64.44-1.1.81-1.45,49.35-17.11,98.42-34.13,147.99-51.31.93-.17,1.39.18,1.84,1.4,4.91,13.36,9.91,26.69,14.89,40.03.7,1.89.72,1.88-1.14,2.51-34.68,11.84-69.36,23.67-104.04,35.51-24.42,8.34-48.84,16.69-73.26,25.03-.54.18-1.11.29-1.66.43-.09-.08-.17-.17-.26-.25Zm235.63,109.01c-41.07,13.35-82.13,26.75-123.2,40.14-13.99,4.56-27.98,9.09-41.95,13.72-1.57.52-2.19.29-2.86-1.27-16.42-38.24-32.89-76.46-49.36-114.68-5.36-12.45-10.74-24.9-16.11-37.35-.84-1.94-.85-1.93,1.21-2.63,39.26-13.37,78.52-26.74,117.78-40.11,20.13-6.85,40.25-13.71,60.37-20.56.4-.14.79-.27,1.25-.42.83-.05.73.73.89,1.2,2.55,7.59,5.07,15.19,7.6,22.79,15.16,45.65,30.31,91.3,45.5,136.94.45,1.35.26,1.79-1.12,2.24Z"/>
          <path fill="#e8c305" d="m413.46,737.01c-3.98-.02-7.39.78-11.32,3.15-10.24,6.18-12.27,19.28-3.68,27.66,8.55,8.34,23.71,7.01,30.82-2.63,3.3-4.48,4.36-9.45,2.85-14.84-2.37-8.47-10.47-13.27-18.67-13.34Z"/>
          <path fill="#e8c305" d="m406.51,644.51c1.34.49,2.77.64,4.17.51,9.98-.94,19.16-4.17,27.48-9.78,1.12-.76,2.16-1.66,2.92-2.81,1.15-1.73.67-3.13-1.35-3.69-1-.28-2.04-.45-3.12-.43-10.07.91-19.4,3.99-27.84,9.63-1.35.9-2.6,1.96-3.43,3.39-.89,1.55-.54,2.55,1.16,3.18Z"/>
          <path fill="#e8c305" d="m363.57,646.28c.95.3,1.94.44,3.28.32.14,0,.61.03,1.08,0,.52-.04,1.04-.13,1.55-.22,8.95-1.48,17.29-4.52,24.67-9.89.92-.67,1.8-1.45,2.32-2.52.69-1.42.32-2.31-1.17-2.77-1.42-.44-2.9-.45-4.36-.27-8.27,1.04-15.95,3.81-23.11,8.03-2.02,1.19-4.03,2.47-5.28,4.57-.8,1.36-.48,2.28,1.02,2.75Z"/>
          <path fill="#e8c305" d="m325.5,647.97c1.04.23,2.07.19,3.12.08,4.88-.53,9.53-1.94,14.04-3.78,4.27-1.75,8.48-3.65,11.95-6.78.89-.81,2.27-1.66,1.75-3.05-.57-1.53-2.24-1.22-3.45-1.37-.48.03-.85.02-1.21.07-7.63.95-14.71,3.56-21.42,7.24-2.16,1.19-4.25,2.5-5.88,4.39-1.37,1.59-.94,2.77,1.1,3.22Z"/>
        </g>
      </g>
    </svg>
  )
}