export const BarDiceLogo = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg"
      width={props?.w ?? "50"}
      height={props?.h ?? "50"}
      viewBox={props?.v ?? "0 0 50 50"}
    >
      <ellipse
        cx="2.55112"
        cy="2.37356"
        rx="2.55112"
        ry="2.37356"
        transform="matrix(0.651527 0.758626 -0.802193 0.597065 8.80811 31)"
        fill="#E8C305"
      />
      <path
        d="M19.1197 34.2071C18.1281 34.9452 16.58 34.677 15.6621 33.6081C14.7441 32.5393 14.8038 31.0745 15.7955 30.3364C16.7871 29.5983 18.3352 29.8665 19.2531 30.9353C20.1711 32.0042 20.1114 33.469 19.1197 34.2071Z"
        fill="#E8C305"
      />
      <ellipse
        cx="2.91556"
        cy="2.64307"
        rx="2.91556"
        ry="2.64307"
        transform="matrix(0.651527 0.758626 -0.802193 0.597065 24.2405 23)"
        fill="#E8C305"
      />
      <ellipse
        cx="2.53741"
        cy="2.48698"
        rx="2.53741"
        ry="2.48698"
        transform="matrix(0.651527 0.758626 -0.802193 0.597065 23.9901 36)"
        fill="#E8C305"
      />
      <ellipse
        cx="2.71519"
        cy="2.69206"
        rx="2.71519"
        ry="2.69206"
        transform="matrix(0.651527 0.758626 -0.802193 0.597065 11.3191 20)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.91744"
        cy="3.04213"
        rx="1.91744"
        ry="3.04213"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 23.4543 11.0928)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.63374"
        cy="2.83253"
        rx="1.63374"
        ry="2.83253"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 20.5283 6.7085)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.49697"
        cy="2.83253"
        rx="1.49697"
        ry="2.83253"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 18.1375 2.63672)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.48701"
        cy="3.05388"
        rx="1.48701"
        ry="3.05388"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 28.7559 4.46875)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.37564"
        cy="2.64029"
        rx="1.37564"
        ry="2.64029"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 25.5813 0.753906)"
        fill="#E8C305"
      />
      <ellipse
        cx="1.82821"
        cy="3.04213"
        rx="1.82821"
        ry="3.04213"
        transform="matrix(0.181256 0.983436 -0.993678 0.112269 32.6553 8.59961)"
        fill="#E8C305"
      />
      <path
        d="M34.3137 44.1176L17.6471 50L33.3333 43.1373L28.4314 21.5686L8.82353 16.6667L0 33.3333L6.86275 15.6863L7.84314 5.88235L2.94118 10.7843L8.82353 2.94118V14.7059L28.4314 18.6275L40.1961 7.84314L29.4118 0L41.1765 6.86275L50 20.5882L41.1765 8.82353L30.3922 20.5882L34.3137 44.1176Z"
        fill="#E8C305"
        stroke="#E8C305"
        strokeWidth="0.001"
      />
    </svg>
  );
};
