export const SmallFlame60 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="287.74 812.94 444.82 804.73 531.09 795.27 518.13 628.8 424.72 605.68 277.99 636.09 287.74 812.94"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m529.75,761.49c41.72-48.3,47.51-129.83-12.77-166.15-10.17-10.81-22.11-19.12-35.4-25.52-23.94-16.06-33.97-44.46-49.11-68.12-13.23,15.57-28.26,28.94-28.16,51.23-69.58,2.73-58.23,31.37-91.11,73.4-4.03.81-8.06,1.62-12.1,2.42-26.73-17.48-52.98-33.62-82.55-46.61,38.02,60.63.35,109.57,14.82,166.25,9.69,138.35,176.17,122.41,257.39,53.67,13.34-1.47,26.66-3.13,39.92-5.06,1.46-.17,1.8-.63,1.68-2.01-.92-11.17-1.77-22.34-2.61-33.51Zm-93.81,40.28c-14.69.76-29.38,1.58-44.08,2.38-30.07,1.55-60.13,3.24-90.2,4.79-3.29.17-6.59.32-9.88.57-1.11.08-1.48-.27-1.53-1.35-1.52-29.85-3.24-59.68-4.77-89.53-1.5-26.33-2.79-52.69-4.3-79.01-.12-1.27.18-1.75,1.42-1.98,46.96-9.1,93.88-18.43,140.82-27.64.97-.19,1.39-.06,1.46,1.08,4.17,63,8.64,125.9,12.84,188.91.53,1.55-.39,1.72-1.79,1.79Zm-148.78,12.74s.07.05.11.07h-.11s0-.04,0-.07Zm241.24-22.27c-16.08,1.69-32.16,3.4-48.24,5.12-10.77,1.15-21.54,2.29-32.31,3.49-1.22.14-1.6-.15-1.69-1.42-.87-13.16-1.83-26.31-2.73-39.47-3.34-50.15-6.98-100.14-10.33-150.31,27.74,7.12,55.24,14.41,82.95,21.56,1.2.31,1.53.81,1.62,1.99,2.79,36.47,5.67,72.93,8.45,109.39,1.27,16.01,2.47,32.03,3.76,48.04.11,1.22-.41,1.5-1.47,1.62Z"/>
          <path fill="#e8c305" d="m403.38,651.34c-14.38-23.56-39.6,5.96-27.4,23.66,10.56,16.94,31.31,2.51,29.72-13.81.06-3.49-.7-6.78-2.32-9.84Z"/>
          <path fill="#e8c305" d="m310.15,751.27c-11.04,14.54,5.2,43.99,21.66,25.97,3.02-3.85,4.24-8.33,4.35-14.02.37-14.08-15.53-26.6-26.01-11.94Z"/>
          <path fill="#e8c305" d="m460.57,735.37c-5.91,8.28-4.91,19.34-1.68,28.47,1.49,5.08,7.5,12.04,12.75,7,3.94-4.1,4.43-10.04,4.7-15.5-.32-7.22-1.68-15.36-7.29-20.53-2.75-2.4-6.06-2.17-8.48.56Z"/>
          <path fill="#e8c305" d="m458.7,678.63c8.55,4.66,11.24-8.26,11.06-14.6-.24-5.43-1.25-11-3.94-16.15-4.15-8.75-12.62-10.05-15.17.46-2.17,10.39-.56,23.26,8.06,30.29Z"/>
          <path fill="#e8c305" d="m510.51,735.56c-10.73-6.58-11.23,17.13-9.05,22.98,4.89,19.28,16.53,12.61,15.56-4.99-.08-4.88-1.75-14.04-6.51-17.99Z"/>
          <path fill="#e8c305" d="m510.69,671.87c-.1-5.23-.92-10.31-3.12-15.1-1.55-4.44-7.54-10.33-11.15-4.46-4.46,9.04-2.69,19.82,1.95,28.48,7.27,11.19,12.91-.73,12.33-8.93Z"/>
        </g>
      </g>
    </svg>
  )
}