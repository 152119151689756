export const SmallFlame57 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="302.59 825.34 416.6 838.83 549.89 771.94 497.94 626.73 396.61 575.57 268.71 665.1 302.59 825.34"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m538.79,739.05c27.17-54.92,10.74-121.64-45.58-149.66-21.08-14.11-40.11-31.69-59.88-47.58-11.64,7.29-22.92,14.34-34.16,21.44-19.06,5.68-39.49,7-57.38,17.37-23.37,9.36-41.88,26.18-53.75,48.38-6.01,10.27-9.9,21.84-19.36,30.68-22.86-4.23-39.23-16.6-47.74-40.26-8.33,48.97,15.71,94.92,23.93,142.38,19.59,107.66,169.73,107.06,236.56,45.78,22.72-11.38,45.42-22.84,68.19-34,1.18-.58,1.52-1.1.99-2.38-4.1-10.73-8-21.43-11.8-32.14Zm-234.23,79.21c-11.09-50.36-22.02-100.77-33.15-151.12,16.17-12.04,32.77-23.81,49.5-35.5,24.29-16.71,48.57-33.42,72.88-50.14.1.22.19.54.32.97,16.02,52.72,31.85,105.5,48,158.19.48,1.54.09,2.17-1.23,2.91-27.85,15.54-55.68,31.13-83.51,46.71-17.08,9.57-34.18,19.12-51.27,28.69-.93.57-1.3.43-1.54-.71Zm172.91-10.59c-11.42,5.11-22.6,11-33.78,16.83-7.42,3.71-14.83,7.44-22.22,11.19-2.69,1.08-5.34,1.36-8.23,1.15-.15-.02-.29-.04-.44-.05-34.09-4.08-68.31-7.26-102.31-11.91,45.08-25.16,90.44-49.83,135.54-74.97,32.5,4.24,65.38,16.99,97.91,24.24-22,11.39-44.23,22.46-66.48,33.52Zm69.12-40.66c-.35.36-.89.02-1.33-.1-30.92-8.58-61.83-17.17-92.75-25.7-1.12-.31-1.59-.87-1.91-1.92-15.97-52.44-32.6-104.7-48.27-157.21,28.07,13.43,56,26.8,83.78,40.09,3.93,2.14,7.81,4.33,11.65,6.56,16.17,45.67,32.43,91.32,48.59,136.99.14.42.62.89.25,1.28Z"/>
          <path fill="#e8c305" d="m376.69,656.3c6.64-5,9.6-11.92,9.83-20.1-3.11-29.59-34.24-9.11-29.22,12.33,1.11,9.37,12.11,13.97,19.39,7.76Z"/>
          <path fill="#e8c305" d="m320.42,745.72c-5.43,4.89-7.64,11.19-7.85,18.33-.61,11.16,9.72,22.48,20.57,12.98,14.16-9.79,7.5-45.75-12.71-31.3Z"/>
          <path fill="#e8c305" d="m460.01,690.42c-6.82-3.28-8.93,3.93-7.67,9.19,2.41,10.27,8.23,21.73,18.74,25.46,4.09,1.23,6.32-3.15,6-6.65-.94-11.11-7.3-22.42-17.07-28Z"/>
          <path fill="#e8c305" d="m443.34,620.73c-4.05-6.29-17.72-15.23-16.19-1.06,2.24,10.31,8.93,22.26,19.41,25.66,3.27.77,5.21-2.54,4.96-5.43-.27-7.2-3.91-13.61-8.18-19.17Z"/>
          <path fill="#e8c305" d="m508.61,708.22c-4.02-2.27-6.83.84-6.42,4.93-.03,0-.06,0-.09,0,.59,7.97,4.25,15.13,9.28,21.19,3.99,5.6,13.61,10.14,12.68-.83-1.57-9.86-6.71-20.01-15.45-25.29Z"/>
          <path fill="#e8c305" d="m481.14,635.33c-2.94-.75-4.2,2.29-4.15,4.71,1.16,9.59,6.51,18.75,14.3,24.53,4.24,3.57,8.54.54,7.25-4.67-1.92-9.62-7.79-21.04-17.39-24.57Z"/>
          <path fill="#e8c305" d="m428.96,783.68c9.93-.89,20.76-3.51,27.94-10.83,3.93-4.82-3.23-6.66-6.98-6.35-10.19.63-21.16,3.32-28.67,10.6-4.64,5.21,3.76,7.13,7.71,6.58Z"/>
          <path fill="#e8c305" d="m454.36,788.87c7.91-.02,18.69-3.42,25.09-7.88,2.57-1.46,6.41-6.01,1.88-7.56-9.74-2.26-19.68,1.47-28.25,5.9-7.33,3.88-8.81,9.82,1.28,9.54Z"/>
          <path fill="#e8c305" d="m381.01,804.22c-9.66.62-20.12,2.78-27.51,9.4-4.34,4.73,3.71,6.16,7.15,5.86,6.38-.28,12.53-1.67,18.42-4.12,7.99-2.72,16.15-10.87,1.94-11.14Z"/>
          <path fill="#e8c305" d="m477.99,793.77c8.29-.21,15.96-2.65,23.17-6.63,6.83-3.72,8.56-9.05-1.17-8.8-8.66.6-16.74,3.04-24.07,7.77-5.74,3.51-4.96,7.88,2.07,7.66Z"/>
          <path fill="#e8c305" d="m408.68,808.58c-9.5.71-20.03,2.8-27.03,9.7-2.95,3.59,2.95,4.96,5.61,4.75,8.23-.06,15.91-2.26,23.16-6.03,7.37-3.68,7.13-8.75-1.74-8.42Z"/>
          <path fill="#e8c305" d="m434.02,812.59c-9.22.82-19.32,2.87-26.11,9.56-4.13,8.52,20.7,2.03,23.76.07,6.42-2.2,15.22-9.66,2.35-9.63Z"/>
          <path fill="#e8c305" d="m278.15,418.17c-8.53-16.1-17.4-31.04-31.86-43.34-2.75,7.4-3.17,13.74,1.44,19.29,8.06,8.47,18.16,22.57,30.42,24.05Z"/>
        </g>
      </g>
    </svg>
  )
}