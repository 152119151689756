import { fetcher } from "../../utils/fetcher";
import { backend } from "../../games/BarDice100/BarDice100";
/**
 * Validates that the given deposit info is valid.
 *
 * @params ... string fields in the User to update.
 * @param setErrors callback to set errors if the fields are invalid.
 */
export function validate(username, firstName, lastName, email, setErrors) {
  const newErrors = {};

  // Validate Username
  if (!username.trim()) {
    newErrors.username = "Username is required";
  } else if (username.length < 4 || username.length > 20) {
    newErrors.username = "Username must be between 4 and 20 characters";
  } else if (!/^[a-zA-Z0-9_]+$/.test(username)) {
    newErrors.username = "Invalid characters in username";
  }

  // Validate First Name
  if (!firstName.trim()) {
    newErrors.firstName = "First Name is required";
  }

  // Validate Last Name
  if (!lastName.trim()) {
    newErrors.lastName = "Last Name is required";
  }

  // Validate Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.trim()) {
    newErrors.email = "Email Required";
  } else if (!email.match(emailRegex)) {
    newErrors.email = "Invalid Email";
  }
  console.log("how many errors?", Object.keys(newErrors).length === 0);
  // Return true if there are no errors, false otherwise
  return Object.keys(newErrors).length === 0;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (email.match(emailRegex)) {
    return email.length < 254;
  }
  return false;
}

/**
 * Updates the User's info in the database based on the deposit info Modal.
 *
 * @param e event that instigated the update.
 * @params ... string fields in the User to update.
 * @param authHeader callback to generate the auth header.
 * @param advance callback to move on from the FinancialInfoModal.
 * @param setErrors callback to set errors if the fields are invalid.
 */
function update(
  e,
  username,
  firstName,
  lastName,
  email,
  authHeader,
  advance,
  user = {},
  setUser = () => {}
) {
  e.preventDefault();

  // Sends the information to the backend if the fields are valid.

  console.log("updating");
  if (validate(username, firstName, lastName, email, () => {})) {
    // Attempts to update the User info in the database.
    fetcher("POST", authHeader(), `${backend}/users/update-deposit-info`, {
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
    })
      .then((res) => {
        console.log(res);
        setUser(...user, res.data);
      })
      // Closes the modal on success.
      .finally((res) => advance())
      // Does nothing on error.
      .catch((err) => {});
  }
}

// Consolidates DepositModalHelpers into one namespace.
const DepositModalHelpers = {
  update,
  validate,
};
export default DepositModalHelpers;
