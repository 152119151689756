export const NoFlame52 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="271.59 721.81 317.88 818.85 463.72 859 557.85 715.43 480.42 640.51 351.61 579.3 271.59 721.81"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m464.21,860.34c-49.16-13.3-98.31-26.61-147.44-40.02-11.82-18.86-19.54-41.68-29.97-61.85-5.31-11.37-10.6-22.74-15.94-34.1-.48-1.02-.43-1.78.11-2.75,26.27-47.15,52.55-94.3,78.75-141.5.73-1.32,1.36-1.48,2.68-.85,42.5,20.12,85.02,40.2,127.55,60.27,27.11,24.31,52.64,50.72,79.28,75.67.99.93,1.05,1.58.28,2.73-32.04,47.01-62.6,96.64-95.3,142.41Zm-.98-4.88c30.6-43.97,59.63-90.16,89.76-134.84.7-1.06.78-1.49-.56-2.05-44.78-19.13-89.5-38.42-134.24-57.65-1.18-.51-1.7-.29-2.33.78-3.63,6.19-7.32,12.34-11,18.49-26.86,44.95-53.72,89.9-80.58,134.84-1.1,1.84-1.1,1.83,1.06,2.42,45.97,12.66,91.94,25.3,137.9,38Zm-146.53-43.11c30.08-52.49,64.68-104.77,92.28-157.53-19.49-22.94-38.82-46.01-58.29-68.97-.45-.54-.9-1.33-1.58-.13-1.67,2.93-3.46,5.8-5.09,8.75-22.77,41.84-46.29,83.27-69.44,124.9-.81,1.45-.91,2.56-.2,4.05,14.2,29.66,28.15,59.22,42.32,88.92Zm236.06-100.79c-22.75-23.66-47.82-46.13-71.26-69.47-40.29-21.05-82.55-39.42-123.71-58.86-.12.51.16.77.39,1.03,1.77,2.13,3.46,4.33,5.34,6.37,17.96,20.15,35.28,40.86,53.18,61.06,44.66,21.06,90.83,39.75,136.06,59.88Zm-195.26-128.49c-.06-.1-.11-.11-.18-.02.04.03.09.06.13.1.02-.03.04-.05.05-.08Zm195.82,128.57l-.08-.05v.07s.08-.02.08-.02Z"/>
          <path fill="#e8c305" d="m357.5,583.07s-.04.05-.05.08c-.04-.03-.09-.06-.13-.1.08-.09.12-.09.18.02Z"/>
          <polygon points="553.32 711.64 553.22 711.66 553.24 711.59 553.32 711.64"/>
          <path fill="#e8c305" d="m425.83,732.57c-26.45-1.57-13.33-35.4,7.04-35.33,27.28.07,14.33,36.24-7.04,35.33Z"/>
          <path fill="#e8c305" d="m387.79,774.99c10.7-.52,17.53,11.73,11.06,20.85-8.48,17.82-42.93,15.65-32.9-7.6,4.69-9.31,14.33-13.25,21.83-13.25Z"/>
          <path fill="#e8c305" d="m483.64,725.78c-.47,10.38-12.48,21.02-23.6,20.02-8.54-.62-13.49-7.17-11.88-15.66,2.05-17.39,34.63-28.32,35.48-4.36Z"/>
          <path fill="#e8c305" d="m423.55,786.9c10.23-.93,16.98,10.03,11.48,18.67-5.72,15.51-38.12,19.55-32.93-2.6,2.93-9.66,11.57-15.45,21.44-16.07Z"/>
          <path fill="#e8c305" d="m516.66,739.52c-.68,9.65-13.75,20.85-24.69,18.56-5.92-1-9.96-6.12-9.48-12.14-.02-17.75,33.7-31.96,34.17-6.42Z"/>
          <path fill="#e8c305" d="m456.41,798.15c27.34.57,9.53,31.87-8.12,30.52-9.35-.03-14.45-7.95-10.52-16.5,3.94-8.58,10.91-13.04,18.65-14.01Z"/>
          <path fill="#e8c305" d="m315.12,759.63c.57-8.89,3.15-16.99,9.42-23.63,10.03-9.39,13.42,4.86,11.22,12.8-1.8,7.99-5.18,17.44-13.27,20.89-5.25,1.76-7.67-5.55-7.36-10.05Z"/>
          <path fill="#e8c305" d="m332.11,645.29c.98-9.43,3.99-17.92,11.51-24.25,3.7-3.59,8.65-1.09,8.8,3.9.71,9.73-3.53,19.92-10.79,26.4-5.6,4.81-9.79.48-9.53-6.04Z"/>
          <path fill="#e8c305" d="m450.91,659.63c-10.36-2.49-24.63-10.79-31.25-19.26-3.39-9.76,43.64,18.88,31.25,19.26Z"/>
          <path fill="#e8c305" d="m517.69,689.37c-10.11-2.8-19.11-8.93-27.02-15.73-6.92-6.56.47-5.03,4.9-2.7,2.58.54,30.29,17.18,22.12,18.44Z"/>
          <path fill="#e8c305" d="m389.24,603.67c10.26,2.4,19.34,8.52,27.4,15.16,5.68,5.25-.29,4.35-4.07,2.6-2.43-.12-31.21-16.72-23.33-17.76Z"/>
          <path fill="#e8c305" d="m480.48,652.08c-9.04-2.28-17.08-7.99-24.37-13.67-9.07-8.28,3.42-3.38,7.17-1.09,1.79.53,24.27,14.38,17.21,14.76Z"/>
        </g>
      </g>
    </svg>
  )
}