export const NoFlame24 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="304.42 627.63 459.02 573.78 517.49 698.72 525.79 819.08 360.55 859.23 273.73 741.14 304.42 627.63"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m526.06,817.33c.03,3.13.47,2.8-2.67,3.59-54.63,12.26-110.4,30.1-164.5,39.61-28.91-39.14-57.86-78.26-86.83-117.36,7.05-38.23,20.55-76.78,29.69-115.13,49.96-20.72,104.37-36.03,155.76-55.04,1.5-.52,1.99-.23,2.63,1.15,18.45,39.81,36.91,79.61,55.5,119.36,2.14,4.58,3.39,9.14,3.65,14.23,2.04,36.47,4.48,73.16,6.78,109.58Zm-163.6,40c51.64-12.97,103.32-25.84,154.97-38.78,1.84-.46,1.85-.47.97-2.15-21.56-41.07-43.16-82.13-64.68-123.23-.48-.92-.86-1.29-2.02-.94-37.64,11.4-75.29,22.73-112.91,34.18-19.49,5.9-38.98,11.8-58.48,17.66-1.14.34-1.15.63-.5,1.53,27.61,37.02,54.42,75.61,82.64,111.72Zm-85.09-120.93c57.38-16.73,114.69-35.09,172.06-52.31,1.22-.37,1.67-.94,1.6-2.2.55-14.47.98-28.98,1.47-43.47.69-19.93,1.34-39.86,1.94-59.79.06-1.81.03-1.82-1.77-1.2-48.46,16.7-96.91,33.42-145.38,50.06-.99.34-1.7.69-2.01,1.87-9.23,35.69-18.69,71.33-27.91,107.03Zm184.2-156.06c-.18.03-.36.06-.53.08-.34,34.7-1.46,69.39-2.01,104.09,19.56,42.85,43.45,84.46,64.61,126.8-.99-36.15-4.34-72.45-6.13-108.65-16.28-41.39-37.84-81.43-55.94-122.32Z"/>
          <path fill="#e8c305" d="m402.66,768.63c28.15,1.68,25.13,30.74-2.8,26.93-22.8-3.86-20.43-27.17,2.8-26.93Z"/>
          <path fill="#e8c305" d="m400.26,681.37c-5.42.51-11.5-3.55-9.4-9.53,3.99-10.75,15.77-17.23,26.86-17.88,6-.35,11.54,3.83,9.08,10.22-4.29,10.38-15.69,16.43-26.54,17.19Z"/>
          <path fill="#e8c305" d="m361.54,665.64c-13.74,0-7.74-13.37-.34-18.27,6.1-4.57,13.64-8.3,21.48-6.84,10.66,3.32,1.4,15.4-4.45,18.96-4.97,3.69-11.48,6.08-16.68,6.15Z"/>
          <path fill="#e8c305" d="m344.95,627.85c20.72,1.75-8.18,28.64-22.02,23.12-8.97-5,3.5-16.32,9.12-19.08,3.99-2.28,8.21-3.92,12.89-4.03Z"/>
          <path fill="#e8c305" d="m483.68,704.24c.31,7.27-3.51,6.13-6.18.82-4.9-9.71-8.87-20.66-8.07-31.65,1.68-6.12,6.52,2.91,7.47,5.22,3.66,8.1,6.3,16.7,6.78,25.61Z"/>
          <path fill="#e8c305" d="m514.66,767.86c-.58,9.65-6.42-1.17-7.49-4.61-3.03-8.07-5.85-16.72-5.18-25.44.95-5.54,5.3,1.58,6.05,3.69,3.73,8.38,6.26,17.13,6.62,26.36Z"/>
          <path fill="#e8c305" d="m498.66,707.12c-.62,14.99-10.6-10.17-10.92-13.78-1.27-5.04-2.79-10.36-1.89-15.59,4.07-8.61,13.96,26.26,12.82,29.36Z"/>
          <path fill="#e8c305" d="m483.01,648.89c.09,6.84-3.67,3.56-5.53-.33-4.36-9-8.29-18.94-7.74-29.06.99-4.49,4.53,1.06,5.37,2.83,4.32,8.36,6.99,17.2,7.89,26.56Z"/>
          <path fill="#e8c305" d="m511.71,709.86c-1.28,16.85-14.67-24.53-11.73-27.92,3.89-7.32,12.48,24.98,11.73,27.92Z"/>
        </g>
      </g>
    </svg>
  )
}