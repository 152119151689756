export const SmallFlame23 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="304.75 630.5 460.13 577.56 514.67 712.72 526.99 815.33 357.18 863.8 275.96 725.36 304.75 630.5"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m522.74,771.22c61.07-68.09,41.41-170.74-43.28-207.83-50.77-20.43-51.71-58.92-87.77-82.89-2.63,21.12-8.26,40.28-26.27,53.73-3.56,2.83-6.38,6.19-8.42,10.13-20.69,1.54-40.92,4.19-60.96,9.16,11.91,15.68,13,32.74,8.34,51.32-25.05-1.97-49.03-6.13-73.51-10.15,33.2,43.35-2.77,96.11,2.93,144.15,7.55,68.23,46.3,111.07,115.46,113.17,3.09,4.99,5.52,8.92,7.03,11.42.68,1.13,1.26,1.41,2.58,1.04,18.47-5.24,130.3-36.57,167.74-47.03,1.29-.36,1.78-.91,1.6-2.26-.55-3.96-2.91-23.07-5.48-43.97Zm-3.92-31.82c-.97-7.86-1.84-14.87-2.5-20.11,1.21,5.79,2.03,11.7,2.34,17.77.04.78.1,1.56.15,2.34Zm-6.87-36.14c-1.09-2.5-2.21-4.98-3.15-7.51-4.32-10.74-8.68-21.46-13.03-32.18,3.24,7.76,6.52,15.51,9.86,23.24,2.34,5.45,4.47,10.92,6.31,16.45Zm-56.03-121.97c.49,13.86,1.03,41.61,1.01,41.61.37,13.55.47,27.13,1,40.67.1,1.19-.29,1.72-1.47,2.1-12.5,4.04-129.61,43.08-175.51,57.03,4.14-15.05,8.28-30.05,12.41-45.04,5.49-19.28,10.93-38.05,12.56-43.88.33-1.18.91-1.83,2.08-2.24,24.05-8.41,46.1-16.12,67.49-23.61,26.74-9.21,53.47-18.42,80.22-27.63.14.19.21.51.2.99Zm64.11,233.64c-53.29,14.95-106.57,29.95-159.86,44.94-26.52-41.28-51.09-85.17-76.97-127.31-1.03-1.72-1.03-1.68.86-2.3,58.02-18.85,116.05-37.65,174.05-56.54,1.48-.48,2.04-.21,2.65,1.21,19.99,46.05,40.03,92.08,60.1,138.1.49,1.12.46,1.53-.83,1.9Zm-53.84-145.78c-.98-2.29-.6-4.76-.71-7.13-.99-26.08-2.02-52.12-2.98-78.2,17.65,41.59,34.01,83.91,51.2,125.76,1.1,2.75,1.02,5.75,1.38,8.65,2.38,17.45,9.34,75.98,10.68,87.78.04.38.22.8-.28,1.36-19.83-46.06-39.52-92.14-59.31-138.22Zm59.72,138.61c-.08.04-.09.1-.08.16-.01-.06,0-.11.08-.16Z"/>
          <path fill="#e8c305" d="m391.4,763.06c-12.98,10.98.76,26.62,14.12,26.26,4.89-.09,9.01-1.03,12.8-3.3,21.9-16.48-11.31-37.08-26.92-22.96Z"/>
          <path fill="#e8c305" d="m404.78,667.6c10.65-1.17,22.83-6.09,27.49-16.35,1.92-5.17-3.86-7.6-8.14-7.38-10.17.93-18.98,4.72-25.91,12.43-5.67,6.47-1.26,12.04,6.56,11.31Z"/>
          <path fill="#e8c305" d="m357.26,650.96c-2.45,4.66,2.34,7.37,6.51,7.06,7-.3,13.22-2.88,18.95-6.72,5.93-3.16,14.41-14.63,2.35-15.29-10.8.25-22.24,5.53-27.81,14.95Z"/>
          <path fill="#e8c305" d="m347.99,628.8c-8.16.51-15.6,4.21-21.71,9.53-2.84,2.21-7.25,8.46-2.06,10.56,8.39,2.06,16.97-2.64,23.65-7.36,5.48-3.58,10.9-12.95.13-12.74Z"/>
          <path fill="#e8c305" d="m486.84,696.36c-1.64-11.41-4.99-23.24-11.07-33.11-.38-.5-.79-1.31-1.59-.99-1.25.84-.33,3.71-.39,5.09,1.8,10.66,5.02,21.28,10.42,30.7.47.77.97,1.92,2.04,1.59,1.09-.45.42-2.09.59-3.29Z"/>
          <path fill="#e8c305" d="m515.77,765.96c-1.37-10.71-4.51-21.41-9.48-31.01-1.19-2.82-3.19-2.5-2.55.66,1.04,10.77,4.51,21.4,9.4,31.05.46.83.86,2.18,2,1.85,1.09-.31.42-1.65.63-2.55Z"/>
          <path fill="#e8c305" d="m499.36,707.49c-.65-6.82-6.34-29.38-11.7-32.61-1.09.88-.31,2.92-.36,4.19,1.72,10.07,4.72,20.08,9.62,29.08.43.73.85,1.88,1.87,1.57.98-.3.4-1.47.58-2.23Z"/>
          <path fill="#e8c305" d="m482.83,652.83c1.04-.27.32-1.54.58-2.4-1.63-10.68-4.87-21.49-10.36-30.85-.79-1.75-2.24-1.12-1.93.67,1.23,10.75,4.87,21.31,9.89,30.89.43.76.96,1.91,1.82,1.69Z"/>
          <path fill="#e8c305" d="m507.62,716.73c.41.79,1.11,1.76,1.95,1.56,1.02-.24.32-1.44.56-2.21-1.57-10.15-4.53-20.39-9.56-29.38-.64-1.45-1.9-.92-1.69.53,1.18,10.18,4.31,20.28,8.74,29.5Z"/>
          <path fill="#e8c305" d="m174.06,503.01c-10.73,33.87.11,45.77,28.72,62.4,0,0,0,0,0-.01,0,0,.14.12.14.12l-.14-.12c-14.24-27.27-3.57-36.85-28.71-62.38Z"/>
        </g>
      </g>
    </svg>
  )
}