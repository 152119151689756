export const NoFlame09 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="312.14 618.8 443.4 577.07 561.15 691.1 491.78 796.55 374.36 867.13 271.67 741.06 312.14 618.8"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m562.53,690.86c-23.31,35.47-46.9,70.64-70.19,106.11-38.1,25.5-78.95,47.82-118.12,71.97-34.97-42.14-69.76-84.38-104.74-126.51-.67-.8-.58-1.43-.31-2.27,13.42-40.56,26.53-81.21,40.04-121.74,42.03-16.88,88.67-27.75,132.2-42.67,1.42-.44,2.32-.27,3.42.81,39.27,38.07,78.28,76.39,117.7,114.3Zm-6.26,5.84c-38.8,19.66-77.27,40.1-115.94,60.05-21.4,34.28-39.83,71.19-60.17,106.38,37.2-21.71,74.82-43.54,110.87-66.87,21.7-33.29,44.14-66.2,65.4-99.74-.05.06-.11.12-.16.18Zm-238.72-77.55c39.41,43.22,78.42,84.95,117.95,128.08,1.54,1.65,4.59.39,6.3-.49,37.86-19.42,75.9-38.49,113.77-57.89-36.99-36.34-74.36-72.34-111.42-108.63-.76-.74-1.39-.82-2.35-.51-17.36,5.54-100.11,31.8-124.24,39.44Zm-5.81,5.55c-12.97,38.53-25.77,77.1-38.73,115.64-.37,1.09-.21,1.8.5,2.66,6.42,7.67,72.31,87.17,98.97,119.19,19.35-35.37,38.56-70.76,57.91-106.13.47-.85.34-1.35-.28-2.04-11.56-12.67-90.19-99.04-118.37-129.33Z"/>
          <path fill="#e8c305" d="m556.27,696.69c.05-.06.11-.12.16-.18-.04.07-.08.14-.13.22,0,0-.03-.03-.03-.03Z"/>
          <path fill="#e8c305" d="m434.46,641.47c9.24.89,16.93,4.6,22.13,12.64,1.42,2.2,2.17,4.67,1.79,7.33-4.66,15.52-30.28,4.98-34.65-6.01-3.92-8.61,1.92-14.01,10.72-13.96Z"/>
          <path fill="#e8c305" d="m399.34,758.24c.24,6.57-4.11,13.91-11.89,11.2-10.35-4.14-16.24-15.81-16-26.65-.09-6.93,5.51-13.19,12.82-9.71,9.36,4.53,14.68,15.03,15.08,25.16Z"/>
          <path fill="#e8c305" d="m294,737.07c.46-1.91.77-3.87,1.4-5.73,2.58-6.87,11.3-2.93,14.36,1.49,5.41,6.56,8.89,15.12,6.84,23.69-1.23,4.29-4.95,5.99-9,4.14-8.54-4.47-12.74-14.3-13.6-23.59Z"/>
          <path fill="#e8c305" d="m466.5,755.22c5-.14,2.51,4.62,1.21,6.98-5.52,8.51-12.75,15.21-21.76,19.93-11.32,5.17-5.11-6.92-1.04-10.89,5.78-6.7,13.04-13.37,21.59-16.03Z"/>
          <path fill="#e8c305" d="m501.49,751.07c-1.62-.31-2.53-1.25-1.95-2.65.97-2.34,2.04-4.73,3.53-6.74,4.94-6.66,10.85-12.29,18.21-16.28,9.17-4.18,4.74,4.43,1.74,8.3-5.7,7.36-12.72,13.98-21.53,17.37Z"/>
          <path fill="#e8c305" d="m411.07,835.7c-9.18-2.15,15.98-23.86,20.29-24.63,8.64-3.81,6.29,1.92,2.83,6.74-6.09,7.73-13.72,14.56-23.12,17.88Z"/>
          <path fill="#e8c305" d="m469.25,801.64c-5.32.1.54-6.62,1.65-8.51,4.82-6,10.4-11.18,17.3-14.77,7.37-3.56,4.56,2.8,1.86,6.29-5.47,7-12.44,13.78-20.81,17Z"/>
        </g>
      </g>
    </svg>
  )
}