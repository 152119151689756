export const NoFlame55 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="266.4 688.05 311.36 826.96 436.87 852.06 556.48 751.36 488.85 630.85 374.51 570.75 266.4 688.05"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m436.02,853.18c-41.67-8.42-83.47-17.03-125.16-25.41-1.16-.23-1.35-.97-1.62-1.81-8.81-27.47-17.65-54.94-26.48-82.4-5.76-17.93-11.52-35.86-17.32-53.77-.34-1.06-.14-1.69.58-2.48,35.67-38.79,71.25-77.66,106.9-116.47,1.87-2.03,1.1-1.97,3.68-.61,37.1,20.49,76.4,38.39,112.41,60.22,22.61,39.69,45.14,79.41,67.9,119,1.17,2.03,1.17,2.02-.61,3.52-40.28,32.97-79.47,68.53-120.29,100.21Zm116.1-107.38c-19.7-37.05-43.29-76.1-64.25-113.83-34.53-20.32-71.74-38.12-107.35-56.85,19.65,41.82,40.59,83.3,60.63,125.03,20.49,11.8,45.41,18.3,67.36,28.28,14.56,5.73,28.96,11.87,43.61,17.36Zm-240.44,73.96c40.22-37.73,80.02-75.89,120.09-113.79,1.93-1.83,1.91-1.84.72-4.32-19.73-41.35-39.52-82.67-59.23-124.02-.59-1.24-.95-1.02-1.68-.2-1.82,2.02-3.7,3.99-5.55,6-32.06,34.83-64.07,69.7-96.2,104.46-.78.85-.59,1.53-.31,2.39,14.09,43.2,28.06,86.27,42.15,129.48Zm5.66,5.55c23.44,4.44,46,9.45,69.27,14.23,16.29,3.39,32.57,6.79,48.86,10.18.91.19,1.66.13,2.46-.54,37.25-31.46,74.55-62.85,111.82-94.29,1.64-1.38,1.64-1.42-.29-2.18-36.81-12.76-74.1-31.88-110.74-41.49-40.47,38.06-80.85,76.07-121.37,114.09Z"/>
          <path fill="#e8c305" d="m552.3,745.63c.27.17.07.48-.18.18.06-.06.12-.12.18-.18Z"/>
          <path fill="#e8c305" d="m314.33,762.63c.29-9.29,4.68-20.33,14.32-23.17,8.24-1.97,12.41,7.84,11.75,14.57-.24,8.62-4.26,17.71-12.13,21.94-8.6,4.25-14.48-5.58-13.94-13.34Z"/>
          <path fill="#e8c305" d="m371.44,629.06c-.75,10.01-6.11,21.73-17.03,23.56-4.14.27-7.79-2.83-8.77-7.23-5.45-20.19,23.68-43.91,25.81-16.33Z"/>
          <path fill="#e8c305" d="m431.23,763.97c-26.37-2.06-.27-29.29,18.44-25.97,8.95.29,10.18,9.16,5.01,14.49-6.29,7.29-14.43,10.78-23.45,11.48Z"/>
          <path fill="#e8c305" d="m477.09,748.45c4.64-.32,11.03,2.48,9.51,8.04-3.26,10.33-14.97,15.71-25.08,16.76-12.02.8-13.66-8.86-5.36-15.95,5.94-5.4,12.89-8.44,20.94-8.85Z"/>
          <path fill="#e8c305" d="m384.67,794.76c6.25-.36,13.54,2.29,11.02,9.23-4.79,10.38-21.4,16.91-32.08,13.01-7.34-3.06-2.95-11.52,1.88-14.78,5.91-4.72,12.7-7.15,19.19-7.46Z"/>
          <path fill="#e8c305" d="m488.15,782.01c-5.03.38-10.7-2.87-8.3-8.54,3.99-8.77,13.78-13.61,22.96-14.99,8.79-1.32,14.52,4.66,8.03,12.3-6.03,6.84-13.63,10.51-22.68,11.23Z"/>
          <path fill="#e8c305" d="m400.5,825.27c-4.78.26-11.2-2.75-8.57-8.64,5.23-9.52,16.43-13.96,27-13.66,6.59.32,9.42,5.31,5.5,10.78-5.69,7.42-14.56,11.07-23.92,11.51Z"/>
          <path fill="#e8c305" d="m429.43,831.85c-23.07-1.94,5-25.64,20.65-20.91,8.94,3.15,1.04,12.7-4.16,15.43-5.06,3.3-10.59,5.19-16.49,5.48Z"/>
          <path fill="#e8c305" d="m440.8,669.05c-.03-2.78,1.42-4.01,4.2-3.54,9.76,2.5,16.99,10.86,22.23,19.05,2.04,3.03,5.29,11.43-1.02,11.18-10.41-1.94-23.97-16.17-25.41-26.68Z"/>
          <path fill="#e8c305" d="m437.16,628.1c.06,6.34-7.12,2.63-10.02.72-6.97-4.67-12.8-10.4-16.62-17.99-4.61-10.29,5.61-7.08,10.48-3.13,6.74,5.06,14.21,12.01,16.16,20.4Z"/>
          <path fill="#e8c305" d="m524.2,716.1c.2,4.54-5.3,2.87-7.63,1.27-7.57-4.76-13.4-11.13-17.4-19.15-3.58-6.91.53-9.7,6.68-5.63,8.25,5.68,16.27,13.37,18.35,23.51Z"/>
          <path fill="#e8c305" d="m490.59,655.6c-.31,5.07-7.03,1.05-9.27-.63-6.16-4.5-11.32-9.89-14.8-16.76-3.73-7.71,2.62-7.34,7.37-3.75,7.19,5.24,14.84,12.1,16.7,21.13Z"/>
        </g>
      </g>
    </svg>
  )
}