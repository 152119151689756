export const BigFlame19 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="297.74 654.58 449.49 605.21 484.02 612.89 537.22 793 360.54 853.13 297.27 674.2 297.74 654.58"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m563.46,668.58c6.89-88.07-81.07-98.08-148.22-94.46,1.62,9.02,5.49,17.52,7.85,26.64-47.97,3.93-100.47,8.8-143.05-18.33-.93-.59-1.81-1.6-3.56-.65-.83,27.91,6.41,57.82-7.92,83.52-17.28,5.36-31.46-.25-44.57-12.73,3.46,25.34,20.27,44.58,24.55,68.99-2.6,11.94-2.71,24.11-3.55,36.21-6.64,59.84,57.35,89.76,109.04,85.66,1.61,4.62,3.11,8.94,4.48,12.87.42,1.22.88,1.31,1.99.91,14.6-5.18,133.71-47.09,178.67-62.84,1.62-.57,1.3-1.34.98-2.44-1.73-5.82-5.19-17.53-9.57-32.29,22.12-25.73,35.69-56.68,32.89-91.07Zm-262.87-.03c.2-4.23.22-8.48.14-12.72-.03-1.37.36-2.1,1.38-2.6l61.34-20.04c34.47-11.24,71.08-23.2,81.84-26.74,0,0,.66-.19,1.6-.42,1.38-.27,2.75-.28,4.22.04,3.72.81,7.44,1.6,11.16,2.38,4.88,1.57,9.76,3.14,14.66,4.7-28.83,9.48-161.6,53.08-174.21,57.22-2.23.73-2.25.54-2.14-1.84Zm233.34,121.39c.04.83-.71.78-1.2.95-7.26,2.57-119.71,42.05-168.66,59.28-1.31.46-1.76.22-2.22-1.08-15.88-45.85-55.21-159.2-59.05-170.01-.55-1.54-.2-2.02,1.29-2.5,43.94-14.41,161.64-53.14,176.57-58.05,2.06-.68,2.07-.68,2.71,1.47,13.96,46.96,47.43,159.43,50.2,168.71.12.4.23.8.36,1.24Z"/>
          <path fill="#e8c305" d="m233.76,282.48c-1.83-4.42-3.99-8.6-9.04-9.92-17.12-4.67-25.01-19.29-35.45-32.64-9.29,25.12-14.36,60.88,14.82,73.56,19.92,8.58,19.18,32.96,22.3,51.17,7.33-27.1,21-55.04,7.37-82.17Z"/>
          <path fill="#e8c305" d="m283.27,417.12c3.47,2.1,5.47,5.23,7.86,9.1-6.79-30.18-20.62-15.52-21.47-50.36-6.81,18.47-2.56,31.46,13.6,41.26Z"/>
          <path fill="#e8c305" d="m422.38,715.94c-11.44.08-20.35,9.15-20.32,20.69.03,10.16,8.81,18.62,19.25,18.55,11.3-.08,20.29-9.1,20.21-20.27-.07-10.58-8.61-19.03-19.15-18.96Z"/>
          <path fill="#e8c305" d="m412.94,629.85c3.43-.43,6.79-1.18,10.11-2.11,7.76-2.16,15.46-4.53,22.76-8.01.78-.37,1.65-.67,2.11-1.78-1.28-.01-2.35-.03-3.45.17-6.56,1.15-12.91,3.11-19.2,5.21-4.36,1.46-8.72,2.97-12.77,5.2-.44.24-1.14.52-.96,1.07.19.59.91.31,1.4.24Z"/>
          <path fill="#e8c305" d="m399.84,629.07c-6.89-.11-32.52,8.33-34.75,11.38,5.31.64,31.87-7.98,34.75-11.38Z"/>
          <path fill="#e8c305" d="m322.51,650.64c11.91-2.18,22.96-5.8,33.47-11.06-4.87-.84-30.71,7.71-33.47,11.06Z"/>
        </g>
      </g>
    </svg>
  )
}