export const ChatIcon = ({ size }) => {
    return (
      <svg
        width={size ? size : 18}
        height={size ? size / 1.125 : 16}
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16V1.6C0 1.16 0.17625 0.783333 0.52875 0.47C0.88125 0.156667 1.305 0 1.8 0H16.2C16.695 0 17.1188 0.156667 17.4713 0.47C17.8238 0.783333 18 1.16 18 1.6V11.2C18 11.64 17.8238 12.0167 17.4713 12.33C17.1188 12.6433 16.695 12.8 16.2 12.8H3.6L0 16ZM2.835 11.2H16.2V1.6H1.8V12.1L2.835 11.2Z"
          fill="#C5C5C5"
        />
      </svg>
    );
  };