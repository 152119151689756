export const ProfileIcon = ({ size }) => {
    return (
      <svg
        width={size ? size : 19}
        height={size ? size : 19}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 9.5C8.19375 9.5 7.07552 9.0349 6.14531 8.10469C5.2151 7.17448 4.75 6.05625 4.75 4.75C4.75 3.44375 5.2151 2.32552 6.14531 1.39531C7.07552 0.465104 8.19375 0 9.5 0C10.8062 0 11.9245 0.465104 12.8547 1.39531C13.7849 2.32552 14.25 3.44375 14.25 4.75C14.25 6.05625 13.7849 7.17448 12.8547 8.10469C11.9245 9.0349 10.8062 9.5 9.5 9.5ZM0 19V15.675C0 15.0021 0.173177 14.3836 0.519531 13.8195C0.865885 13.2555 1.32604 12.825 1.9 12.5281C3.12708 11.9146 4.37396 11.4544 5.64062 11.1477C6.90729 10.8409 8.19375 10.6875 9.5 10.6875C10.8062 10.6875 12.0927 10.8409 13.3594 11.1477C14.626 11.4544 15.8729 11.9146 17.1 12.5281C17.674 12.825 18.1341 13.2555 18.4805 13.8195C18.8268 14.3836 19 15.0021 19 15.675V19H0ZM2.375 16.625H16.625V15.675C16.625 15.4573 16.5706 15.2594 16.4617 15.0813C16.3529 14.9031 16.2094 14.7646 16.0312 14.6656C14.9625 14.1312 13.8839 13.7305 12.7953 13.4633C11.7068 13.1961 10.6083 13.0625 9.5 13.0625C8.39167 13.0625 7.29323 13.1961 6.20469 13.4633C5.11615 13.7305 4.0375 14.1312 2.96875 14.6656C2.79062 14.7646 2.64714 14.9031 2.53828 15.0813C2.42943 15.2594 2.375 15.4573 2.375 15.675V16.625ZM9.5 7.125C10.1531 7.125 10.7122 6.89245 11.1773 6.42734C11.6424 5.96224 11.875 5.40312 11.875 4.75C11.875 4.09687 11.6424 3.53776 11.1773 3.07266C10.7122 2.60755 10.1531 2.375 9.5 2.375C8.84688 2.375 8.28776 2.60755 7.82266 3.07266C7.35755 3.53776 7.125 4.09687 7.125 4.75C7.125 5.40312 7.35755 5.96224 7.82266 6.42734C8.28776 6.89245 8.84688 7.125 9.5 7.125Z"
          fill="#C5C5C5"
        />
      </svg>
    );
  };