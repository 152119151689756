export const NoFlame39 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="462.78 863.48 517.95 730.67 523.06 621 356.77 581.72 276.57 707.29 306.58 813.02 462.78 863.48"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m521.23,675.92c-1.03,18.24-2.08,36.49-3.09,54.73-17.13,44.56-36.3,88.55-54.09,132.92-.46,1.15-.91,1.31-2.06.94-28.84-9.5-57.7-18.96-86.56-28.42-22.59-7.41-45.17-14.81-67.78-22.17-1.29-.42-1.93-1.06-2.33-2.36-9.6-34.73-23.7-69.56-31.1-104.26,26.85-41.75,53.67-83.53,80.51-125.29,1.61-2.5,1.59-2.5,4.35-1.83,51.5,12.44,103.01,24.88,154.51,37.34,2.47.65,9.95,1.89,10.61,2.81-.47,18.31-2.21,37.12-2.96,55.59,0,0-.02,0-.02,0Zm-3.66-53.16c-51.85-13.56-104.79-25.39-156.97-38.48-1.37-.33-2.05,0-2.78,1.15-25.24,39.68-50.46,79.37-75.79,118.99-.97,1.53-.09,1.61,1.37,1.99,48.86,14.13,97.55,28.79,146.37,43.05,8.12,2.1,16.17,4.42,24.2,6.84,2.55.75,2.54.74,3.67-1.74,19.95-43.95,39.95-87.87,59.94-131.8Zm-58.95,234.2s-.03,0-.04,0c-1.34-27.24-2.43-54.49-3.55-81.74-.12-2.88-.31-5.75-.41-8.63-.03-.98-.4-1.42-1.39-1.71-57.74-16.82-115.63-34.66-173.28-51.03,8.93,32.15,19.52,64.52,28.97,96.75.36,1.19.96,1.78,2.14,2.16,48.75,15.85,97.45,31.82,146.17,47.77,2.37.97,1.07-2.34,1.39-3.57Zm63.79-228.14c-.11-.01-.21-.02-.32-.03-19.57,44.95-41.23,89.36-59.66,134.63,1.35,31.08,1.21,62.76,3.08,93.54,16.86-40.41,33.32-80.99,50-121.47,2.48-5.74,1.29-12.45,2.11-18.59,1.63-29.36,3.23-58.72,4.8-88.08Z"/>
          <path fill="#e8c305" d="m427.97,724.41c-4.62-.13-9.36-1.26-13.43-4.36-19.47-17.44,14.5-36.36,29.2-22.81,14.2,11.33-1.21,28.46-15.77,27.16Z"/>
          <path fill="#e8c305" d="m383.82,711.78c-5.1-.26-10.02-1.29-14.03-4.84-16.66-16.78,15.33-33.88,29.16-22.45,15.08,10.98-.68,28.1-15.12,27.28Z"/>
          <path fill="#e8c305" d="m362.03,682.49c-.81,14.35-18.98,20.34-30.65,15.04-9.85-4.11-11.43-14.35-3.67-21.83,9.13-9.46,32.92-8.97,34.32,6.79Z"/>
          <path fill="#e8c305" d="m462.03,656.14c-26.88-.69-23.25-29.1,1.99-27.83,24.66,1.79,21.35,27.63-1.99,27.83Z"/>
          <path fill="#e8c305" d="m421.89,618.01c27.44,1.81,19.2,29.44-4.95,27.01-24.31-3.2-16.68-27.7,4.95-27.01Z"/>
          <path fill="#e8c305" d="m382.92,608.06c26.73,3.19,14.64,29.24-7.56,26.25-11.49-.41-19.85-13.14-8.62-21.57,4.17-3.15,9.19-4.74,16.19-4.68Z"/>
          <path fill="#e8c305" d="m337.09,765.24c-9.96-.69-19.57-6-25.3-14.16-2.37-3.49-2.77-8.99,2.32-10.25,15.64-4.83,45.71,22.92,22.99,24.41Z"/>
          <path fill="#e8c305" d="m383.53,802.83c-9.79-.82-19.75-5.54-25.27-13.86-5.07-8.58,4.9-10.83,11.41-8.82,8.46,2.19,17.33,6.75,21.2,14.99,2.25,5.05-2.91,7.87-7.34,7.69Z"/>
          <path fill="#e8c305" d="m409.05,816.47c9.47,1.05,17.77,4.47,24.03,11.96,6.15,8.34-3.22,11.31-10.22,9.31-8.44-2.06-17.84-6.25-21.41-14.74-1.44-4.7,3.44-6.58,7.61-6.54Z"/>
          <path fill="#e8c305" d="m472.05,767.61c.66-10.56,3.65-21.05,8.67-30.36,3.97-6.76,5.09-.55,4.38,3.8-1.61,10.45-3.97,21.42-10.16,30.18-2.86,2.94-3.1-1.57-2.89-3.62Z"/>
          <path fill="#e8c305" d="m513.85,672.51c.79,2.8-7.77,37.91-11.63,30.32-.79-10.73,2.95-21.57,7.34-31.28,2.19-4.57,4.47-4.39,4.29.96Z"/>
          <path fill="#e8c305" d="m487.12,759.22c0-5.58,5.6-30.4,11.07-31.49,1.16.27.94,1.79.97,2.76-.41,10.29-3.49,20.54-8.18,29.69-2.11,3.88-3.96,3.58-3.86-.97Z"/>
          <path fill="#e8c305" d="m484.81,789.24c-.78,9.81-3.66,19.51-8.17,28.24-4.26,7.42-4.48-.64-3.58-4.51,1.78-9.08,4.04-18.5,9.27-26.26,2.1-2.68,2.77.68,2.49,2.54Z"/>
          <path fill="#e8c305" d="m511.24,725.48c-.95,9.77-3.39,19.66-8.35,28.19-4.1,4.86-2.06-7.49-1.59-9.11,1.32-6.42,3.13-12.69,5.93-18.64,1.4-3.33,4.42-6.63,4-.44Z"/>
        </g>
      </g>
    </svg>
  )
}