export const NoFlame03 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>  
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="286.89 624 393.77 610.92 534.61 635.52 516.77 797.85 427.24 846.65 280.95 797.83 286.89 624"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m285.56,623.48c4.29-.53,98.3-11.82,104.62-12.74,2.74-.4,5.31.07,7.93.52,39.01,6.77,137.35,23.91,137.99,24.07-.2,1.4-16.62,145.43-18.33,161.36-.24,2.24-1.16,3.34-3,4.36-10.58,5.85-67.88,38.32-86.02,48.57-.64.36-1.65.56-2.32.33-11.76-3.95-122.58-41.79-146.27-49.83-1.25-.42-1.52-1.01-1.47-2.24.65-15.59,6.82-173.83,6.87-174.39Zm3.82,6.13c-.06.66-6.37,157.97-6.65,164.09-.11,2.34-.13,2.34,2.17,3.12.79.27,137.41,46.31,138.15,46.52,5.27-61.21,10.51-122.2,15.77-183.36-49.85-10.13-99.54-20.23-149.43-30.37Zm141.76,213.62c.72-.38,55.54-30.86,82.44-45.74,1.67-.92,2.32-1.99,2.51-3.85.89-8.74,17.18-151.01,17.36-152.86-.51.09-55.22,12.53-82.31,18.72-3.63.83-3.72.85-4.06,4.51-.96,10.16-15.58,175.15-15.93,179.22Zm-136.98-219.49c0,.1,0,.2,0,.29.7.15,123.2,24.39,146.72,28.99,1.14.22,2.4.2,3.54-.04,25.09-5.29,81.15-17.26,84.09-17.9-.61-.41-1.21-.56-1.82-.66-5.88-1.03-106.65-18.6-125.73-21.88-2.67-.46-5.43-1.19-8.05-.92-9.89,1-95.38,11.7-98.74,12.12Z"/>
          <path fill="#e8c305" d="m378.86,696.02c1.38-29.83,35.77-19.3,33.18,6.01-3.55,26.39-33.13,16.29-33.18-6.01Z"/>
          <path fill="#e8c305" d="m302.36,761.63c.83-9.5,9.14-17.47,18.69-11.33,16.04,8.37,12.3,44.52-9.59,31.49-6.24-4.76-8.85-11.3-9.1-20.16Z"/>
          <path fill="#e8c305" d="m456.92,706.42c.13-7.79,1.87-15.12,6.53-21.54,8.25-10.58,14.5-.74,13.78,8.91-.19,6.69-1.66,13.1-5.25,18.8-2.6,4.11-8.45,10.3-12.6,4.32-2.16-3.06-2.59-6.86-2.47-10.5Z"/>
          <path fill="#e8c305" d="m468.59,781.2c-.32,7.79-2.32,15.08-7.04,21.39-7.11,8.88-13.06,4.75-13.11-5.51.13-9.58,3.08-19.69,10.71-25.94,6.48-4.89,10.04,4.76,9.43,10.06Z"/>
          <path fill="#e8c305" d="m519.31,679.69c-.82,8.43-2.46,18.1-9.49,23.57-2.25,1.42-4.66,1.02-5.94-1.31-4.76-8.86-1.1-28.82,8.44-33.36,6.14-1.37,7.55,5.4,6.99,11.1Z"/>
          <path fill="#e8c305" d="m510.13,759.31c-.39,8.47-1.99,15.29-6.1,21.28-6.33,8.68-11.33,4.11-11.09-5.01.1-6.81,1.73-13.27,5.34-19.09,6.03-9.03,10.57-6.94,11.86,2.81Z"/>
          <path fill="#e8c305" d="m400.26,626.78c.12-.02.24-.04.36-.05,7.17-.77,14.31-.27,21.4.79,2.89.43,5.72,1.17,8.58,1.79.4.09.83.22,1.16.45.13.09.25.18.37.28.65.53.56,1.59-.2,1.96-.09.04-.18.08-.28.1-1.47.33-2.98.6-4.49.65-8.87.32-17.66-.3-26.29-2.49-.31-.08-.61-.17-.9-.28-1.59-.58-1.39-2.93.28-3.2Z"/>
        </g>
      </g>
    </svg>
  )
}