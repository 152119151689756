export const Modal = ({modalOpen, setModalOpen, element}) => {
  function closeOnBackgroundClick(e) {
    if (e.target.id === "modal") setModalOpen(false);
  }

  return (
    <div 
      id="modal"
      className={!modalOpen ? "hide" : ""}
      onClick={closeOnBackgroundClick}
    >
      {element}
    </div>
  )
}