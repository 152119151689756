export const BigFlame10 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="314.67 621.09 452.72 577.45 561.03 704.8 487.59 797.17 365.65 864.95 271.8 730.81 314.67 621.09"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m289.6,390.59s.06.06.09.09c0-.05-.01-.1-.02-.16l-.07.07Z"/>
          <path fill="#e8c305" d="m70.14,340.61c-5.93,15.88,7.04,30.66,17.61,40.98,7.4,6.96,12.75,15.25,16.66,24.55.46,1.1.49,2.58,2.43,3.32-2.27-25.92-10.98-57.99-36.7-68.85Z"/>
          <path fill="#e8c305" d="m442.8,821.52c.64-.37,1.27-.74,1.91-1.11-.67.31-1.34.61-2.01.92.03.06.07.13.1.19Z"/>
          <path fill="#e8c305" d="m289.6,390.59s.07-.07.07-.07c-.13-.04-.27-.08-.4-.11.11.06.22.12.34.18Z"/>
          <path fill="#e8c305" d="m533.36,667.65c4.88-33.35-1.41-74.64-31.9-93.86-29.06-18.26-43.73-47.88-55.65-79.3-7.27,21.65-10.56,42.21-.99,63.55-24.4-9.03-47.21-40.59-63.39-60.91-8.25-11.78-15.91-24.03-17.81-38.58-.94-7.2-3.83-12.93-8.25-18.23-19.01-19.99-46.22-29.81-65.68-49.65,6.7,59.62,54.02,51.74,49.8,103.79-23.82-10.95-44.8-27.5-70.86-34.15-42.12-12.16-90.74-19.9-109.91-65.15-8.47,41.86,28.17,77.14,57.17,101.89,45.33,37.31,42.67,104.41,33.18,157.12-3.12,12.91-3.6,24.74-4.29,38.26-5.14-3.97-8.28-9.11-14.07-10.98,8.88,18.65,15.86,38.19,20.22,58.62,2.93,11.78,6.16,23.51,13.36,33.65,16.57,23.23,41.79,37.62,68.39,46.34,10.75,14.9,21.4,29.82,31.44,44.88.72,1.03,1.34,1.11,2.43.5,6.61-3.74,13.25-7.41,19.89-11.1,33.93-19.34,68.81-37.43,102.27-57.4,23.7-31.75,52.79-62.54,74.79-94.32-10.06-11.56-20.1-23.25-30.14-34.97Zm22.1,34.06c-41.41,20.22-82.76,40.54-124.2,60.71-36.87-46.4-72.66-94.27-109.18-141.15-1.08-1.4-1.08-1.41.67-1.95,43.52-12.16,87.82-29.74,130.98-39.24,34.05,39.8,68.11,79.59,102.22,119.34q1.24,1.44-.49,2.3Zm-191.88,157.71c-29.92-42.31-59.71-84.74-89.68-127.01-.75-.95-.74-1.8-.31-2.88,13.58-34.22,27.08-68.47,40.62-102.71.29-.47.24-1.29.84-1.37,8.96,10.71,17.3,22.63,26.06,33.73,27.49,35.86,54.89,71.8,82.42,107.63.72.9.66,1.53.05,2.46-20.14,29.92-39.59,60.53-59.99,90.15Zm123.85-63.12c-7.75,4.53-15.56,9-23.39,13.44-.55.02-.97.14-1.07.61-.94.53-1.88,1.07-2.83,1.61.17-.2.33-.39.49-.61-2.41,1.3-4.81,2.6-7.22,3.91.44-.06.87-.14,1.28-.24-.93.52-1.86,1.04-2.78,1.56-.05-.1-.1-.19-.15-.29-2.35,1.37-4.7,2.74-7.05,4.11.68-.31,1.37-.63,2.05-.94-24.95,13.91-50.12,27.55-75.03,41.35-.06.09-.12.13-.17.14.05-.01.11-.06.17-.14-.04-.04-.08-.09-.12-.13h0c19.58-30.02,40.32-59.38,60.31-89.15,40.95-21.61,83.42-42.31,125.05-62.43-22.42,29.08-46.59,58.11-69.55,87.2Z"/>
          <path fill="#e8c305" d="m429.62,648.69c-4.64,2.91-6.13,8.21-3.54,13.64,4.44,9.32,12.3,13.67,20.79,14.61,4.74.03,8.02-.86,9.86-2.25,14.68-13.45-13.52-34.59-27.11-26Z"/>
          <path fill="#e8c305" d="m367.6,748.88c0,6.75,3.01,14.95,7.17,19.94,2.34,2.8,4.89,5.32,8.4,6.64,3.89,1.46,6.81.44,8.98-3.06,4.52-9.48-.47-20.7-6.75-28.18-6.87-8.57-17.95-7.82-17.8,4.67Z"/>
          <path fill="#e8c305" d="m302.4,722.51c-5.73-2.93-8.79,3.67-8.42,8.51.31,7.31,2.92,13.76,7.49,19.42,2.76,4.27,10.76,8.06,13.1,1.46,2.83-10.2-2.91-24.28-12.17-29.39Z"/>
          <path fill="#e8c305" d="m462.51,765.84c-10.36,2.62-18.77,10.08-26.02,17.64-4.8,4.95-5.49,10.43,2.98,6.47,3.86-.44,33.4-22.58,23.04-24.1Z"/>
          <path fill="#e8c305" d="m523.97,734.4c-9.63,2.7-17.5,10.13-24.27,17.24-6.19,7.13-2.72,8.85,4.48,4.66,3.13-.91,28.44-20.58,19.8-21.9Z"/>
          <path fill="#e8c305" d="m426.11,813.63c-3.54.16-32.25,20.9-23.07,22.27,9.88-2.74,18.09-9.86,25.13-17.09,3.41-3.49,4.4-8-2.06-5.18Z"/>
          <path fill="#e8c305" d="m485.03,781.82c-3.24.52-29.16,19.48-21.16,21.02,9.67-3.33,17.79-10.24,24.36-17.99,3.19-4.05.55-4.96-3.2-3.03Z"/>
        </g>
      </g>
    </svg>
  )
}