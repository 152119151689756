export const SmallFlame58 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="408.56 829.11 544.66 781.87 504.89 624.76 405.9 581.87 270.65 654.66 298.1 824.11 408.56 829.11"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m536.81,747.22c31.65-53.34,22.57-125.22-34.38-154.96-3.47-1.94-7.15-3.45-10.11-6.3-18.1-17.28-36.7-34.59-54.35-52.22-49.5,53.1-76.16,7.21-134.08,89.07-6.24,8.96-14.66,15.38-21.21,23.64-.32.17-.65.35-.97.52-24.63-6.72-46.77-16.37-61.41-38.47,1.94,47.28,19.31,92.03,20.35,139.43,5.03,128.65,178.31,122.98,250.69,52.95,17.64-6.08,35.27-12.16,52.9-18.24.9-.31,1.69-.42,1.32-1.86-2.89-11.02-5.81-22.24-8.75-33.58Zm-236.72,68.47c-7.15-43.51-14.33-87.01-21.5-130.51-1.51-9.19-2.99-18.38-4.55-27.56-.18-1.07,0-1.59.97-2.1,13.13-6.89,26.24-13.81,39.34-20.74,5.46-2.92,10.94-5.81,16.42-8.71,24.71-12.66,49.35-25.29,73.96-37.9.1.29.19.69.31,1.23,12.83,57.71,25.8,115.36,38.57,173.09-46.67,18.35-94.23,36.26-141.18,54.47-1.96.75-1.99.76-2.33-1.27Zm137.24,2.67c-8.01,2.66-16.08,5.37-24.36,8.27-7.95.53-15.93.68-23.92.6-14.24-.72-28.49-1.33-42.73-2-13.43-.92-26.89-1.89-40.34-2.36,48.05-17.61,95.55-37.42,143.82-53.77,29.55,4.86,59.17,9.08,88.67,14.28-33.66,11.79-67.43,23.31-101.14,34.98Zm103.7-41.75c-29.28-5.05-58.56-10.17-87.85-15.16-15.45-56.12-26.47-115.77-40.12-173.15,21.61,9.21,43.14,18.39,64.57,27.52,8.07,3.65,16.14,7.3,24.24,10.95,1.31.59,1.85,1.42,2.19,2.73,12.62,48.71,25.18,97.45,37.88,146.14.29,1.03.06,1.14-.91.97Z"/>
          <path fill="#e8c305" d="m371.95,629.73c-15.64,9.53-10.1,44.44,11.34,33.11,7-4.68,10.03-11.51,10.46-19.68.76-12.07-10.96-21.95-21.81-13.43Z"/>
          <path fill="#e8c305" d="m336.5,748.97c-15.39-18.1-32.83,12.85-22.52,26.61,10.15,15.55,27.26-.93,26.01-14.74.03-4.31-1.04-8.3-3.49-11.86Z"/>
          <path fill="#e8c305" d="m470.44,709.68c-1.78-2.36-3.87-4.39-6.61-5.65-2.83-1.31-5.61-.4-7.07,2.35-3.54,10.03,1.19,20.83,7.17,28.92,6.1,8.2,14.9,7.68,14.49-3.91-.41-8.06-3.11-15.29-7.98-21.72Z"/>
          <path fill="#e8c305" d="m458.28,647.06c-.29-7.27-2.91-13.74-7.01-19.65-3.82-6.66-15.16-13.55-15.73-1.04.51,10.9,5.22,21.56,14.44,27.72,5.24,3.23,8.75-2.11,8.29-7.03Z"/>
          <path fill="#e8c305" d="m509.85,717.07c-4.83-2.86-7.45,3.11-7.06,7.16.62,9.6,4.34,20.18,12.55,25.78,2.96,1.7,5.47.74,6.61-2.47,2.38-10.09-3.35-24.83-12.1-30.47Z"/>
          <path fill="#e8c305" d="m493.07,642.09c-2.09-2.37-6.9-5.43-9.02-1.55-6.49,11.66,17.25,46.28,18.73,23.64-.74-7.96-3.78-15.64-9.71-22.09Z"/>
          <path fill="#e8c305" d="m422.78,786.26c-7.83,3.23-7.04,6.67,1.39,6.33,6.21-.07,30.18-3.8,31.45-10.3-5.35-4.93-26.35,1.22-32.84,3.97Z"/>
          <path fill="#e8c305" d="m449.48,795.36c10.04-.73,21.14-2.52,29.63-8.29,4.56-6.81-22.99-.75-25.49.54-5.65,1.32-18.05,7.84-4.14,7.76Z"/>
          <path fill="#e8c305" d="m383.91,809.09c-1.35-2.56-7.81-1.55-10.4-1.47-8.76,1.11-18.24,2.34-25.64,7.44-2.5,3.23,4.23,2.9,6.63,2.98,5.74-.13,27.08-3.06,29.41-8.96Z"/>
          <path fill="#e8c305" d="m472.28,797.93c9.91-.85,20.4-2.5,28.85-8.02,4.49-6.84-24.66.22-27.03,1.75-5.32,1.62-12.45,6.42-1.82,6.28Z"/>
          <path fill="#e8c305" d="m408.6,810.01c-8.1-1.49-16.41.77-24.22,2.84-5.39,1.2-16.6,6.79-3.7,6.77,2.96.77,34.55-5.09,27.91-9.61Z"/>
          <path fill="#e8c305" d="m405.95,815.89c-5.85,1.95-9.52,5.46-.26,5.28,5.41-.27,25.4-3.13,27.71-8.4-4.56-3.83-21.82,1.22-27.45,3.12Z"/>
          <path fill="#e8c305" d="m262,392.18c-4.52-11.18-10.49-21.04-20.39-29.03-4.65,10.27,9.36,29.75,20.39,29.03Z"/>
        </g>
      </g>
    </svg>
  )
}