export const NoFlame50 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="278.26 743.6 314.34 804.24 478.25 855.5 553.02 694.95 479.99 649.56 343.06 591.98 278.26 743.6"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m479.99,649.56c25.06,13.6,49.47,28.89,74.32,43.06,2.56,1.5,2.41,1.43,1.17,4.05-2.44,5.12-4.88,10.25-7.29,15.38-22.9,48.25-45.69,96.56-68.65,144.78-.55,1.16-1.12,1.19-2.18.88-53.49-15.4-106.99-30.77-160.51-46.07-1.55-.44-2.58-1.13-3.4-2.61-11.76-21.25-23.62-42.43-35.47-63.63-.57-1.02-.61-1.83-.15-2.9,21.3-49.91,42.55-99.83,63.8-149.76.41-.96.77-1.26,1.82-.78,2.57,1.17,5.21,2.2,7.81,3.3.39.17.99.16.98.83-1.27.07.1,1.07.64,1.35,15.58,10.28,30.77,21.27,46.56,31.17,48.66,19.94,97.11,40.47,145.91,60.02.41.65,1.03-.16.17-.2-9.46-5.98-19.44-11.23-29.06-17-11.91-7.46-25.3-13.79-36.45-21.88Zm-3.16,203c24.96-50.38,48.15-102.65,72.72-153.52.69-1.46.51-1.93-.98-2.54-49.76-20.27-99.52-40.54-149.23-60.93-1.14-.47-1.54-.23-1.94.81-14.69,33.24-29.08,66.62-44.05,99.74-9.98,23.35-20.28,46.56-30.56,69.78-.54,1.23-.38,1.69.93,2.05,50.95,14.64,102.18,29.95,153.11,44.61Zm-161.49-49.03c25.76-56.19,49.84-113.68,75.07-170.3.5-1.13.37-1.71-.66-2.43-15.51-10.89-31-21.83-46.49-32.74-1.75-1.23-1.79-1.22-2.61.7-20.13,47.13-40.18,94.29-60.3,141.43-.36.85-.36,1.49.11,2.32,11.65,20.33,23.29,40.66,34.88,61.02Zm35.39-207.85c-.07-.1-.12-.1-.2-.01.07.11.12.11.2.01Zm.47.24c-.09.08-.03.12.04.18l.06-.18s-.07,0-.1,0Z"/>
          <path fill="#e8c305" d="m545.5,688.43c.83.03.27.87-.17.2.06-.06.12-.13.17-.2Z"/>
          <path fill="#e8c305" d="m350.73,595.68c-.08.09-.13.09-.2-.01.08-.09.13-.09.2.01Z"/>
          <path fill="#e8c305" d="m351.21,595.92s.07,0,.1,0l-.06.18c-.07-.06-.13-.1-.04-.18Z"/>
          <path fill="#e8c305" d="m405.13,626.8c4.55.09,31.68,12.32,34.32,15.41-.76.25-1.48.19-2.13-.06-10.9-4.65-22.53-8.41-32.19-15.35Z"/>
          <path fill="#e8c305" d="m480.02,657.57c11.8,3.91,23.06,9.01,33.76,15.35-3.86.19-30.37-11.79-33.76-15.35Z"/>
          <path fill="#e8c305" d="m378.7,609.21c11.19,3.76,21.93,8.55,32.54,14.45-4.28.27-30.49-11.33-32.54-14.45Z"/>
          <path fill="#e8c305" d="m478.39,652.21c-10.65-3.21-20.64-7.94-30.36-13.24.11-.25.22-.49.34-.74,10.46,3.64,20.5,8.22,30.03,13.98Z"/>
          <path fill="#e8c305" d="m438.22,691.86c.04,25.03-39.1,30.07-37.99,2.38.95-22.6,37.13-28.37,37.99-2.38Z"/>
          <path fill="#e8c305" d="m361.92,780.67c1.04-24.64,41.14-28.87,37.76-.59-3.38,20.48-37.31,24.51-37.76.59Z"/>
          <path fill="#e8c305" d="m456.75,727.55c-24.21-.62-18.71-35.95,2.66-37.5,26.45-1.37,20.45,37.82-2.66,37.5Z"/>
          <path fill="#e8c305" d="m439.18,788.92c.06,24.23-39.99,29.13-36.15,1.87,2.72-19.39,35.39-24.98,36.15-1.87Z"/>
          <path fill="#e8c305" d="m499.49,704.82c21.49,1.01,17.22,31.57-.62,35.76-28.58,5.97-23.4-36.68.62-35.76Z"/>
          <path fill="#e8c305" d="m476.55,800.73c.38,23.25-38.8,29.3-34.68,2.22,2.73-18.3,33.87-24.72,34.68-2.22Z"/>
          <path fill="#e8c305" d="m331.57,735.79c.82,5.37-8.56,34.73-16.01,27.99-4.39-8.62,2.72-27.03,9.25-33.57,4.98-4.8,6.99.96,6.76,5.58Z"/>
          <path fill="#e8c305" d="m344.09,631.08c-.75,9.42-4.15,19.1-10.65,26.09-6.04,5.79-6.39-4.53-5.12-8.41,1.47-7.15,4.12-13.82,8.56-19.69,3.44-4.64,7.5-4.53,7.21,2.02Z"/>
        </g>
      </g>
    </svg>
  )
}