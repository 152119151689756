export const NoFlame20 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="300.48 648.41 454.04 596.81 480.96 623.32 533.14 800.38 357.79 860.25 291.58 683.55 300.48 648.41"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m289.21,686.24c.11-.49.19-.9.3-1.31,3.06-11.62,6.14-23.23,9.14-34.86.32-1.24.86-1.8,2.03-2.19,36.92-12.4,139.32-46.87,153.59-51.73,1.26-.43,2.08-.26,3.03.67,8.11,7.89,16.26,15.74,24.41,23.59.48.46.75.98.93,1.59,7.66,25.09,50.12,164.02,54.02,176.79.6,1.97.59,1.98-1.43,2.66-46.33,15.78-164.25,55.92-176.86,60.3-1.57.54-2.07.18-2.62-1.27-13.61-35.71-57.62-150.72-65.97-172.53-.22-.58-.4-1.19-.57-1.72Zm241.26,110.96c-.12-.41-.22-.76-.33-1.11-1.72-5.6-35.66-115.94-50.91-165.51q-.63-2.04-2.63-1.36c-20.74,7-139.42,47.04-178.04,60.02-1.39.47-1.88.82-1.26,2.45,14.14,37.01,55.37,145.43,61.86,162.61.42,1.1.88,1.59,2.02.99.27-.14.59-.2.89-.31,51-17.37,162.5-55.35,167.26-56.98.45-.15,1.09-.12,1.13-.79Zm-234.58-114.22c.4-.13.8-.25,1.2-.38,6.1-2.06,157.63-53.19,177.37-59.86,1.67-.56,1.67-.57.46-1.81-3.95-4.04-18.28-18.46-21.32-21.77-1.09-1.19-1.94-1.31-3.36-.82-29.16,9.86-125.93,42.41-145.14,48.84-1.18.4-1.79.99-2.07,2.21-1.92,8.3-7.05,29.98-7.63,32.53-.11.47-.44,1.08.51,1.07Z"/>
          <path fill="#e8c305" d="m416.63,764.41c-6.97-.33-12.63-3.02-16.39-9.12-3.95-6.4-3.53-14.51,1.05-20.63,7.15-9.56,20.58-11.14,29.75-3.49,7.13,5.95,8.25,17.5,2.42,24.97-4.16,5.32-10.67,8.52-16.83,8.28Z"/>
          <path fill="#e8c305" d="m442.06,622.7c1.19.07,2.88-.52,3.25,1.05.28,1.19-1.16,1.84-2.09,2.45-5.19,3.46-11.04,5.45-16.92,7.34-4.39,1.41-8.86,2.45-13.46,2.93-1.12.12-2.2-.05-3.27-.29-.89-.2-1.21-.76-.53-1.61.97-1.21,2.22-2.07,3.56-2.78,8.71-4.64,17.95-7.67,27.72-9.08.56-.08,1.15-.01,1.72-.01Z"/>
          <path fill="#e8c305" d="m367.01,642.9c-.97-.16-2.44.43-2.79-.74-.34-1.14,1-1.81,1.9-2.4,5.49-3.57,11.61-5.72,17.82-7.64,3.65-1.13,7.35-2.03,11.17-2.4,1.31-.13,3.12-.41,3.57.73.48,1.21-1.23,1.98-2.26,2.63-6.28,3.92-13.21,6.26-20.29,8.19-2.97.81-5.98,1.52-9.11,1.62Z"/>
          <path fill="#e8c305" d="m326.44,648.77c-.74,0-1.05.02-1.36,0-.51-.05-1.11-.04-1.3-.65-.16-.52.25-.86.59-1.2,1.28-1.28,2.86-2.12,4.45-2.9,7.31-3.59,14.94-6.27,22.98-7.69,1.17-.21,2.4-.14,3.6-.16.45,0,1.01-.05,1.24.47.25.56-.27.85-.58,1.17-1.17,1.24-2.66,2.04-4.15,2.78-7.72,3.85-15.79,6.68-24.33,8.06-.51.08-1.04.11-1.14.12Z"/>
        </g>
      </g>
    </svg>
  )
}