export const i18nEn = {
    player: "Player",
    guess: "Guess",
    instructions: "If the dice rolls over your guess, You win!",
    warning: "If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER.",
    scanToPlay: "SCAN TO PLAY!",
    nextRoll: "Next roll",
    in: "in ",
    rolled: "Rolled",
    currency: "$",
    jackpotSubtext: "Hits after 3 back-to-back rolls of 100"
};


export const i18nPt = {
    player: "Jogador",
    guess: "Palpite",
    instructions: "Se o dado rodar mais do que seu palpite, você ganha!",
    warning: "Se você ou alguém que você conhece tem um problema com jogos de azar, há ajuda disponível. Ligue para 0808 8020 133.",
    scanToPlay: "SCAN TO PLAY!", // TODO
    nextRoll: "Próxima rodada",
    in: "",
    rolled: "Rolou",
    currency: "R$",
    jackpotSubtext: "Ganhe se houver 3 lançamentos seguidos de 100"
}