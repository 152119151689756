export const SmallFlame31 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="283.51 629.51 439.4 621.17 526.65 636.57 525.72 801.42 435.95 823.23 283.08 808.26 283.51 629.51"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m404.97,784.69c3.84-4.13,5.51-9.02,5.45-15.29.22-3.44-.84-7.29-2.81-10.88-2.4-4.39-5.86-7.51-10.74-9.02-6.56-2.03-13.46,1.69-16.73,7.43-4.88,8.56-3.58,19.9,3.09,27.13,5.94,6.44,15.84,6.98,21.74.63Z"/>
          <path fill="#e8c305" d="m364.4,736.87c5.02-4.13,6.74-9.75,6.99-15.06-.02-5.94-1.39-10.51-4.33-14.6-5.88-8.17-17.07-8.29-22.94-.24-6.11,8.39-5.49,20.55,1.45,28.3,5.1,5.7,13,6.4,18.83,1.6Z"/>
          <path fill="#e8c305" d="m329.13,688.99c6.51-7.35,7.05-19.71,1.21-27.7-5.11-6.99-13.9-7.69-19.95-1.51-4.06,4.15-5.6,9.32-5.66,15.01-.08,4.77,1.24,9.09,4,12.97,5.1,7.2,14.6,7.77,20.39,1.23Z"/>
          <path fill="#e8c305" d="m469.56,656.72c-2.95-3.21-6.8-3.05-9.58.32-1.24,1.51-2.15,3.22-2.8,5.05-2.35,6.65-2.44,13.42-1,20.25.68,3.2,1.85,6.21,3.91,8.81,3,3.78,7.21,3.78,10.2,0,.68-.87,1.28-1.78,1.76-2.78,2.1-4.38,2.82-9.05,2.84-13.86,0-1.89-.08-3.77-.39-5.63-.73-4.42-1.79-8.74-4.94-12.17Z"/>
          <path fill="#e8c305" d="m469.06,749.58c-2.27-1.94-4.75-2.01-7.16-.26-1.25.91-2.22,2.07-3,3.37-3.08,5.14-4.02,10.82-4.14,16.69-.09,2.27.19,4.5.61,6.71.68,3.58,1.75,7.02,4.39,9.72,1.51,1.54,3.29,2.13,5.44,1.56,2.73-.72,4.32-2.74,5.48-5.04,4.47-8.85,4.93-18.03,1.82-27.39-.68-2.04-1.75-3.92-3.43-5.37Z"/>
          <path fill="#e8c305" d="m490.8,702.8c-2.15-2.07-4.8-2.11-7.05-.16-1.29,1.12-2.21,2.51-2.95,4.03-2.15,4.43-2.8,9.17-2.85,14.03-.06,2.42.2,4.82.68,7.18.6,2.99,1.53,5.87,3.39,8.37,2.51,3.36,6.1,3.57,8.9.45,1.06-1.18,1.89-2.52,2.46-3.99,3.14-8.06,3.37-16.21.68-24.42-.67-2.06-1.68-3.96-3.26-5.48Z"/>
          <path fill="#e8c305" d="m515.07,670.54c-.63-3.69-1.55-7.29-4.04-10.24-2.16-2.55-5-2.62-7.32-.22-1.49,1.55-2.39,3.44-3.01,5.45-2.1,6.83-2.15,13.7-.23,20.58.7,2.49,1.68,4.85,3.57,6.72,2.32,2.3,4.92,2.2,7.08-.3,1.04-1.2,1.79-2.58,2.36-4.05,1.45-3.76,2.09-7.66,1.96-11.78.09-2.01-.02-4.1-.37-6.17Z"/>
          <path fill="#e8c305" d="m511.06,745.82c-1.94-2.35-4.42-2.58-6.71-.63-1.38,1.18-2.32,2.68-3.06,4.32-1.94,4.33-2.62,8.91-2.65,12.64,0,3.01.08,5.05.44,7.07.59,3.27,1.37,6.48,3.71,9.02,2.08,2.25,4.5,2.3,6.75.21,1.37-1.27,2.31-2.85,3-4.56,2.49-6.17,3.03-12.57,2.03-19.11-.5-3.23-1.37-6.35-3.51-8.96Z"/>
          <path fill="#e8c305" d="m271.61,406.46c-36.4,36.22-8.17,44.11,9.78,78.96,29.8-38.89,12.03-44.18-9.78-78.96Z"/>
          <path fill="#e8c305" d="m335.31,436.16c11.42-12.92,14.74-29.38,7.09-38.41-6.28,11.26-20.91,28.17-7.09,38.41Z"/>
          <path fill="#e8c305" d="m527.69,667.1c.07-10.87.14-21.75.21-32.62,0-.87.22-1.69-1.17-1.9-4.75-.72-9.49-1.46-14.24-2.2-27.07-46.87-82.64-69.55-135.38-67.71-24.08.55-48.36,4.15-69.86-9.7-13.99-7.19-26.23-18.21-41.37-22.8-39.16,42.3-91.58,120.73-32.92,168.22,6.96,5.42,13.33,11.51,19.21,18.11,1.62,1.82,2.56,3.64,2.86,6.11,3.49,23.26,11.44,46.02,26.89,63.93-.02,7.29-.04,14.58-.06,21.87,0,.8-.25,1.64,1.1,1.77,6.1.57,12.18,1.27,18.27,1.87,1.9.19,3.81.36,5.71.54,37.61,34.27,123.97,35.64,160.31,5.67,19.25-4.72,38.51-9.45,57.77-14.14,1.26-.31,1.83-.74,1.76-2.15-.11-2.35.04-4.71.06-7.07.12-17.18.23-34.36.34-51.53.02-3.03.04-6.06.06-9.1,6.28-24.34,5.93-54.93.43-67.17Zm-231.21,139.14c-2.92-.28-5.84-.57-8.76-.75-1.33-.08-1.88-.44-1.87-1.94.12-56.56.2-113.12.25-169.69,0-1.28.33-1.66,1.64-1.72,15.38-.69,30.76-1.45,46.14-2.2,10.67-.52,21.34-1.04,32.01-1.56,15.12-.74,30.23-1.46,45.35-2.21,7.06-.35,14.12-.7,21.18-1.13,1.61-.1,1.34.85,1.34,1.77-.09,19.85-.2,39.7-.26,59.54-.04,11.94,0,23.88,0,35.82h-.19c0,9.37.03,18.75,0,28.12-.08,22.36-.21,44.72-.26,67.09,0,1.44-.41,1.77-1.78,1.63-9.48-.95-18.96-1.84-28.45-2.74-8.18-.77-16.37-1.52-24.55-2.3-9.54-.9-19.07-1.82-28.61-2.72-8.23-.78-16.47-1.53-24.7-2.31-9.48-.9-18.97-1.81-28.45-2.71Zm228.9-120.75c-.08,15.08-.19,30.16-.29,45.24-.11,17.8-.22,35.61-.34,53.41-.03,4.55-.15,9.11-.14,13.66,0,1.14-.43,1.5-1.46,1.74-26.86,6.25-53.71,12.52-80.55,18.84-1.54.36-1.46-.31-1.46-1.36.01-31.74.01-63.47.01-95.21.71-28.8.61-57.61.75-86.41.01-2.83.17-5.66.1-8.48-.04-1.44.4-1.81,1.79-1.59,5.27.82,10.56,1.56,15.84,2.33,11.96,1.74,23.92,3.48,35.88,5.21,9.53,1.38,19.06,2.77,28.59,4.12,1.16.16,1.51.64,1.5,1.82-.11,15.55-.14,31.11-.22,46.66Z"/>
        </g>
      </g>
    </svg>
  )
}