export const SmallFlame45 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="297.48 784.99 451.03 831.32 486.02 832.63 535.16 651.13 358.29 593.01 297.48 784.99"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m327.17,787.59c12.08,4.7,24.39,8.49,37.37,11.16-5.08-3.01-32.94-11.41-37.37-11.16Z"/>
          <path fill="#e8c305" d="m363.56,720.99c-10.87.2-19.6,8.26-19.86,19.57-.26,11.25,8.34,20.2,20.17,20.43,11.07.22,19.88-8.8,19.99-20.07.11-10.75-9.17-19.86-20.3-19.93Z"/>
          <path fill="#e8c305" d="m408.5,735.07c-10.29-.01-19.29,8.86-19.37,19.18-.09,11.86,8.8,20.25,19.82,20.68,11.08-.02,19.91-8.84,19.83-19.83-.08-11.13-9.07-20.02-20.28-20.04Z"/>
          <path fill="#e8c305" d="m453.28,748.95c-10.42.09-19.01,8.22-19.2,19.06-.2,11.37,8.05,20.31,19.5,20.58,10.74.26,19.12-7.74,19.52-19.34.38-10.98-8.72-20.1-19.81-20.3Z"/>
          <path fill="#e8c305" d="m391.57,671.7c10.78.03,18.74-8.17,19.03-18.22.31-11.08-8.38-20.32-20.15-20.43-10.35-.1-18.57,7.57-19.07,18.52-.11,11.01,8.81,20.1,20.19,20.13Z"/>
          <path fill="#e8c305" d="m435.93,685.92c10.48-.12,18.46-8.04,18.56-18.43.11-11.47-8.8-19.63-19.52-20.1-10.84-.16-18.88,8-19.11,18.19-.26,11.25,8.89,20.47,20.07,20.34Z"/>
          <path fill="#e8c305" d="m478.52,661.59c-10.3-.36-18.65,7.85-18.76,18.06-.13,11.13,8.56,20.09,19.62,20.24,10.23.14,18.31-7.91,18.45-18.37.02-10.66-8.11-19.54-19.3-19.93Z"/>
          <path fill="#e8c305" d="m550.26,665.47c-10.93-48.1-34.7-95.85-84.22-113.25-20.15-7.47-37.48-19.24-50.99-36.29-14.2-17.91-33.51-27.92-54.72-34.88-.88-.29-1.84-.94-2.81.34,14.96,31.91,7.85,56.18-30.69,56.87-32.93.79-65.2,3.35-97.34,11.53,20.32,16.11,46.36,35.32,44.35,64.2-10.43,4.32-21.2,4.45-31.92,4.38,12.86,31.38,11.26,36.7,4.27,69.89-33,130.65,88.43,195.84,197.63,141.3-48.72-14.84-96.93-29.53-145.12-44.21,0-.11,0-.22,0-.33.95-2.48,2.67-4.52,3.94-6.82.35-.63.99-.43,1.55-.26,5.27,1.55,160.69,48.36,174.06,52.41.64.19,1.27.4,1.92.92-.76.56-1.47.05-2.12.05-6.54-.07-13.07-.19-19.61-.32-2.3-.05-4.58-.42-6.89-.27-.51.03-1.05-.17-1.52.18,1.48.87,3.14.68,4.75.75,10.52.49,21.04.94,31.56,1.42,1.17.05,1.88-.17,2.24-1.51,1.23-4.54,5.47-19.99,11.08-40.39,35.46-29.44,56.79-79.26,50.58-125.69Zm-63.6,152.05c-.63,2.27-1.3,4.53-1.86,6.82-.28,1.17-.71,1.5-1.95,1.12-16.18-4.93-128.25-38.8-168.09-50.84-2.66-.8-5.29-1.71-7.97-2.39-1.47-.37-1.57-.97-1.15-2.28,4.11-12.75,40.52-127.27,54.67-171.76.73-2.29.47-2.02,2.66-1.3,53.1,17.52,165.17,54.5,168.1,55.47.46.15,1.09.18,1.01.89-.16.57-.31,1.07-.45,1.57-14.99,54.23-29.98,108.47-44.97,162.7Z"/>
          <path fill="#e8c305" d="m177.29,486.53c-28.14,8.66-.38,41.44,6.95,56.73,1.5-1.67.6-3.48.45-5.26-.1-24.44,10.18-26.95-7.4-51.47Z"/>
        </g>
      </g>
    </svg>
  )
}