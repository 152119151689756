export const SmallFlame04 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="290.49 624.07 398.27 601.41 539.68 641.13 512.22 796.75 419.12 856.21 277.37 789.54 290.49 624.07"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m394.25,688.86c-20.69.54-17.83,31.22-2.4,38,27.25,10.42,26.48-35.84,2.4-38Z"/>
          <path fill="#e8c305" d="m317.87,749.19c-11.57-6.74-19.78,7.24-16.25,17.49,3.12,16.93,26.39,25.31,27.66,2.74-.55-8.4-3.74-15.55-11.4-20.24Z"/>
          <path fill="#e8c305" d="m464.86,698.48c-5.18,6.79-7.7,14.53-8.15,24.11.91,9.62,5.99,12.24,12.95,4.39,6.32-8.13,11.77-21.96,6.55-31.69-3.28-4.41-9.04-.1-11.35,3.18Z"/>
          <path fill="#e8c305" d="m454.47,782.09c-6.73,6.93-9.77,15.43-10.63,25.52.48,3.52,1.76,9.76,6.97,7.33,8.69-4.91,12.38-14.99,13.98-24.33,1.53-7.81-2.48-16.12-10.32-8.52Z"/>
          <path fill="#e8c305" d="m515,676.32c-1.36.67-2.7,1.67-3.66,2.84-5.4,6.54-7.77,14.23-8.43,23.21.42,9.37,5.39,11.56,11.3,3.61,4.27-5.93,6.53-12.65,7.03-19.88.09-3.83-.29-12.64-6.24-9.77Z"/>
          <path fill="#e8c305" d="m500.17,756.81c-7.05,6.63-9.66,15.19-10.7,24.89.78,7.08,3.9,9.43,9.4,3.72,5.15-5.86,7.82-12.85,8.63-20.5.48-4.99-.71-13.4-7.34-8.11Z"/>
          <path fill="#e8c305" d="m432.85,630.27c-8.09-3.08-16.52-4.02-25.11-3.54-2.42.11-9.54,1.59-5.34,4.73,7.17,4.4,15.9,5.27,24.15,5.47,5.73.48,17.73-1.85,6.3-6.66Z"/>
          <path fill="#e8c305" d="m554.39,667.03c-8.88-46.61-34.63-91.14-80.54-108.97-47.46-15.53-55.97-61.98-100.22-78.46,19.52,74.83-67.03,57.5-111.14,71.86,15.37,13.78,26.13,29.35,26.41,50.45-17.6,6.08-35.27,5.54-54.22,4.02,32.64,48.95-10.85,103.45,6.91,156.54,12.09,70.57,88.84,97.78,152.99,84.21,8.19,3.76,16.39,7.52,24.58,11.28,31.6-15.75,61.61-39.7,92.65-58.14.88-.57,1.27-1.23,1.44-2.24,1.4-7.84,2.82-15.67,4.22-23.5,25.39-30.47,41.04-67.03,36.93-107.04Zm-257.38-44.5c33.84-6.64,67.63-13.51,101.47-20.12,45.39,11.33,90.94,25.08,135.88,38.01-29.42,9.59-58.63,19.65-87.99,29.4-49.99-13.5-99.58-31.71-149.37-46.94,0-.12,0-.23,0-.35Zm139.12-11.61c4.69,1.27,9.37,2.56,14.04,3.9-4.68-1.32-9.36-2.62-14.04-3.9Zm-40.83-9.66c-1.09.21-2.18.42-3.26.64,1.06-.22,2.11-.44,3.17-.65.03,0,.06.01.09.02Zm21.23,250.19c-44.78-20.24-89.46-40.65-134.24-60.9-1.5-.68-1.94-1.44-1.81-3.04,4.01-52.94,7.93-105.87,11.74-158.82,49.08,15.84,98.01,32,147.06,47.88,1.18.38,1.61.75,1.41,2.16-8.18,57.56-15.97,115.17-24.17,172.73Zm95.11-56.53c-27.16,20.49-58.35,37.44-86.99,56.54,8.03-57.56,16.52-114.93,24.62-172.46.14-1.1.65-1.48,1.57-1.8,29.09-10.03,57.96-20.82,87.15-30.52,0,0,.09.08.09.08.03-.06.07-.12.1-.19-8.64,49.45-17.64,98.91-26.54,148.35Z"/>
          <path fill="#e8c305" d="m175.53,494.57c-20.9,20.68,2.24,43.92,16.93,59.87,1.1-2.33-.09-3.74-.63-5.25-7.27-25.83,5.81-27.5-16.3-54.62Z"/>
        </g>
      </g>
    </svg>
  )
}