export const GreyLeftChevron = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "10"} 
      height={props?.h ?? "13" }
      viewBox={props?.v ?? "0 0 10 13"} 
      fill="none"
    >
      <path d="M8.06319 13C8.44644 13.0023 8.82174 12.9039 9.14096 12.7174C9.46017 12.5309 9.70875 12.2649 9.85482 11.9534C10.0009 11.6419 10.0378 11.2992 9.96076 10.9691C9.88375 10.6391 9.69634 10.3368 9.42257 10.101L5.28212 6.4805L9.42257 2.8439C9.76237 2.52333 9.94736 2.09934 9.93857 1.66123C9.92977 1.22313 9.72789 0.805135 9.37544 0.495302C9.02299 0.185469 8.5475 0.00799325 8.04914 0.000263461C7.55078 -0.00746632 7.06846 0.155154 6.7038 0.453864L0.108384 6.2518C0.0740408 6.28175 0.0467819 6.31737 0.0281797 6.35662C0.00957745 6.39588 0 6.43798 0 6.4805C0 6.52302 0.00957745 6.56512 0.0281797 6.60438C0.0467819 6.64363 0.0740408 6.67925 0.108384 6.7092L6.7038 12.5071C7.06503 12.8229 7.55378 13.0001 8.06319 13Z" fill="white"/>
    </svg>
  )
}