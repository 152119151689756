export const SmallFlame41 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="281.81 732.54 306.88 806.29 463.45 856.09 510.17 714.22 525.94 629.01 351.09 580.72 281.81 732.54"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m478.37,785.71c2.86-2.15,12.16-32.94,11.14-37.04-5.1,11.93-8.81,24.28-11.14,37.04Z"/>
          <path fill="#e8c305" d="m504.42,709.02c4.87-11.35,8.15-23.16,10.37-35.27-2.95,2.42-11.17,30.32-10.37,35.27Z"/>
          <path fill="#e8c305" d="m489.29,764.79c4.51-11.37,7.97-23.05,10.07-35.13-5.04,11.2-8.37,22.92-10.9,34.88.28.08.56.17.83.25Z"/>
          <path fill="#e8c305" d="m484.45,785.01l-.74-.22c-4.23,10.85-7.67,21.93-9.61,33.48,4.6-10.74,8.23-21.77,10.35-33.26Z"/>
          <path fill="#e8c305" d="m497.83,747.1c4.38-11.12,7.67-22.11,9.84-33.43-2.59,3.03-10.5,29.69-9.84,33.43Z"/>
          <path fill="#e8c305" d="m425.24,716.26c-5.17,3.44-8.07,8.21-8.27,13.56-.03,4.09.9,7.29,2.57,9.86,6.79,10.42,22.02,12.57,31.59,4.49,7.46-6.3,8.8-17.62,1.18-25.25-6.95-6.95-18.75-8.18-27.07-2.66Z"/>
          <path fill="#e8c305" d="m404.94,703.54c-7.22-5.16-17.27-5.34-24.66-.37-5.13,3.46-8.05,8.23-8.29,13.73.02,5.22,1.66,9.21,4.8,12.35,7.37,7.38,20.74,8.27,29.43.59,8.35-7.39,7.8-19.82-1.28-26.3Z"/>
          <path fill="#e8c305" d="m361.24,690.75c-8.5-6.72-22.73-5.17-29.48,3.37-5.26,6.66-5.53,14.42-.33,20.73,3.86,4.69,9.13,6.36,14.24,6.75,7.22,0,12.81-2.15,17.31-6.92,6.77-7.18,6-17.81-1.74-23.93Z"/>
          <path fill="#e8c305" d="m466.53,636.99c-4.84-.03-8.49.89-11.89,2.83-9.13,5.18-10.83,16.26-3.63,23.86,6.89,7.27,19.63,8.31,27.63,2.26,7.89-5.97,8.61-16.11,1.62-23.08-4.05-4.03-9.12-5.52-13.72-5.86Z"/>
          <path fill="#e8c305" d="m408.57,652.2c4,3.57,8.81,5.1,14.66,5.15,4.09-.1,8.33-1.39,12.08-4.16,7.83-5.78,8.51-16.23,1.51-22.89-7.45-7.09-20.98-7.08-28.43.01-6.63,6.32-6.59,15.83.18,21.88Z"/>
          <path fill="#e8c305" d="m365.86,639.96c3.82,3.42,8.4,5.01,13.53,4.98,4.73.02,9.1-1.24,12.94-4.05,8.51-6.23,8.92-17.2.9-23.56-7.84-6.21-20.67-5.41-27.67,1.72-6.09,6.19-6,15.28.3,20.91Z"/>
          <path fill="#e8c305" d="m343.22,766.56c-6.43-3.68-13.23-6.25-20.7-6.74-1.64.05-3.26-.04-4.79.69-1.54.74-2.02,1.81-1.57,3.44.44,1.58,1.49,2.74,2.62,3.83,3.81,3.66,8.41,6.03,13.24,7.96,4.73,1.9,9.61,3.33,14.78,3.16,1.86-.06,3.96-.12,4.87-2.18.93-2.1-.58-3.58-1.79-4.99-1.86-2.17-4.21-3.77-6.67-5.18Z"/>
          <path fill="#e8c305" d="m393.68,801.03c-7.4-5.83-15.86-8.93-25.19-9.81-1.38.11-2.75,0-4.07.44-2.34.78-2.94,2.35-1.66,4.49.76,1.28,1.79,2.32,2.96,3.22,6.35,4.92,13.62,7.7,21.46,9.08,2.57.45,5.2.7,7.75-.28,1.82-.7,2.33-2.16,1.46-3.9-.65-1.3-1.59-2.35-2.7-3.23Z"/>
          <path fill="#e8c305" d="m435.92,829.85c-7.35-5.95-15.94-8.74-25.22-9.66-2.29.06-3.43.23-4.48.75-1.44.71-1.83,1.77-1.15,3.23.78,1.67,2.15,2.82,3.59,3.87,4.81,3.49,10.23,5.66,15.94,7.16,2.99.79,6.03,1.39,9.14,1.18,1.69-.11,3.75-.1,4.42-2.04.68-1.98-.86-3.38-2.23-4.49Z"/>
          <path fill="#e8c305" d="m540.79,607.33c-46.66-60.65-96.19-27.2-116.62-128.02-.68-.11-1.37-.22-2.05-.32-23.98,17.41-25.4,43.09-24.58,70.08-12.16.97-24.82,2.52-36.85,7.58-11.95,26-27.77,49.12-48.99,68.7-25.93-19.8-52.98-37.65-82.71-51.28-3.45-1.58-3.46-1.56-6.63-1.09,47.35,52.48-17.68,118,1.56,176.94,3.69,15.66,10.91,29.9,17.19,44.59,28.83,68.5,116.61,70.47,177.56,47.97,22.95,7.36,40.67,13.05,43.99,14.1.44.14,1.3.36,1.58-.56.75-2.49,1.73-4.42,2.12-7.02-.51-.72-.33-1.52-.23-2.28.7-5.7,6.3-49.57,7.07-55.95.14-1.16.47-2.25.84-3.34,9.31-27.89,45.88-137.3,49.45-147.88.13-.38.21-.8.55-1.25-1.25,6.98-3.38,18.88-5.65,31.55-3.12,16.74-6.61,35.47-8.19,43.94-.08.41-.21.82.01,1.22-10.07,31.29-20.07,64.42-30.43,95.75,64.74-40.81,121.28-135.97,61-203.42Zm-233.13,199.54s0,0,0-.01c1.18.38,2.39.77,3.65,1.17-1.22-.39-2.43-.77-3.65-1.16Zm158.15-12.79c-.8,7.8-5.59,52.9-6.03,57.22-.08.82-.23,2.25-1.35,2.09-1.93-.28-3.59-.85-5.38-1.42-30.31-9.71-125.22-40.2-142.39-45.66-.97-.31-1.31-.87-1.59-1.69-3.14-9.16-19.11-55.17-22.58-65.64-.14-.43-.56-.94-.24-1.32.42-.49.98-.01,1.45.13,14.31,4.41,171.24,53,176.89,54.74.9.28,1.62.6,1.22,1.54Zm2.53-10.19c-.17.51-.07,1.34-.87,1.32-1.5-.46-2.85-.87-4.2-1.28-40.93-12.61-157.11-48.4-174.3-53.61-1.68-.51-1.36-1.21-.85-2.33,6.01-12.97,49.86-107.95,65.74-142.49.65-1.41,1.25-1.61,2.68-1.21,43.25,12.03,86.51,24.03,129.77,36.03,15.29,4.54,27.46,8.18,33.87,10.11-.01.38-.19.92-.48,1.76-13.38,39.52-47.62,140.66-51.38,151.71Z"/>
        </g>
      </g>
    </svg>
  )
}