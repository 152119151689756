export const SmallFlame55 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="265.98 688.13 310.94 827.03 436.45 852.14 556.06 751.43 488.43 630.92 374.09 570.82 265.98 688.13"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m556.93,749.45c-5.77-10.03-11.52-20.08-17.27-30.13.51.82,1.01,1.65,1.53,2.46,21.86-95.83-39-127.01-113.38-162.33-13.93-1.89-32.87,7.12-47.44,12.83-1.25-.68-2.51-1.36-3.76-2.05-2.58-1.36-1.81-1.42-3.68.61-1.7,1.85-3.4,3.71-5.1,5.56-.87.08-1.8.23-3.02.46-29.05,6.64-59.94,13.54-85.41,28.93-15.75,23.98-32.45,47.85-37.31,76.92-13.12-5.21-21.53-14.83-29.38-26.58-14.06,96.1,83.09,206.42,182.77,188.81,13.53,2.75,27.05,5.51,40.55,8.23,40.82-31.68,80.02-67.24,120.29-100.21,1.78-1.5,1.78-1.49.61-3.52Zm-164.72-168.24c10.68,5.86,21.68,11.31,32.68,16.75,15.67,8.03,31.32,16.16,46.59,24.65,6.09,3.68,12.01,7.55,17.7,11.7,20.63,37,43.65,75.2,62.94,111.49-14.65-5.49-29.05-11.63-43.61-17.36-21.95-9.99-46.86-16.48-67.36-28.28-20.04-41.73-40.98-83.22-60.63-125.03,3.88,2.04,7.77,4.07,11.68,6.09Zm40.29,120.44c1.18,2.48,1.2,2.48-.72,4.31-40.06,37.9-79.87,76.06-120.09,113.79-14.1-43.21-28.07-86.29-42.15-129.48-.18-.55-.32-1.02-.2-1.51,35.09-37.7,68.44-73.58,103.71-111.55.07.12.15.25.23.42,19.71,41.36,39.5,82.68,59.23,124.02Zm117.25,53.24c-37.27,31.43-74.57,62.83-111.82,94.29-.8.67-1.54.73-2.45.54-16.29-3.4-32.57-6.79-48.86-10.18-23.27-4.77-45.83-9.79-69.27-14.23,40.52-38.02,80.9-76.04,121.37-114.09,36.63,9.61,73.93,28.74,110.74,41.49,1.93.75,1.93.79.29,2.18Z"/>
          <path fill="#e8c305" d="m328.65,739.46c-9.64,2.84-14.03,13.88-14.32,23.17-.54,7.76,5.34,17.59,13.94,13.34,7.87-4.23,11.89-13.32,12.13-21.94.65-6.73-3.51-16.55-11.75-14.57Z"/>
          <path fill="#e8c305" d="m345.63,645.4c.98,4.4,4.64,7.5,8.77,7.23,10.92-1.83,16.28-13.55,17.03-23.56-2.13-27.58-31.26-3.85-25.81,16.33Z"/>
          <path fill="#e8c305" d="m431.23,763.97c9.02-.7,17.16-4.19,23.45-11.48,5.16-5.33,3.94-14.2-5.01-14.49-18.71-3.31-44.81,23.91-18.44,25.97Z"/>
          <path fill="#e8c305" d="m486.6,756.5c1.52-5.56-4.87-8.36-9.51-8.04-8.05.41-15,3.46-20.94,8.85-8.3,7.08-6.65,16.75,5.36,15.95,10.11-1.05,21.82-6.42,25.08-16.76Z"/>
          <path fill="#e8c305" d="m395.69,803.99c2.52-6.93-4.77-9.59-11.02-9.23-6.49.31-13.28,2.74-19.19,7.46-4.82,3.26-9.21,11.72-1.88,14.78,10.68,3.9,27.29-2.64,32.08-13.02Z"/>
          <path fill="#e8c305" d="m502.81,758.48c-9.18,1.38-18.96,6.22-22.96,14.99-2.39,5.67,3.27,8.92,8.3,8.54,9.05-.72,16.65-4.39,22.68-11.23,6.5-7.63.77-13.62-8.03-12.3Z"/>
          <path fill="#e8c305" d="m424.42,813.76c3.92-5.47,1.09-10.46-5.5-10.78-10.57-.31-21.77,4.14-27,13.66-2.63,5.88,3.79,8.9,8.57,8.64,9.36-.44,18.23-4.09,23.92-11.51Z"/>
          <path fill="#e8c305" d="m450.08,810.94c-15.65-4.74-43.72,18.97-20.65,20.91,5.9-.29,11.43-2.18,16.49-5.48,5.2-2.73,13.11-12.28,4.16-15.43Z"/>
          <path fill="#e8c305" d="m466.21,695.74c6.3.25,3.05-8.15,1.02-11.18-5.25-8.18-12.47-16.55-22.23-19.05-2.78-.47-4.22.76-4.2,3.54,1.45,10.51,15,24.74,25.41,26.68Z"/>
          <path fill="#e8c305" d="m421,607.7c-4.87-3.95-15.09-7.16-10.48,3.13,3.82,7.58,9.65,13.32,16.62,17.99,2.9,1.92,10.08,5.63,10.02-.72-1.94-8.39-9.42-15.33-16.16-20.4Z"/>
          <path fill="#e8c305" d="m516.57,717.37c2.33,1.61,7.83,3.27,7.63-1.27-2.08-10.13-10.1-17.82-18.35-23.51-6.14-4.08-10.25-1.29-6.68,5.63,4,8.01,9.83,14.38,17.4,19.15Z"/>
          <path fill="#e8c305" d="m481.33,654.97c2.23,1.68,8.96,5.7,9.27.63-1.86-9.04-9.51-15.89-16.7-21.13-4.74-3.59-11.1-3.96-7.37,3.75,3.48,6.86,8.64,12.26,14.8,16.76Z"/>
          <path fill="#e8c305" d="m255.51,411.12c12.69,8.57,23.03,19.18,37.58,25-9.27-22.1-21.22-45.47-47.57-46.79-2.72,7.61,4.1,17.21,9.98,21.79Z"/>
        </g>
      </g>
    </svg>
  )
}