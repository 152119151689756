export const NoFlame08 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="307.99 619.19 433.52 578.91 557.9 679.91 495.53 797.35 384.32 868.33 270.83 751.97 307.99 619.19"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m559.82,678.95c-21.24,39.68-42.88,79.08-64.07,118.78-35.56,25.05-73.6,47.42-109.96,71.6-1.51.97-2.26.78-3.46-.44-36.71-39.59-79.88-75.4-113.97-116.05,0,0,24.87-89.82,36.72-133.95,38.67-16.2,82.55-25.9,122.86-40.13,3.9-1.24,6.38-.76,9.56,1.82,40.61,32.99,81.66,65.44,122.32,98.37Zm-5.51,5.85c-36.67,19.7-74.59,38.22-110.32,59.16-18.32,40.09-36.87,80.12-54.52,120.48,34.88-22.82,71.25-44.13,105.15-68.09,19.84-37.2,40.4-74.14,59.67-111.58l.16-.08c-.09-.03-.11.04-.14.11Zm-173.12,178.74c.22-.14.45-.27.67-.41,17.95-40.21,35.65-80.52,53.59-120.74.43-.96.41-1.59-.43-2.3-42.46-38.25-84.58-76.92-127.14-115.05-10.4,42.78-25.41,85.5-35.53,127.36,35.58,36.72,73.93,73.91,108.84,111.14Zm-67.89-244.36c42.52,38.37,85.17,76.53,127.75,114.82.68.62,1.18.59,1.91.19,36.6-19.09,73.33-37.87,109.91-57.01-39.14-31.68-78.49-63.09-117.63-94.78-1.46-1.18-2.62-1.34-4.35-.78-39.26,12.59-78.28,25.01-117.6,37.56Z"/>
          <path fill="#e8c305" d="m554.32,684.8c.03-.07.05-.13.14-.11-.05.03-.11.05-.16.08,0,0,.02.03.02.03Z"/>
          <path fill="#e8c305" d="m374.25,737.86c.95-19.06,19.58-13.83,25.92-.7,6.67,9.37,4.79,31.32-10.82,25.04-4.02-1.73-7-4.66-9.5-8.18-3.29-4.64-5.37-10.78-5.6-16.16Z"/>
          <path fill="#e8c305" d="m319.4,756.69c-.21,2.28-.7,4.8-1.68,6.92-4.38,7.79-14.23.78-17.43-4.64-5.41-6.33-9.81-27.15,3.55-25.74,4.05.91,6.95,3.51,9.37,6.68,3.79,4.95,5.93,10.52,6.19,16.78h0Z"/>
          <path fill="#e8c305" d="m443.93,661.72c-9.1-.7-16.98-3.85-22.8-11.2-9.71-16.65,16.72-15.49,25.01-8.63,10.69,5.98,13.79,19.56-2.2,19.84Z"/>
          <path fill="#e8c305" d="m445.12,770.86c2.82-10.67,11.87-20.05,21.37-25.65,10.48-5.02,6.34,5.55,3.29,10.55-4.01,6.52-9.16,12.09-15.63,16.29-2.99,1.73-9.3,5.36-9.03-1.19Z"/>
          <path fill="#e8c305" d="m443.66,808.25c-3.84,10.03-10.79,18.47-19.95,24-8.9,4.5-7.44-1.25-4.34-7.19,4.11-6.87,9.58-12.54,16.26-16.96,2.18-1.21,8.09-4.63,8.03.14h0Z"/>
          <path fill="#e8c305" d="m504.73,742.65c-2.45.01-3.56-1.09-3.02-2.79.9-2.81,1.92-5.67,3.42-8.18,3.61-6.03,8.3-11.17,14.05-15.26,20.19-11.97-2.28,23.33-14.46,26.23Z"/>
          <path fill="#e8c305" d="m496.54,775.76c-3.71,9.44-10.52,17.34-19.08,22.78-6.38,3.73-6.49-1.06-3.84-5.67,1.03-3.93,21.1-26.8,22.93-17.11Z"/>
        </g>
      </g>
    </svg>
  )
}