import { useState } from "react"
import { Modal } from "../../../utils/modal"
import { CashierAmountIcon } from "../assets/CashierAmountIcon"
import { CashierLocationIcon } from "../assets/CashierLocationIcon"

export const CashierPending = (props) => {

  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(<></>)

  const handleModal = (reqId) => {
    if (!modalOpen) {
      setModalOpen(true)
      setModalData(
        // TODO
        <div id="cancelRequestModal">
          <div className="cancelRequestText">{props.i18nConfirmToCancel}</div>
          <div className="cancelRequestButtons">
            <div onClick={(e)=>{props.cancelRequest(reqId)}}>
            {props.i18nConfirm}
            </div>
            <div onClick={(e)=>{setModalOpen(false)}}>{props.i18nNo}</div>
          </div>
        </div>
      )
    }
  }

  return (
    <div id="cashier">
      <div className="cashRequest">
        <div>
          <div className="requestHeader">{props.requestType == "Deposit" ? props.i18nDepositRequested : props.i18nWithdrawalRequested}</div>
          <ol className="cashierInstructions">
            {props.requestType==="Deposit" ? (<>
              <li>{props.i18nRequestPageInstructions1}</li>
              <li>{props.i18nRequestPageInstructions2}</li>
              <li>{props.i18nRequestPageInstructions3}</li>
            </>) : (<>
              <li>{props.i18nRequestPageInstructions1}</li>
              <li>{props.i18nRequestPageInstructions4}</li>
            </>)}
          </ol>
        </div>
        <div className="requestDetails">
          <div className="fieldRow">
            <CashierAmountIcon w={55} h={55} v={"8 5 55 55"} />
            <div className="text">
              <div>{ props.requestType == "Deposit" ? props.i18nAmountToDeposit : props.i18nAmountToWithdraw }</div>
              <input id="amount"
                name="amount"
                value={props.amount}
                readOnly={true}
              />
            </div>
          </div>
          <div className="requestCode">
            {props.pendingRequest.actionCode.split("").map((c,i)=>(
              <div key={`code${i}`} className="fieldRow">{c}</div>
            ))}
          </div>
          <div className="fieldRow">
            <CashierLocationIcon w={55} h={55} v={"8 5 55 55"} />
            <div className="column text">
              <div>{props.i18nLocation}</div>
              <input id="location"
                name="location"
                value={props.selectedLocation?.name}
                readOnly={true}
              />
            </div>
          </div>
          <div className="cashierCenter cashierCancel" 
            onClick={(e)=>{
              handleModal(props.pendingRequest.id)
            }}
          >
            {props.i18nCancelRequestMsg}
          </div>
        </div>
        <div className="cashierCenter">
          {props.i18nCompleteRequestMsg}
        </div>
      </div>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} element={modalData} />
    </div>
  )
}