export const SmallFlame53 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="269.41 712 316.21 823.24 455.4 858.3 558.6 727.32 480.15 635.07 356.92 574.76 269.41 712"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m559.59,726.46c-7.33-8.37-14.53-16.88-21.7-25.43,7.92-74.55-58.69-124.4-124.79-139.3-23.07,1.93-45.41,8.54-68.8,5.04-20.87-1.71-53.96-6.44-72.65,2.67-28.35,35.93-57.93,79.72-43.8,127.56-1.52.91-2.19-1.68-3.76-.52-3.98,85.3,87.58,151.73,167.84,146.69-.86-.23-1.72-.46-2.58-.69,22.15,5.68,44.3,11.35,66.48,17.01,35.23-42.07,69.21-86.89,103.82-130.01,1.07-1.36.87-1.95-.05-3.04Zm-65.92-77.58c-2.7-3.05-5.42-6.09-8.16-9.1.34.2.68.4,1.02.6,2.39,2.83,4.76,5.66,7.14,8.5Zm-221.96,62.92s0-.03-.01-.04c23.89-37.3,47.74-74.54,71.6-111.79,3.82-5.88,7.65-11.76,11.47-17.64,1.37-2.1,1.36-2.09,2.77-.07,20.07,28.83,40.13,57.66,60.25,86.45.95,1.35.61,2.11-.2,3.25-33.92,47.83-67.58,95.82-101.55,143.61-14.86-34.6-29.44-69.19-44.32-103.77Zm184.65,142.43c-43.54-8-88.4-23.04-132.36-33.35q-1.68-.44-.69-1.83c34.26-47.78,67.96-96.17,102.5-143.64,42.34,18.16,84.63,36.4,126.99,54.53-26.48,40.99-65.85,82.93-96.44,124.29Zm37.87-157.37c-21.66-9.53-43.35-19.01-65.04-28.47-1.63-.71-2.91-1.65-3.96-3.14-20.16-28.91-41.47-57.21-61.17-86.34,0,0,0,0,0,0,11.48,5.79,23.15,11.35,34.86,16.86,24.16,11.95,48.26,24.03,72.48,35.86,3.74,2.08,7.44,4.22,11.12,6.39.65.67,1.26,1.38,1.88,2.1,23.03,27.73,47.12,54.82,69.61,82.86-20-8.67-39.87-17.32-59.78-26.12Z"/>
          <path fill="#e8c305" d="m415.37,731.31c3.14,26.18,48.47,2.57,34.94-16.75-11.41-12.37-34.91,1.55-34.94,16.75Z"/>
          <path fill="#e8c305" d="m484.78,739.57c3.68-8.64-2.29-16.11-10.71-15.96-8.77-.35-19.16,6.65-22.7,15.29-7.33,24.94,28.79,17.3,33.41.68Z"/>
          <path fill="#e8c305" d="m388.55,781.99c-12-.83-30.28,12.16-23.05,25.14,2.63,3.93,6.79,4.72,10.25,4.97,9.94-.03,18.95-5.36,23.22-13.34,4.96-7.87-1.41-17.46-10.42-16.77Z"/>
          <path fill="#e8c305" d="m516.8,745.34c-.5-6.81-6.6-9.31-12.04-8.87-10.46.9-20.99,9.31-22.06,20.01,1.57,21.2,35.62,5.58,34.1-11.14Z"/>
          <path fill="#e8c305" d="m399.66,809.09c-2.54,7.07,4.19,12.96,10.51,12.73,4.77-.07,8.49-1.09,11.93-2.96,5.29-2.88,9.6-6.75,11.6-12.63,4.33-22.96-31.53-12.43-34.04,2.86Z"/>
          <path fill="#e8c305" d="m455.7,803.03c-10.07.1-20.51,6.85-23.11,16.84-1.66,6.79,4.31,11.31,10.42,11.02,5.31-.13,10.05-1.95,14.26-5.08,9.59-6.07,13.75-22.06-1.56-22.78Z"/>
          <path fill="#e8c305" d="m327.38,736.1c-9.7,6.34-14.94,21.76-10.35,32.51,2.52,5.53,9.41,3.83,12.64-.05,5.65-6.11,8.19-13.47,8.64-21.65.37-6.31-3.49-14.9-10.93-10.81Z"/>
          <path fill="#e8c305" d="m348.96,618.12c-8.58,5.14-12.36,14.63-13.15,24.22-.16,4.34,1.7,10.49,7.17,9.4,7.79-2.26,11.94-10.54,14.17-17.75,2.47-6.1,1.5-20.28-8.19-15.87Z"/>
          <path fill="#e8c305" d="m429.99,646.09c-6.22-.32-1.55,5.43.4,7.54,7.51,7.34,16.43,14.95,27.06,16.64,3.93-.34.72-4.97-.7-6.54-7.15-7.75-16.51-15.07-26.76-17.64Z"/>
          <path fill="#e8c305" d="m519.57,698.58c9.67-1.35-17.3-20.84-20.58-21.39-4.42-2.45-10.39-2.98-5.44,3.43,6.93,7.86,15.78,15.3,26.02,17.96Z"/>
          <path fill="#e8c305" d="m400.31,603.36c-7.66-3.23-7.36,1.01-2.68,5.58,2.33,3.26,25.22,20.32,26.91,13.85-4.64-9.43-15.1-14.91-24.23-19.43Z"/>
          <path fill="#e8c305" d="m458.42,632.31c-9.08.84,16.93,19.07,19.81,19.44,3.26,1.88,9.03,2.68,4.82-2.56-6.6-7.46-15.03-14.11-24.64-16.88Z"/>
          <path fill="#e8c305" d="m307.5,454.62c-14.47-37.73-22.99-50.99-65.5-51.52,9.29,28.28,43.47,34.2,65.5,51.52Z"/>
          <path fill="#e8c305" d="m339.66,420.09c-2.12-11.2-1.55-29.99-16.19-29.79,4.74,10.34,3.97,22.78,16.19,29.79Z"/>
        </g>
      </g>
    </svg>
  )
}