export const BigFlame05 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="294.72 622.82 404.73 593.71 545.98 648.96 507.58 797.55 412.6 862.27 276.94 783.16 294.72 622.82"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m556.79,647.88c-4.03-15.39-8.89-30.62-13.33-45.93-1.79-4.95-5.32-13.12-11.2-13.34-2.48.7,1.43,7.12-3.39,9.44-1.95.83-2.9-1.25-4.07-2.24-7.92-6.72-16.64-12.21-25.95-16.68-21.65-10.26-26.24-36.63-31.62-57.63-3.71-16.12-13.07-25.91-30.01-28.25-20.57-2.84-39.69-9.66-56.3-22.56-1.23-.96-2.47-1.71-4.08-.9,14.63,24.4,23.17,45.65,23.31,58.54-45.58-17.29-90.6-35.5-136.85-54,24.18,43.46,32.23,89.79,12.15,136.96-2.02,6.24-5.72,3.67-9.87,1.52-19.73-10.36-55.6-44.95-49.31-68.7,5.28-18.31,15.82-48.03,1.97-64.25-23.12-20.79-34.8-48.24-48.16-75.98-9.6,18.24-43.34,66.57-17.41,81.11,26.15,15.64,44.5,47.1,45.47,77.13-3.64,28.19-28.17,66.61.59,88.1,4.65,3.44,8.28,8.16,11.64,12.93,13.08,18.58,20.71,38.77,19.41,61.99-7.48,75.9,71.57,121.95,139.6,114.13,13.79,8.03,27.58,16.05,41.37,24.08,1.52.89,2.54.93,4.04-.11,21.85-15.05,43.76-30.03,65.65-45.02,9.03-6.19,17.01-11.61,26-17.85,2.75-2.78,3.62-8.31,4.9-11.9,1.54-6.24,3.09-12.47,4.65-18.7,31.61-31.46,52.12-77.23,40.8-121.9Zm-17.01-.62c-30.25,13.57-62.49,26.34-92.92,39.61-49.42-18.66-96.82-43.64-145.93-64.57.02-.12.05-.24.07-.36,34.53-8.82,69.12-18.11,103.66-26.93.52.12,1.03.25,1.55.37,44.88,16.25,89.03,35.14,133.57,51.87Zm-236.62-27.96c-1.6.4-3.05.77-4.33,1.09.19-.05.36-.11.56-.16,1.26-.31,2.51-.62,3.76-.93Zm105.99,238.03c-43.08-24.79-85.99-49.73-129.09-74.49-1.29-.74-1.78-1.42-1.58-3.02,6.17-50.68,11.36-101.54,17.76-152.15,48.25,21.86,97.06,44.54,145.36,66.27l-32.45,163.39Zm99.78-66.74c-1.04,4.19-2.95,6.94-6.55,9.36-28.46,19.04-56.66,38.41-85.06,57.55,10.55-53.64,22.18-107.14,33.03-160.75.26-1.26.69-2.07,2-2.64,30.4-13.32,60.65-26.92,91.08-40.18-11.53,45.71-23.13,90.97-34.49,136.66Z"/>
          <path fill="#e8c305" d="m393.09,699.18c-21.56,1.6-14.6,34.9,2.61,38.69,4.69,1.46,8.92.28,12.35-3.29,10.26-11.94.38-34.15-14.96-35.4Z"/>
          <path fill="#e8c305" d="m316.32,747.55c-3.97-2.76-9.06-2.85-12.33-.23-9.64,9.27-2.27,27.15,8.6,32.21,7.85,4.11,15.26-3.76,14.53-11.62-.39-8.47-3.7-15.43-10.8-20.36Z"/>
          <path fill="#e8c305" d="m469.23,708.53c-8.25,7-13.41,18.07-13.21,28.9.16,2.24.65,4.53,2.97,5.51,10.76,2,19.51-20.02,19.97-29.02-.7-6.71-3.43-9.94-9.73-5.4Z"/>
          <path fill="#e8c305" d="m455.29,787.63c-5.49,2.31-8.97,7.55-11.78,12.56-2.63,4.82-4.49,9.89-4.86,15.67,2,19.31,25.37-11.76,21.94-25.19-.32-2.9-2.59-4.05-5.3-3.04Z"/>
          <path fill="#e8c305" d="m516.23,685.99c-7.69,6.74-11.01,15.66-12.4,25.85.8,6.71,3.24,8.5,8.8,3.83,7.14-6.92,11.18-16.92,11.01-26.8-.36-4.93-3.65-6.17-7.41-2.87Z"/>
          <path fill="#e8c305" d="m498.28,761.59c-7.16,6.52-12.02,16.24-12.12,25.99.2,4.09,2.66,5.32,6.11,3.12,7.7-5.78,12.15-15.78,13.21-25.06.25-5.54-2.7-7.68-7.2-4.05Z"/>
          <path fill="#e8c305" d="m413.52,638.42c8.36,3.43,17.98,5.13,26.72,2.22,2.72-.93,3.02-3.23.93-5.28-6.71-5.74-15.84-7.12-24.43-7.84-2.51.42-5.04.73-7.52,1.29-1.43.32-2.85,1.02-3.17,2.69-.33,3.84,4.78,5.31,7.46,6.91Z"/>
        </g>
      </g>
    </svg>
  )
}