export const NoFlame42 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="284.48 744.53 306.3 802.09 461.23 850.76 476.76 798.41 526.9 632.87 352.56 583.23 284.48 744.53"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m351.11,579.86c.41.12.76.22,1.12.32,10.31,3,20.64,5.92,30.91,9.06,3.83,1.17,7.66,2.36,11.51,3.49,4.96,1.46,9.92,2.92,14.9,4.31,26.83,7.44,53.48,15.48,80.23,23.2,12.63,3.64,25.24,7.36,37.87,10.99,1.03.3,1.32.59.98,1.7-8.89,29.4-17.75,58.81-26.61,88.21-8.23,27.3-16.45,54.61-24.69,81.91-1.72,5.71-3.51,11.4-5.28,17.09-3.09,9.96-6.2,19.92-9.24,29.9-.39,1.28-.79,1.57-2.15,1.14-48.43-15.31-96.87-30.57-145.3-45.83-3.3-1.04-6.58-2.1-9.89-3.1-.89-.27-1.37-.76-1.69-1.63-6.6-18.19-13.23-36.37-19.85-54.55-.3-.81-.36-1.57-.01-2.42,7.95-19.48,15.88-38.97,23.81-58.45,5.15-12.64,10.3-25.28,15.45-37.93,8.97-22.04,17.93-44.08,26.92-66.11.21-.51.17-1.34,1.03-1.31Zm171.19,55.14c-.09-.83-.96-.69-1.52-.86-18.79-5.49-37.59-10.96-56.38-16.44-36.33-10.59-72.67-21.18-108.99-31.81-1.17-.34-1.62-.08-2.07,1.03-4.06,10.16-8.17,20.3-12.27,30.44-16.47,40.77-32.93,81.54-49.42,122.3-.45,1.11-.56,1.62.87,2.07,59.43,18.34,118.84,36.73,178.25,55.11,2.11.65,2.09.66,2.73-1.45,5.6-18.43,11.19-36.85,16.8-55.28,10.1-33.19,20.22-66.38,30.33-99.57.55-1.8,1.08-3.61,1.66-5.55Zm-51.69,170.46c.2-.83-.4-.98-.97-1.15-2.1-.66-4.19-1.32-6.29-1.97-41.85-12.98-83.71-25.96-125.56-38.95-15.45-4.79-30.9-9.59-46.35-14.37-.47-.15-1.03-.56-1.45-.08-.35.4.06.89.21,1.32,5.8,16.66,11.62,33.31,17.38,49.99.42,1.23,1.06,1.85,2.29,2.23,26.83,8.42,53.64,16.87,80.46,25.33,21.97,6.92,43.95,13.84,65.91,20.81,1.34.43,1.93.27,2.34-1.18,3.47-12.24,7.01-24.45,10.53-36.68.51-1.76,1-3.52,1.49-5.29Z"/>
          <path fill="#e8c305" d="m441.38,760.24c-11.55-.22-20.57-9.02-20.27-19.92.2-7.32,5.36-14.07,12.88-16.72,8.69-3.06,18.67.08,24.01,7.57,5.83,8.17,4.02,19.23-4.12,25.19-3.63,2.65-7.59,3.88-12.5,3.88Z"/>
          <path fill="#e8c305" d="m395.66,708.88c9.62-.09,17.68,5.88,19.84,14.7,1.68,6.86-1.2,14.25-7.29,18.75-9.24,6.81-23.08,4.47-29.49-4.98-5.37-7.92-3.19-18.88,4.89-24.59,3.6-2.55,7.6-3.83,12.06-3.87Z"/>
          <path fill="#e8c305" d="m350.14,732.26c-9.59-.2-17.85-6.33-19.11-15.53-.98-7.2,1.86-12.98,7.63-17.31,7.38-5.53,18.24-5.38,25.45.29,9.44,7.41,8.95,21.24-1.03,28.53-3.85,2.81-8.19,4.1-12.94,4.02Z"/>
          <path fill="#e8c305" d="m468.92,677.21c-5.71-.04-10.82-1.88-14.95-6.01-8.56-8.54-6.24-21.74,4.68-26.91,8.54-4.05,19.45-1.46,25.24,5.97,6.11,7.85,3.99,18.93-4.58,24.06-3.18,1.9-6.61,2.83-10.38,2.89Z"/>
          <path fill="#e8c305" d="m424.36,629.68c7.07.39,13.04,2.96,16.91,9.15,4.55,7.27,2.42,16.56-4.77,21.64-8.35,5.9-20.55,4.47-27.28-3.18-7.63-8.69-3.98-22.13,7.12-26.19,2.58-.95,5.23-1.48,8.01-1.41Z"/>
          <path fill="#e8c305" d="m382.51,617.12c4.92.22,10.45,2.19,14.42,7.32,5.23,6.78,4.34,15.53-2.05,21.5-7.69,7.18-21.07,6.8-28.37-.75-7.05-7.29-5.64-17.92,1.92-23.84,3.71-2.9,7.98-4.29,14.08-4.23Z"/>
          <path fill="#e8c305" d="m349.09,784.71c-5.87-.32-11.77-2.26-17.55-4.64-3.49-1.44-6.83-3.19-9.82-5.52-1.03-.8-2.07-1.64-2.63-2.86-.73-1.59-.34-2.44,1.35-2.96,1.95-.6,3.98-.49,5.92-.2,8.69,1.31,16.84,4.17,24.24,8.99,1.42.92,2.69,2.01,3.72,3.34,1.25,1.61.76,2.99-1.19,3.57-1.18.35-2.39.2-4.04.28Z"/>
          <path fill="#e8c305" d="m368.46,795.5c6.44.38,12.54,2.16,18.46,4.62,3.34,1.39,6.55,3.02,9.4,5.3.49.39.96.82,1.34,1.31,1.53,1.94,1,3.26-1.46,3.64-3,.47-5.95,0-8.86-.65-7.25-1.6-14.16-4.07-20.36-8.29-1.09-.74-2.08-1.59-2.78-2.72-.81-1.34-.5-2.27,1-2.76,1.04-.34,2.14-.49,3.25-.45Z"/>
          <path fill="#e8c305" d="m433.98,834.22c-6.71-.41-13.04-2.29-19.2-4.87-2.51-1.06-4.93-2.3-7.15-3.89-.9-.64-1.7-1.38-2.3-2.32-.67-1.06-.37-1.79.73-2.22,1.29-.51,2.65-.65,4.01-.52,8.88.85,17.18,3.5,24.83,8.11,1.17.71,2.23,1.57,3.06,2.67.87,1.15.52,2.28-.86,2.66-1,.28-2.04.47-3.11.39Z"/>
        </g>
      </g>
    </svg>
  )
}