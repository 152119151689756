import { MouseEventHandler, TouchEventHandler } from "react";

const Style: { [key: string]: React.CSSProperties } = {
    container: {
        display: "flex",
        flexGrow: "1",
    },
    button: {
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        minHeight: "68px",
        // padding: "5% 0",
        borderRadius: "6px",
        border: "1px solid #42404B",
        boxShadow: "12px 16px 22px 0px rgba(22, 25, 97, 0.33)",
    },
    green: {
        background: "linear-gradient(172deg, #9AE18A -10.33%, #3AE512 44.59%)",
    },
    grey: {
        background: "grey",
    },
    red: {
        background: "linear-gradient(125deg, #E25B5B 11.04%, #CD3B3B 70.92%)",
    },
    negativeSpaceCompensated: {
        flexGrow: "6",
    },
    negativeSpaceEven: {
        flexGrow: "1",
    },
    mainLabel: {
        display: "flex",
        flexGrow: "3", // vertical spacing
        width: "100%", // horizontal spacing
        justifyContent: "center",
        color: "white",
        fontFamily: "Days One",
        fontSize: "16px",
    },
    subtext: {
        display: "flex",
        flexGrow: "1", // vertical spacing
        width: "100%", // horizontal spacing
        justifyContent: "center",
        color: "#181C27",
        fontFamily: "Days One",
        fontSize: "x-small",
        gap: "10px",
    },

};

// Props
type Props = {
    mainLabel: string,
    subtext?: Array<string>,
    bgColor: "green" | "grey" | "red",
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined | undefined,
    onTouch?: TouchEventHandler<HTMLButtonElement> | undefined,
    cssOverride?: string,
}

export const Button = ({
    mainLabel = "",
    subtext = [],
    bgColor,
    onClick = undefined,
    onTouch = undefined,
    cssOverride = "",
}: Props) => {
    const subtextHTML = subtext.map(function(subtext){
        return <span key={subtext}>{subtext}</span>;
    });
    let color = undefined;
    switch (bgColor) {
        case "green":
            color = Style.green;
            break;
        case "grey":
            color = Style.grey;
            break;
        case "red":
            color = Style.red;
            break;
        default:
            break;
    }

    return (
        <div style={Style.container}>
            <button 
                style={{...Style.button, ...(color)}}
                className={cssOverride} 
                onClick={onClick}
                onTouchEnd={onTouch}>
                <label style={subtext.length == 0 ? Style.negativeSpaceEven : Style.negativeSpaceCompensated}>
                    {/* Negative space */}
                </label>
                <label style={Style.mainLabel}>
                    { mainLabel }
                </label>
                <label style={Style.subtext}>
                    { subtextHTML }
                </label>
            </button>
        </div>
    );
}