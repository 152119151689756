export const SmallFlame48 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="284.96 762.38 310.92 798.9 485.4 849.7 547.5 675.46 374.41 609.66 338.45 608.65 284.96 762.38"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m547.79,673.25c-2.36-.89-5.2-1.97-8.43-3.19-4.08-27.42-20.74-50.58-36.77-72.4-5.17-7.17-12.23-12.78-20.04-17.01-16.19-8.77-30.74-19.75-44.44-31.96-19.75-18.6-44.69-29.66-71.36-34.57.37,39.4-51.53,48.51-78.13,27.9-14.6-9.39-24.88-23.72-38.23-34.11-35.92,39.19-66.36,113.48-17.43,154.23,8.2,6.23,14.98,14.11,20,23.49-32.72,108.89,81,187.99,178.95,149.32,26.67,7.83,48.46,14.23,53.19,15.64,1.35.4,1.9.16,2.33-1.21.56-1.74,13.14-37.07,27.61-77.66,13.12-16.6,25.54-51.78,25.91-72.69,2.99-8.39,5.76-16.16,8.19-22.98.82-2.29,1.05-1.89-1.34-2.79Zm-237.37,119.2c-.56.08-20.41-29.87-23.14-33.35,17.35-49.15,34.6-98.05,52-147.35,5.63.27,26.94,1.31,31.72,1.62,2.29.15,2.3.07,1.56,2.18-7.03,20.06-59.46,169.91-61.44,175.65-.16.47-.03,1.16-.7,1.26Zm232.23-112.91c-17.85,50.1-35.69,100.2-53.54,150.29-1.6,4.49-3.22,8.97-4.77,13.47-.34,1-.71,1.27-1.79.95-27.2-8.08-54.42-16.12-81.63-24.16-27.46-8.12-54.92-16.25-82.4-24.33-1.35-.4-1.54-.79-1.07-2.11,20.55-58.53,41.05-117.07,61.56-175.61.79-2.25.8-2.24,3.07-1.38,41.91,15.76,83.81,31.51,125.72,47.27,11.47,4.31,22.94,8.62,34.41,12.94.43.16,1.11.11,1.07.83-.22.65-.42,1.25-.63,1.84Z"/>
          <path fill="#e8c305" d="m324.44,723.19c-.63-.26-1.05.27-1.48.64-.72.62-1.27,1.39-1.76,2.2-4.19,6.87-6.65,14.41-8.46,22.19-.72,3.09-1.37,6.22-.97,9.45.09.71.2,1.42.94,1.65.7.22,1.18-.38,1.61-.79.52-.5.93-1.13,1.35-1.73,2.01-2.91,3.41-6.12,4.67-9.4,2.46-6.41,4.47-12.95,4.99-19.85.04-.47.13-.94.12-1.4,0-.47-.13-.93-.15-1.4-.03-.67-.22-1.3-.86-1.56Z"/>
          <path fill="#e8c305" d="m339.34,631.04c-.63-.26-1.05.27-1.48.64-.72.62-1.27,1.39-1.76,2.2-4.19,6.87-6.65,14.41-8.46,22.19-.72,3.09-1.37,6.22-.97,9.45.09.71.2,1.42.94,1.65.7.22,1.18-.38,1.61-.79.52-.5.93-1.13,1.35-1.73,2.01-2.91,3.41-6.12,4.67-9.4,2.46-6.41,4.47-12.95,4.99-19.85.04-.47.13-.94.12-1.4,0-.47-.13-.93-.15-1.4-.03-.67-.21-1.29-.86-1.56Z"/>
          <path fill="#e8c305" d="m407.28,695.36c10.59-.6,18.85-9.51,18.85-20.32.09-10.95-8.11-19.76-19.24-19.67-10.3.08-18.38,7.8-19.4,18.59-1.14,12.07,8.28,22.06,19.79,21.41Z"/>
          <path fill="#e8c305" d="m376.04,745.05c-11.02.1-20.25,9-20.26,20.73,0,10.58,7.86,18.43,18.51,18.59,11.22.16,20.62-9.29,20.62-20.4,0-10.74-8.13-18.89-18.87-18.91Z"/>
          <path fill="#e8c305" d="m452.93,709.5c8.89-2.3,15.05-10.46,14.97-19.86.08-2.17-.33-4.27-1.02-6.32-3.65-10.85-15.92-15.76-25.9-10.3-9.54,5.21-13.18,16.63-8.73,26.51,3.54,7.86,12.36,12.12,20.67,9.97Z"/>
          <path fill="#e8c305" d="m419.08,758.9c-1.79-.02-3.55.24-5.26.75-9.75,2.95-16.04,12.81-14.34,22.91,1.47,8.73,7.82,14.13,16.14,14.87,10.62.94,19.78-7.29,21.22-17.52,1.61-11.4-6.35-20.9-17.75-21.02Z"/>
          <path fill="#e8c305" d="m482.86,687.7c-6.01,3.09-9.49,8.15-10.66,14.83-1.95,11.06,5.82,21.55,16.79,21.69,1.31.08,2.6-.11,3.88-.38,11.16-2.27,17.55-14.51,14.73-24.94-2.94-10.9-14.72-16.37-24.74-11.21Z"/>
          <path fill="#e8c305" d="m460.08,772.28c-10.5.46-18.87,9.5-18.9,20.15-.11,11.82,9.95,19.62,20.93,17.35,10.32-2.14,17.51-13.25,15.38-23.58-1.77-8.58-8.91-14.29-17.4-13.91Z"/>
          <path fill="#e8c305" d="m353.15,479.16c-6.79-22.97,4.31-31.64-21.39-47.12-3.52,28.47-12.33,37.78,21.39,47.12Z"/>
          <path fill="#e8c305" d="m150.56,462.06c6.11,6.76,11.45,14.65,19.87,19.98,6.93-10.89-12.84-40.04-19.87-19.98Z"/>
        </g>
      </g>
    </svg>
  )
}