export const SmallFlame46 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="342.1 624.64 293.38 777.58 304.91 783.8 486.79 838.29 539.89 657.61 361.58 596.04 342.1 624.64"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m367.84,729.24c-11.22.19-19.58,8.92-19.73,20.05-.15,11.14,8.54,19.86,19.77,20.1,11.49-.08,20.26-9.04,20.15-20.59-.1-10.81-9.2-19.75-20.2-19.57Z"/>
          <path fill="#e8c305" d="m412.23,743.28c-10.63.22-19.37,9.5-19.06,20.51.32,11.07,8.8,19.07,19.53,19.33,11.22-.18,19.8-9.17,19.61-20.58-.17-10.76-9.27-19.49-20.09-19.26Z"/>
          <path fill="#e8c305" d="m396.36,679.27c10.86-.01,18.7-8.61,19.06-18.62.41-11.37-8.62-20.66-19.73-20.84-10.48-.22-19.24,7.71-19.44,19.14-.19,11.14,8.59,20.33,20.11,20.32Z"/>
          <path fill="#e8c305" d="m456.22,757.08c-10.39.06-18.85,9.11-18.82,20.14.23,10.56,8.02,19.25,19.25,19.28,10.61.03,18.88-8.5,19.04-19.71.15-11.08-8.59-19.77-19.46-19.72Z"/>
          <path fill="#e8c305" d="m440.21,693.71c10.43-.06,18.49-8.19,18.71-18.73.24-11.8-8.87-20.11-19.29-20.39-10.81,0-19.12,8.36-19.12,19.25,0,10.87,8.84,19.94,19.7,19.87Z"/>
          <path fill="#e8c305" d="m482.64,669.11c-10.54-.18-18.68,8.28-18.74,19.23-.06,10.54,8.74,19.43,19.35,19.54,9.91.11,18.28-8.54,18.32-18.91.08-10.74-8.15-19.68-18.93-19.86Z"/>
          <path fill="#e8c305" d="m547.02,670.5c-9.32-51.06-34.94-106.83-87.98-123.88-53.44-18.97-49.84-52.99-118.48-66.13,51.52,91.05-57.18,49.05-104.36,55.85-4.95,32.78,17.4,62,20.26,93.68-3.21.23-6.24.46-9.6.7,17.21,30.25,3.65,56.24-1.64,87.15-15.53,113.46,117.51,157.65,203.83,110.12,12.61,3.76,25.21,7.52,37.82,11.27,2.54.76,2.52.76,3.26-1.8,5.05-17.44,10.1-34.87,15.15-52.31,29.62-30.01,47.7-72.37,41.73-114.65Zm-208.36-33.25c.35-1.23.75-2.41,1.17-3.59l1.22-3.1c1.41-3.34,3.07-6.49,4.91-9.51,3.74-4.99,11.37-15.36,13.52-18.28-1.02,3.32-2.05,6.64-3.1,9.96-9.93,31.43-19.86,62.87-29.81,94.29-7.63,24.11-15.29,48.22-22.93,72.33h0s-7.11-3.02-7.11-3.02c13.87-46.42,28.89-92.49,42.13-139.08Zm147.75,193.77c-.62,2.12-.61,2.12-2.59,1.52-52.61-15.76-105.22-31.51-157.82-47.27-4.97-1.49-9.91-3.04-14.9-4.45-1.22-.35-1.39-.76-1.02-1.93,8.7-27.44,17.36-54.89,26.03-82.34,9.77-30.94,19.53-61.89,29.3-92.83.63-2,.64-2,2.59-1.33,55.5,19.09,111,38.17,166.5,57.28.59.2,1.63.12,1.4,1.06-1.1,3.81-2.14,7.38-3.18,10.94-15.44,53.11-30.88,106.23-46.31,159.34Z"/>
          <path fill="#e8c305" d="m168.42,478.36c-15.95,10.35,6.81,34.7,12.88,46.08-3.43-15.91,2.09-34.99-12.88-46.08Z"/>
        </g>
      </g>
    </svg>
  )
}