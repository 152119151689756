export const NoFlame21 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="302.51 642.42 455.85 589.7 475.19 638.21 530.81 806.12 357.12 861.7 285.56 699.13 302.51 642.42"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m283.69,699.37c.2-.64.49-1.59.78-2.54,5.4-17.72,10.81-35.43,16.16-53.16.35-1.15,1.05-1.53,2.03-1.87,31.03-10.7,62.06-21.41,93.09-32.12,20.44-7.06,40.88-14.1,61.31-21.2,1.23-.43,1.74-.36,2.26.98,5.67,14.45,11.62,28.79,17.08,43.32,3.35,8.91,5.97,18.09,8.95,27.14,9.36,28.45,18.72,56.9,28.09,85.35,6.58,19.99,13.15,39.99,19.79,59.97.51,1.53.25,2-1.27,2.49-13.91,4.45-27.79,8.98-41.67,13.5-39.74,12.95-79.47,25.9-119.21,38.85-4.83,1.57-9.67,3.11-14.48,4.73-1.07.36-1.49.14-1.94-.91-19.77-45.65-39.58-91.28-59.39-136.92-3.73-8.6-7.46-17.2-11.19-25.8-.21-.48-.49-.93-.39-1.81Zm6.39-3.47c.55-.14,1.12-.25,1.66-.43,24.42-8.34,48.84-16.69,73.26-25.03,34.68-11.84,69.36-23.68,104.04-35.51,1.86-.64,1.85-.63,1.14-2.51-4.98-13.34-9.98-26.66-14.89-40.03-.56-1.52-1.12-1.71-2.59-1.2-27.35,9.5-54.72,18.94-82.09,28.39-21.28,7.35-42.56,14.69-63.86,22-1.16.4-1.76.96-2.1,2.17-3.92,13.68-7.93,27.34-11.85,41.02-1.04,3.61-2.26,7.18-2.98,10.88-.02.11-.05.22-.07.34.11-.03.22-.06.33-.09Zm182.5-54.41c-.45.15-.85.29-1.25.42-20.12,6.85-40.25,13.71-60.37,20.56-39.26,13.37-78.52,26.74-117.78,40.11-2.05.7-2.04.69-1.21,2.63,5.37,12.45,10.75,24.9,16.11,37.35,16.47,38.22,32.94,76.44,49.36,114.68.67,1.57,1.29,1.79,2.86,1.27,13.96-4.63,27.96-9.16,41.95-13.72,41.06-13.39,82.12-26.79,123.2-40.14,1.37-.45,1.57-.89,1.12-2.24-15.19-45.64-30.34-91.29-45.5-136.94-2.52-7.6-5.05-15.2-7.6-22.79-.16-.47-.06-1.25-.89-1.2Z"/>
          <path fill="#e8c305" d="m290.07,695.9c-.11.03-.22.06-.33.09.02-.11.05-.22.07-.34.09.08.17.17.26.25Z"/>
          <path fill="#e8c305" d="m413.46,737.01c8.2.07,16.3,4.87,18.67,13.34,1.51,5.39.46,10.36-2.85,14.84-7.11,9.64-22.27,10.97-30.82,2.63-8.59-8.38-6.56-21.48,3.68-27.66,3.94-2.37,7.35-3.16,11.32-3.15Z"/>
          <path fill="#e8c305" d="m436.62,628.31c1.07-.02,2.11.15,3.12.43,2.02.56,2.51,1.96,1.35,3.69-.77,1.15-1.8,2.06-2.92,2.81-8.32,5.61-17.5,8.83-27.48,9.78-1.41.13-2.84-.01-4.17-.51-1.7-.63-2.05-1.63-1.16-3.18.83-1.44,2.08-2.49,3.43-3.39,8.44-5.64,17.77-8.72,27.84-9.63Z"/>
          <path fill="#e8c305" d="m366.85,646.6c-1.34.11-2.33-.03-3.28-.32-1.49-.47-1.82-1.39-1.02-2.75,1.25-2.11,3.26-3.38,5.28-4.57,7.16-4.22,14.84-6.99,23.11-8.03,1.46-.18,2.94-.17,4.36.27,1.49.46,1.86,1.35,1.17,2.77-.52,1.07-1.39,1.85-2.32,2.52-7.39,5.38-15.72,8.41-24.67,9.89-.52.09-1.03.18-1.55.22-.47.04-.94,0-1.08,0Z"/>
          <path fill="#e8c305" d="m352.91,633.06c1.21.15,2.89-.16,3.45,1.37.51,1.39-.86,2.25-1.75,3.05-3.48,3.14-7.69,5.04-11.95,6.78-4.51,1.85-9.16,3.26-14.04,3.78-1.05.11-2.08.15-3.12-.08-2.04-.45-2.47-1.64-1.1-3.22,1.63-1.89,3.72-3.2,5.88-4.39,6.71-3.68,13.78-6.29,21.42-7.24.36-.05.73-.04,1.21-.07Z"/>
        </g>
      </g>
    </svg>
  )
}