export const SmallFlame52 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="271.17 721.88 317.46 818.92 463.3 859.07 557.43 715.5 480 640.58 351.19 579.37 271.17 721.88"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m559.23,715.21c-8.59-8.05-17.07-16.25-25.52-24.48-.47-75.8-81.17-135.22-154.03-125.74-38.35,8.25-73.14-14.05-110.95-15.92-39.92,44-81.55,116.41-29.18,167.19.98,32.9,20.97,58.88,43.36,80.82,29.43,31.98,69.77,49.62,112.26,44.56,23.01,6.24,46.02,12.47,69.04,18.7,32.7-45.77,63.26-95.4,95.3-142.41.76-1.15.7-1.8-.28-2.73Zm-6.47-3.65c-45.23-20.13-91.4-38.82-136.06-59.88-17.9-20.2-35.22-40.91-53.18-61.06-1.86-2.02-3.54-4.2-5.3-6.32,41.91,19.96,85.39,37.87,125.79,60.25,8.54,8.44,17.26,16.79,26,25.12,5.46,5.41,10.96,10.76,16.6,15.87,8.9,8.58,17.69,17.22,26.15,26.02Zm-278.18,7.82c20.48-36.82,41.24-73.49,61.52-110.42,4.29-7.66,8.58-15.33,12.87-23,.05-.08.09-.16.14-.24.69-1.21,1.13-.41,1.58.13,19.47,22.96,38.8,46.03,58.29,68.97-27.61,52.76-62.2,105.04-92.28,157.53-14.18-29.71-28.13-59.26-42.32-88.92-.71-1.49-.61-2.6.2-4.05Zm40.89,98.8c.25.41.49.82.75,1.23-.07-.02-.14-.04-.21-.06-.18-.39-.36-.78-.54-1.17Zm237.52-97.55c-30.13,44.68-59.15,90.87-89.76,134.84-45.95-12.7-91.93-25.34-137.9-38-2.16-.59-2.16-.59-1.06-2.42,26.86-44.95,53.72-89.9,80.58-134.84,3.68-6.16,7.38-12.3,11-18.49.63-1.07,1.15-1.29,2.33-.78,44.74,19.23,89.46,38.52,134.24,57.65,1.34.56,1.26.99.56,2.05Z"/>
          <path fill="#e8c305" d="m425.83,732.57c21.37.91,34.31-35.27,7.04-35.33-20.37-.06-33.49,33.76-7.04,35.33Z"/>
          <path fill="#e8c305" d="m387.79,774.99c-7.5,0-17.14,3.95-21.83,13.25-10.04,23.25,24.41,25.43,32.9,7.6,6.47-9.12-.36-21.37-11.06-20.85Z"/>
          <path fill="#e8c305" d="m448.16,730.13c-1.61,8.5,3.34,15.04,11.88,15.66,11.12,1,23.14-9.64,23.6-20.02-.85-23.96-33.43-13.03-35.48,4.36Z"/>
          <path fill="#e8c305" d="m423.55,786.9c-9.88.62-18.52,6.41-21.44,16.07-5.19,22.15,27.21,18.12,32.93,2.6,5.49-8.64-1.25-19.6-11.48-18.67Z"/>
          <path fill="#e8c305" d="m482.49,745.94c-.49,6.02,3.55,11.14,9.48,12.14,10.95,2.28,24.01-8.91,24.69-18.56-.47-25.53-34.19-11.33-34.17,6.42Z"/>
          <path fill="#e8c305" d="m456.41,798.15c-7.74.97-14.71,5.44-18.65,14.01-3.93,8.55,1.17,16.47,10.52,16.5,17.65,1.36,35.46-29.94,8.12-30.52Z"/>
          <path fill="#e8c305" d="m322.48,769.69c8.09-3.45,11.47-12.9,13.27-20.89,2.21-7.94-1.19-22.19-11.22-12.8-6.26,6.64-8.84,14.74-9.42,23.63-.31,4.5,2.11,11.81,7.36,10.05Z"/>
          <path fill="#e8c305" d="m341.64,651.33c7.26-6.48,11.5-16.67,10.79-26.4-.15-4.99-5.1-7.49-8.8-3.9-7.52,6.33-10.54,14.82-11.51,24.25-.27,6.52,3.93,10.85,9.53,6.04Z"/>
          <path fill="#e8c305" d="m419.66,640.37c6.62,8.47,20.89,16.77,31.25,19.25,12.39-.37-34.65-29.02-31.25-19.25Z"/>
          <path fill="#e8c305" d="m495.57,670.93c-4.43-2.32-11.82-3.86-4.9,2.7,7.9,6.8,16.91,12.94,27.02,15.73,8.17-1.26-19.54-17.89-22.12-18.44Z"/>
          <path fill="#e8c305" d="m416.63,618.83c-8.06-6.64-17.13-12.76-27.4-15.16-7.88,1.04,20.89,17.64,23.33,17.76,3.78,1.75,9.75,2.65,4.07-2.6Z"/>
          <path fill="#e8c305" d="m463.28,637.32c-3.74-2.29-16.24-7.19-7.17,1.09,7.29,5.68,15.33,11.39,24.37,13.67,7.06-.38-15.41-14.23-17.21-14.76Z"/>
          <path fill="#e8c305" d="m260.46,439.97c11.45,9.77,21.45,20.4,33.08,30.01,10.35-34.75-6.78-51.31-37.11-64.5-7.83,11.76-6.01,25.53,4.03,34.49Z"/>
          <path fill="#e8c305" d="m336.42,427.96c4.93-16.14,3.44-28.71-4.23-33.12-5.94,19.48-4.62,29.44,4.23,33.12Z"/>
        </g>
      </g>
    </svg>
  )
}