export const NoFlame12 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>      
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="319.23 622.3 469.29 579.55 561.67 727.62 482.25 794.17 350.31 856 274.92 710.59 319.23 622.3"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m470.83,576.31c31.33,48.93,60.85,100.3,91.69,149.97.61.98.48,1.43-.38,2.14-23.54,19.61-47.06,39.26-70.58,58.9-5.74,5.77-12.76,9.35-20.1,12.54-40.11,18.78-80.21,37.6-120.31,56.4-1.17.7-1.78.33-2.4-.87-15.41-29.93-30.86-59.83-46.31-89.74-9.61-18.6-19.21-37.21-28.86-55.8-.57-1.1-.57-1.93,0-3.02,15.77-28.52,28.16-60.43,45.62-87.29,50.57-14.31,101.06-28.93,151.64-43.24Zm84.18,156.18c-46.91,21.12-94.09,38.92-140.07,61.78-18.72,18.98-39.7,38.96-58.04,58.14,41.55-19.02,83.47-38.65,124.77-58.42,24.33-20.53,49.49-40.77,73.34-61.5.13-.03.26-.05.39-.08-.03-.06-.07-.11-.1-.17-.09.08-.19.16-.28.25Zm-229.8-109.29c29.53,54.01,59.26,107.89,88.81,161.9.61,1.11,1.11,1.33,2.29.81,40.12-17.65,80.29-35.17,120.44-52.76,6.24-2.73,12.47-5.46,18.72-8.17,2.39-.64-1.72-4.56-2.09-5.75-28.69-45.05-54.7-94.71-84.72-137.52-47.84,13.87-95.59,27.62-143.44,41.49Zm-6.5,4.45c-4.13,7.24-7.41,14.09-11.13,21.38-10.28,19.91-20.55,39.83-30.87,59.72-.58,1.12-.51,1.96.05,3.03,23.83,45.85,47.56,91.76,71.09,137.74.59,1.11,1.16,1.21,2.15.19,18.77-19.22,37.59-38.4,56.5-57.49.98-.99,1.13-1.69.45-2.92-29.48-53.88-58.74-107.75-88.24-161.65Z"/>
          <path fill="#e8c305" d="m555.01,732.49c.09-.08.19-.16.28-.25l.1.17c-.13.03-.26.05-.39.08,0,0,0,0,0,0Z"/>
          <path fill="#e8c305" d="m447.3,692.91c-9.57-.41-19.56-9.26-20.78-19.4-.85-24.34,34.39-16.14,35.9,4.34,1.01,8.65-4.93,15.08-15.13,15.06Z"/>
          <path fill="#e8c305" d="m358.51,758.2c.05-14.76,11.11-9.88,16.14-.85,4.4,7.47,8.15,16.82,5.29,25.48-1.44,3.21-4.04,4.19-7.2,2.61-2.94-1.47-5.07-3.83-6.91-6.48-4.44-6.38-6.89-13.45-7.33-20.76Z"/>
          <path fill="#e8c305" d="m312.62,736.02c.26,3.54-1.35,9.69-6.01,7.24-7.84-5.07-10.96-15.17-12.1-24.01-.86-7.82,3.74-12.82,9.77-5.44,5.19,6.49,7.84,13.96,8.34,22.21Z"/>
          <path fill="#e8c305" d="m419.47,804.11c-7.22-1.64,24.62-18.34,27.29-18.7,10.92-3.94,4.23,2.54-.52,5.49-8.32,5.42-17.03,10.86-26.77,13.21Z"/>
          <path fill="#e8c305" d="m490.15,772.38c-3.96.04,1.83-4.22,2.81-4.97,4.69-3.36,23.16-15.54,28.01-13.78-2.82,6.25-23.66,16.92-30.82,18.76Z"/>
          <path fill="#e8c305" d="m417.36,815.73c6.14.2-14.76,12.34-16.12,12.75-1.13.86-18.45,8.83-13.36,3.17,8.6-7.03,18.71-13.06,29.48-15.92Z"/>
          <path fill="#e8c305" d="m454.56,802.85c-5.22-1.93,22.09-15.83,24.69-16.38,9.85-3.35-1.55,5.1-4.13,6.4-6.53,3.88-13.11,8.14-20.56,9.98Z"/>
        </g>
      </g>
    </svg>
  )
}