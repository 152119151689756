export const NoFlame59 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="400.54 817.52 538.57 790.2 511.05 627.17 418.64 592.84 275.31 645.19 295.42 820.43 400.54 817.52"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m408.27,816.01c-3.82.88-7.61,1.87-11.57,1.97-34.41.86-68.82,1.62-103.22,2.56-.91.03-1.29-.22-1.39-1.19-1.11-10.51-2.26-21.02-3.41-31.53-1.2-10.98-2.4-21.95-3.6-32.93-4.01-36.73-7.93-73.47-12.01-110.2-.14-1.14,0-1.75,1.24-2.2,48.89-16.73,97.41-37.56,146.48-52.35,29.83,11.46,59.65,22.94,89.52,34.31,1.24.47,1.74,1.14,1.96,2.44,6.34,38.04,12.78,76.07,19.15,114.11,2.66,15.84,5.31,31.69,8.03,47.53.22,1.26.02,1.72-1.29,1.97-43.16,8.3-86.29,17.37-129.46,25.38,40.6-8.93,81.97-15.96,122.75-24.66-24.93-1.58-49.96-1.36-74.92-2.31-3.82-.07-7.68-.65-11.44.06-48.07,10.15-96.2,20-144.13,30.69,25.71-.86,50.69-1.16,76.29-1.87,10.4-.26,20.84-.02,31.05-1.78Zm33.8-34.29c-8.83-60.93-17.68-121.86-26.56-182.78-.31-2.14-.35-2.15-2.34-1.43-30.39,10.95-60.77,21.91-91.15,32.87-14.24,5.13-28.47,10.26-42.71,15.4-1.1.4-2.2.55-1.98,2.34,1.21,9.97,2.29,19.96,3.39,29.94,4.93,44.98,9.91,89.95,14.77,134.94.12,1.17.43,1.32,1.53,1.08,47.9-10.49,95.85-20.77,143.75-31.25.59-.13,1.49-.02,1.31-1.1Zm91.51,3.25s0,.05,0,.08c1.21-.18,3.34.69,2.84-1.38-8.62-51.21-17.24-102.41-25.74-153.63-26.55-13.15-57.53-21.56-85.53-33.12-1.69-.63-1.77-.56-1.51,1.21,6.39,42.87,12.65,85.76,18.97,128.64,2.61,17.9,5.31,35.79,7.93,53.69.12.87.44,1.1,1.28,1.13,27.26,1.12,54.51,2.32,81.77,3.38Z"/>
          <path fill="#e8c305" d="m418.14,800.4c-20.66.07,32.58-9.58,33.84-6.05-.3,1.42-6.69,2.36-8.22,2.79-8.46,1.76-16.98,2.97-25.62,3.26Z"/>
          <path fill="#e8c305" d="m443.09,800.72c-20.35-.27,30.44-8.97,31.5-6.02.32,1.67-11.39,3.16-13.21,3.79-6.05,1.06-12.15,2.26-18.29,2.18,0,.02,0,.04,0,.06Z"/>
          <path fill="#e8c305" d="m346.27,815.11c-10.88.34.94-2.75,4.27-3.14,8.67-1.57,17.45-2.92,26.29-2.73.48.02,1.23-.17,1.3.51.06.6-.69.73-1.15.85-10.01,2.79-20.34,4.05-30.7,4.5Z"/>
          <path fill="#e8c305" d="m492.96,794.53c.91.14,2.37-.4,2.71.54.09.72-.89,1-1.46,1.14-10.38,2.52-21.05,4.61-31.77,4.47-1.63.37-1.89-1-.22-1.3,9.9-3,20.53-4.23,30.74-4.86Z"/>
          <path fill="#e8c305" d="m368.37,814.34c.28-.75.79-.84,1.38-1,5.45-1.47,10.98-2.47,16.58-3.17,4.91-.61,9.82-1.32,14.79-1.22.64.01,1.67-.31,1.8.44.13.76-.94.79-1.54.97-5.36,1.59-10.87,2.45-16.4,3.18-5.5.43-11.13,1.91-16.6.8Z"/>
          <path fill="#e8c305" d="m426.18,809.22c-.68,2.05-40.97,7.64-31.34,3.58,6.85-1.7,13.79-2.86,20.82-3.53,3.56-.1,7.04-1.35,10.52-.05Z"/>
          <path fill="#e8c305" d="m382.7,672.87c-17.4-.98-18.01-26.2-6.56-35.27,27.2-18.44,33.96,33.46,6.56,35.27Z"/>
          <path fill="#e8c305" d="m309.07,763.53c-.56-14.02,16.4-27.77,26.3-12.45,11.2,15.76-8.78,45.61-23.58,24.16-1.96-3.53-2.61-7.36-2.72-11.72Z"/>
          <path fill="#e8c305" d="m478.29,744.63c.31,6-3.52,16.13-11,10.84-8.53-7.31-11.34-19.67-9.49-30.52.95-5.42,6.01-9.41,10.81-5.07,6.94,6.17,9.41,15.61,9.68,24.75Z"/>
          <path fill="#e8c305" d="m464.52,655.24c.54,10.58-6.89,15.69-13.95,6.05-5.72-8.25-8.92-18.4-6.06-28.31,1.66-4.24,5.17-5.32,8.95-2.77,2.49,1.67,4.3,3.98,5.77,6.54,3.31,5.71,5.17,11.86,5.29,18.5Z"/>
          <path fill="#e8c305" d="m502.4,736.71c-.59-5.25,2.59-15.22,9.13-9.82,7.35,6.91,9.71,18.21,8.26,27.95-.53,3.88-3.93,7.99-7.95,5.11-6.86-5.38-9.11-14.88-9.44-23.23Z"/>
          <path fill="#e8c305" d="m506.9,667.69c.26,4.76-1.72,12.91-7.74,9.39-7.78-5.91-10.42-16.67-10.19-26.07.73-10.58,8.52-8.74,12.65-1.13,3.33,5.61,4.95,11.72,5.27,17.81Z"/>
        </g>
      </g>
    </svg>
  )
}