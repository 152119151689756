export const BigFlame14 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="320.99 630.73 483.85 585.02 558.42 750.57 509.76 775.96 500.54 779.84 377.15 831.74 341.21 844.39 280.21 690.27 320.99 630.73"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m496.08,781.25l-118.93,50.49c45.31,3.3,91.08-14.07,118.93-50.49Z"/>
          <path fill="#e8c305" d="m289.16,440.04c20.08,23.9,11.59,42.07,29.4,64.04,2.39-42.14,45.93-32.3-29.4-64.04Z"/>
          <path fill="#e8c305" d="m79.02,347.63c8.91-13.16,1.23-25.03-.49-37.19-23.26,7.12-.42,22.91.49,37.19Z"/>
          <path fill="#e8c305" d="m558.59,748.76c-10.84-24.52-59.35-134.87-72.79-165.51-.53-1.21-1.02-1.59-2.41-1.2-4.79,1.33-19.97,5.52-39.26,10.84-37.93-27.17-90.46-8.95-128.27-37.98-.03,9.1,3.03,16.59,5.66,24.58-32.88-7.55-70.41.97-76.57-44.06-2.3-25.93,1.02-50.72,9.03-75.2,4.06-12.4,7.06-25.01,5.62-38.23-1.17-10.75-3.8-20.73-12.36-28.52-19.23-19.89-38.01-38.66-50.17-64.87-5.4,33.31-27.43,56.37-40.1,86.04-17.93,36.53,7.33,76.23,42.72,89.64,18.76,8.83,37.41,24.03,42.84,44.36-1.69,14.15-.72,28.29.82,42.34,8.21,52.99,5.36,109.22,33.09,156.76,9.4,32.49,21.06,52.68,52.52,67.31,6.29,15.97,10.78,27.36,11.37,28.85.22.56.26,1.4,1.35.93,3.55-1.54,7.24-2.73,10.72-4.41-.25-.04-.66-.02-.71-.14-.21-.5.3-.56.58-.71,3.21-1.77,30.73-16.9,41.25-22.74,6.21-3.45,162.85-65.37,164.22-65.88,1.21-.45,1.36-.97.82-2.19Zm-170.13,63.21c-12.15,6.99-43.08,24.95-46.42,26.89-1.77,1.03-1.79,1.05-2.52-.81-10.62-26.92-48.42-122.84-56.75-143.86-.52-1.3-.46-2.26.32-3.46,5.26-8.19,28.09-44.39,34.31-54.24.29-.47.49-1.05,1.14-1.2.5.23.58.73.75,1.15,3.55,8.75,64.38,159.04,70.06,172.94.52,1.28.32,1.91-.89,2.6Zm162.97-64.69c-5.6,2.22-107.4,42.14-152.67,59.95-1.53.6-2,.26-2.57-1.14-21.89-53.94-68.85-169.57-70.49-173.42-.5-1.17-.29-1.6.94-1.94,22.06-6.08,124.39-34.45,153.5-42.52.35-.1.73-.15,1.06-.3,1.14-.53,1.7-.18,2.21,1,11.46,26.19,65.73,149.8,68.65,156.46.15.34.29.67.44,1.01.05.78-.65.74-1.07.91Z"/>
          <path fill="#e8c305" d="m405,814.14c11.93-3.93,23.47-8.78,34.66-14.47-11.92,3.94-23.51,8.68-34.66,14.47Z"/>
          <path fill="#e8c305" d="m513.88,770.1c-11.25,3.76-22.13,8.41-32.72,13.8,11.23-3.81,22.18-8.31,32.72-13.8Z"/>
          <path fill="#e8c305" d="m410.01,815.41c-11.09,3.69-21.84,8.2-32.31,13.4,11.07-3.74,21.82-8.25,32.31-13.4Z"/>
          <path fill="#e8c305" d="m478.92,787.77c-9.91,3.43-19.57,7.44-28.93,12.16,9.89-3.47,19.58-7.42,28.93-12.16Z"/>
          <path fill="#e8c305" d="m444.77,672.75c-9.48-2.63-18.9,3.11-20.46,12.6-1.23,7.47,1.4,13.71,6.76,18.81,3.86,3.68,8.54,5.58,13.83,5.57,1.1-.1,2.11-.09,3.07-.29,8.04-1.7,13.4-8.88,12.83-17.11-.65-9.31-7-17.07-16.02-19.58Z"/>
          <path fill="#e8c305" d="m355.13,758.84c-2.17-1.33-3.84-.74-4.62,1.67-.5,1.55-.71,3.17-.59,4.8.61,8.34,3.24,16.05,7.52,23.2,1.37,2.29,2.93,4.47,5.24,5.96,2.13,1.38,3.98.71,4.76-1.71.46-1.42.42-2.88.55-4.71-.63-8.75-3.51-17.11-8.54-24.72-1.16-1.75-2.49-3.37-4.32-4.49Z"/>
          <path fill="#e8c305" d="m301.71,701.28c-1.96-1.43-3.25-.97-3.88,1.4-.44,1.63-.41,3.29-.25,4.97.77,8.11,3.33,15.6,7.71,22.46.73,1.14,1.59,2.21,2.74,2.99,1.89,1.29,3.07.87,3.72-1.34.34-1.17.42-2.36.41-3.58-.48-7.9-2.77-15.28-6.65-22.16-1-1.78-2.1-3.51-3.79-4.74Z"/>
        </g>
      </g>
    </svg>
  )
}