export const BigFlame12 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="319.2 622.47 469.27 579.73 561.65 727.8 482.23 794.34 350.28 856.18 274.9 710.77 319.2 622.47"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m72.43,350.97c5.2,5.19,10.8,10.04,14.11,18.35,1.24-21.44-9.49-38.02-14.93-56.24-14.63,9.92-10.35,27.54.82,37.89Z"/>
          <path fill="#e8c305" d="m562.52,726.29c-18.4-29.63-36.33-59.87-54.46-89.84-1.66-3.54-3.61-6.87-6.01-9.92-10.28-16.91-20.64-33.7-31.22-50.21-5.1,1.44-10.2,2.9-15.3,4.35-6.3-8.9-11.56-17.88-15.89-28.25-4.52,9.34-5.91,18.34-7.64,27.72-13.79-5.12-27.63-5.42-41.52-9.27-20.22-7.22-20.36-7.05-28-26.99-13.57-28.29-3.88-58.97-8.23-88.65-2.11-25.18-26.04-36.97-48.73-39.69-26.51-4.79-53.12-9.14-78.45-18.84-.71-.27-1.57-.65-2.29.57,18.26,31.46,48.72,53.54,76.14,76.49,15.38,13.84,12.69,37.11,13.05,55.89-.02.7-.26,1.28-1.34,1.49-12.03-10.38-31.29-19.24-32.02-37.25.02-1.95-.69-3.92-1.18-5.85-16.08-74.42-96.21-44.04-103.69-135.66-33.57,62.35-51.67,126.76,33.3,153,7.48,2.36,18.02,3.35,21.84,11.15,18.63,48.62,27.13,100.5,37.83,151.29,2.02,10.39-2.09,20.22-.87,31.45-5.31-3.75-8.89-7.84-15.2-8.91,23.66,51.75,16.54,79.26,69.91,114.25,8.74,16.93,17.48,33.87,26.2,50.81.62,1.2,1.23,1.57,2.4.87,18.93-8.88,37.86-17.75,56.79-26.62,25.85-1.46,64.94-20.11,73.33-34.61,3.7-2.13,7.19-4.59,10.3-7.72,23.52-19.64,47.04-39.28,70.58-58.9.86-.72.99-1.17.38-2.14Zm-212.51,123.43c-.99,1.01-1.56.91-2.15-.19-23.53-45.98-47.26-91.89-71.09-137.74-.56-1.07-.63-1.91-.05-3.03,10.32-19.89,20.59-39.81,30.87-59.72,3.71-7.29,6.99-14.14,11.12-21.38,29.5,53.9,58.76,107.76,88.24,161.65.68,1.24.53,1.94-.45,2.92-18.91,19.09-37.73,38.27-56.5,57.49Zm131.87-55.9c-8.59,4.12-17.12,8.2-25.66,12.3-33.01,15.66-66.27,31.17-99.31,46.29,18.34-19.18,39.32-39.16,58.04-58.14,45.97-22.86,93.15-40.66,140.05-61.77-23.77,20.67-48.86,40.85-73.12,61.32Zm73.15-61.34h0c.12-.02.25-.05.37-.07-.12.02-.25.05-.37.07Zm.44-7.5c-6.25,2.71-12.48,5.44-18.72,8.17-40.14,17.59-80.32,35.11-120.44,52.76-1.18.52-1.68.3-2.29-.81-29.54-54-59.28-107.89-88.81-161.9,47.85-13.87,95.61-27.62,143.44-41.49,30.02,42.81,56.03,92.47,84.72,137.52.36,1.19,4.48,5.1,2.09,5.75Z"/>
          <path fill="#e8c305" d="m426.52,673.51c1.21,10.14,11.21,18.99,20.78,19.4,10.19.02,16.13-6.41,15.13-15.06-1.51-20.48-36.75-28.68-35.9-4.34Z"/>
          <path fill="#e8c305" d="m358.51,758.2c.44,7.31,2.88,14.38,7.33,20.76,1.85,2.65,3.97,5.01,6.91,6.48,3.16,1.58,5.76.59,7.2-2.61,2.85-8.65-.9-18.01-5.29-25.48-5.03-9.04-16.09-13.91-16.14.85Z"/>
          <path fill="#e8c305" d="m304.28,713.81c-6.03-7.38-10.63-2.38-9.77,5.44,1.14,8.83,4.27,18.94,12.1,24.01,4.65,2.45,6.27-3.7,6.01-7.24-.5-8.25-3.15-15.72-8.34-22.21Z"/>
          <path fill="#e8c305" d="m446.76,785.41c-2.67.35-34.51,17.06-27.29,18.7,9.74-2.35,18.45-7.79,26.77-13.21,4.75-2.94,11.45-9.42.52-5.49Z"/>
          <path fill="#e8c305" d="m492.96,767.41c-.97.75-6.77,5.01-2.81,4.97,7.16-1.84,27.99-12.5,30.82-18.76-4.85-1.76-23.32,10.42-28.01,13.78Z"/>
          <path fill="#e8c305" d="m417.36,815.73c-10.77,2.86-20.88,8.89-29.48,15.92-5.09,5.66,12.23-2.31,13.36-3.17,1.37-.4,22.27-12.55,16.12-12.75Z"/>
          <path fill="#e8c305" d="m479.25,786.47c-2.6.55-29.92,14.45-24.69,16.38,7.46-1.83,14.04-6.09,20.56-9.98,2.58-1.3,13.98-9.75,4.13-6.4Z"/>
        </g>
      </g>
    </svg>
  )
}