export const CashierLocationIcon = (props) => {
  return (
    <svg  fill="none" xmlns="http://www.w3.org/2000/svg" 
      width={props?.w ?? "99"} 
      height={props?.h ?? "97"} 
      viewBox={props?.v ?? "0 0 99 97"}
    >
      <g opacity="0.5" filter="url(#filter0_d_1044_920)">
      <ellipse cx="37.4654" cy="32.5" rx="27.0621" ry="26.5" fill="#284F91"/>
      </g>
      <g filter="url(#filter1_d_1044_920)">
      <ellipse cx="37.471" cy="32.5049" rx="20.6985" ry="20.2686" fill="#4C7FD4"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.4655 20C32.6665 20 28.7852 24.019 28.7852 28.9882C28.7852 34.3426 34.2661 41.7257 36.523 44.5378C37.019 45.1541 37.9243 45.1541 38.4203 44.5378C40.6648 41.7257 46.1458 34.3426 46.1458 28.9882C46.1458 24.019 42.2644 20 37.4655 20ZM37.4655 32.1983C35.7542 32.1983 34.3654 30.7601 34.3654 28.9882C34.3654 27.2162 35.7542 25.7781 37.4655 25.7781C39.1767 25.7781 40.5656 27.2162 40.5656 28.9882C40.5656 30.7601 39.1767 32.1983 37.4655 32.1983Z" fill="#C5C5C5"/>
      <defs>
      <filter id="filter0_d_1044_920" x="0.40332" y="0" width="98.124" height="97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_920"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_920" result="shape"/>
      </filter>
      <filter id="filter1_d_1044_920" x="6.77246" y="6.23633" width="85.3975" height="84.5371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1044_920"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1044_920" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}