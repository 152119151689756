export const SmallFlame20 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="300.06 648.48 453.62 596.89 480.54 623.4 532.72 800.45 357.37 860.32 291.16 683.62 300.06 648.48"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m526.63,766c39.97-45.85,54.14-124.71-1.58-164.36-12.85-9.13-22.48-22.65-37.52-28.57-28.89-13.84-37.49-47.48-55.8-70.49-12.66,14.82-27.65,28.28-27.43,50.55-81.74,3-55.75,44.02-98.36,78.74-27.8-18.1-55.67-35.87-87.22-49.59,36.73,57.81,1.91,107.4,14.04,163.64,2.19,7.67,3.62,15.4,4.7,23.34,5.97,57.5,62.82,86.18,115.75,84.59.93,2.43,1.78,4.66,2.53,6.63.55,1.45,1.06,1.81,2.62,1.27,4.18-1.45,19.96-6.84,40.85-13.97,12.49-3.1,24.66-7.15,36.4-12.4,36.47-12.42,76.76-26.14,99.61-33.93,2.02-.69,2.03-.69,1.43-2.66-1.04-3.42-4.86-15.89-10.02-32.8Zm-231.26-84.09c.58-2.55,5.71-24.23,7.63-32.53.16-.68.42-1.16.82-1.52,32.91-11.15,65.63-22.24,98.35-33.33,20.07-6.76,38.04-12.81,48.05-16.2,1.43-.48,2.28-.36,3.36.82,3.04,3.32,17.37,17.73,21.32,21.77,1.21,1.24,1.21,1.24-.46,1.81-19.74,6.66-171.27,57.79-177.37,59.86-.4.13-.8.26-1.2.38-.95.01-.62-.6-.51-1.07Zm233.96,116.09c-4.75,1.64-116.26,39.62-167.26,56.98-.3.1-.62.16-.89.31-1.14.6-1.61.12-2.02-.99-6.5-17.18-47.72-125.6-61.86-162.61-.62-1.63-.13-1.98,1.26-2.45,38.61-12.99,157.3-53.02,178.04-60.02q2-.68,2.63,1.36c15.25,49.57,49.19,159.91,50.91,165.51.11.35.21.7.33,1.11-.04.67-.69.64-1.13.79Z"/>
          <path fill="#e8c305" d="m431.03,731.16c-9.17-7.64-22.59-6.07-29.75,3.49-4.58,6.13-5,14.23-1.05,20.63,3.76,6.1,9.42,8.79,16.39,9.12,6.16.24,12.67-2.96,16.83-8.28,5.83-7.47,4.71-19.02-2.42-24.97Z"/>
          <path fill="#e8c305" d="m409.57,636.18c1.06.24,2.15.4,3.27.29,4.6-.48,9.07-1.51,13.46-2.93,5.88-1.89,11.73-3.88,16.92-7.34.93-.62,2.38-1.27,2.09-2.45-.37-1.56-2.06-.98-3.25-1.05-.58,0-1.16-.07-1.72.01-9.77,1.41-19.01,4.44-27.72,9.08-1.34.71-2.59,1.57-3.56,2.78-.68.85-.36,1.41.53,1.61Z"/>
          <path fill="#e8c305" d="m367.01,642.9c3.13-.1,6.14-.81,9.11-1.62,7.08-1.94,14.01-4.28,20.29-8.19,1.03-.65,2.74-1.42,2.26-2.63-.45-1.14-2.26-.86-3.57-.73-3.82.38-7.52,1.28-11.17,2.4-6.2,1.92-12.32,4.07-17.82,7.64-.9.58-2.24,1.26-1.9,2.4.35,1.17,1.83.58,2.79.74Z"/>
          <path fill="#e8c305" d="m325.08,648.77c.31.03.63,0,1.36,0,.1-.01.63-.04,1.14-.12,8.54-1.38,16.61-4.21,24.33-8.06,1.49-.74,2.98-1.54,4.15-2.78.31-.33.83-.61.58-1.17-.23-.52-.79-.47-1.24-.47-1.2.01-2.43-.05-3.6.16-8.04,1.42-15.67,4.1-22.98,7.69-1.59.78-3.17,1.63-4.45,2.9-.34.34-.74.68-.59,1.2.18.62.78.6,1.3.65Z"/>
        </g>
      </g>
    </svg>
  )
}