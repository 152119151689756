export const SmallFlame08 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="307.57 619.26 433.1 578.98 557.49 679.98 495.11 797.42 383.9 868.4 270.41 752.05 307.57 619.26"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m374.25,737.86c.23,5.38,2.31,11.53,5.6,16.16,2.5,3.52,5.48,6.45,9.5,8.18,15.62,6.28,17.49-15.68,10.82-25.04-6.34-13.13-24.97-18.36-25.92.7Z"/>
          <path fill="#e8c305" d="m303.83,733.23c-13.36-1.41-8.95,19.41-3.55,25.74,3.2,5.42,13.05,12.43,17.43,4.64.99-2.12,1.47-4.64,1.68-6.92-.26-6.25-2.4-11.83-6.19-16.78-2.43-3.17-5.32-5.77-9.37-6.68Z"/>
          <path fill="#e8c305" d="m446.13,641.89c-8.29-6.85-34.72-8.02-25.01,8.64,5.83,7.36,13.7,10.5,22.8,11.2,15.99-.27,12.9-13.86,2.2-19.84Z"/>
          <path fill="#e8c305" d="m466.48,745.21c-9.49,5.6-18.55,14.98-21.37,25.65-.28,6.56,6.04,2.92,9.03,1.19,6.46-4.2,11.61-9.77,15.63-16.29,3.05-5.01,7.19-15.57-3.29-10.55Z"/>
          <path fill="#e8c305" d="m435.63,808.11c-6.68,4.41-12.16,10.08-16.26,16.96-3.1,5.94-4.56,11.69,4.34,7.19,9.17-5.54,16.11-13.97,19.95-24,.07-4.77-5.85-1.36-8.03-.14Z"/>
          <path fill="#e8c305" d="m519.18,716.43c-5.75,4.09-10.44,9.23-14.05,15.26-1.5,2.52-2.52,5.37-3.42,8.18-.55,1.7.56,2.8,3.01,2.79,12.18-2.9,34.65-38.2,14.46-26.23Z"/>
          <path fill="#e8c305" d="m473.61,792.87c-2.65,4.61-2.53,9.4,3.84,5.67,8.56-5.44,15.37-13.34,19.08-22.78-1.83-9.69-21.89,13.18-22.93,17.11Z"/>
          <path fill="#e8c305" d="m353.21,479.21c-6.95-23,4.38-31.59-21.45-47.18-3.52,28.58-12.34,37.72,21.45,47.18Z"/>
          <path fill="#e8c305" d="m537.8,719.8c7.36-13.6,14.72-27.21,22.02-40.86-7.56-6.12-15.14-12.22-22.72-18.31-5.57-24.88-32.87-68.66-53.99-79.6-39.56-21.03-69.1-63.77-116.59-66.16.97,33.41-40.96,45.94-67.27,33.02-19.58-9.42-33.59-25.28-48.03-41.06-36.57,39.93-67.6,114.11-18.02,155.5,8.19,6.21,14.98,14.1,19.67,23.21-23.8,86.75,38.58,150.58,103.33,156.56,8.93,8.8,17.71,17.69,26.14,26.78,1.2,1.23,1.95,1.42,3.46.44,24.6-16.36,49.97-31.9,74.83-47.99,7.38-3.8,14.21-8.48,20.72-13.68,4.84-3.27,9.65-6.57,14.42-9.93,1.54-2.88,3.09-5.75,4.63-8.62.23.12.46.25.69.37,17.77-19.87,30.8-42.28,36.73-69.68Zm16.54-35.06h0s.07-.04.11-.05c-.04.02-.07.04-.11.05Zm-123.43-103.13c1.73-.56,2.89-.4,4.35.78,39.14,31.68,78.49,63.09,117.63,94.78-36.58,19.14-73.31,37.92-109.91,57.01-.74.4-1.23.42-1.91-.19-42.58-38.29-85.24-76.46-127.75-114.82,39.32-12.55,78.34-24.97,117.6-37.56Zm-49.05,281.51c-.22.14-.45.27-.67.41-34.92-37.23-73.26-74.42-108.84-111.14,10.11-41.86,25.13-84.58,35.53-127.36,42.56,38.13,84.68,76.79,127.14,115.05.85.71.86,1.34.43,2.3-17.93,40.21-35.64,80.53-53.59,120.74Zm112.76-66.77c-33.9,23.96-70.27,45.27-105.15,68.09,17.65-40.37,36.2-80.39,54.52-120.48,35.72-20.94,73.61-39.45,110.27-59.14-19.25,37.42-39.81,74.35-59.64,111.53Z"/>
          <path fill="#e8c305" d="m150.42,461.94c6.29,6.88,11.51,14.78,19.58,19.73,8.93-9.42-12.52-39.51-19.58-19.73Z"/>
        </g>
      </g>
    </svg>
  )
}