export const NoFlame14 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="321.01 630.55 483.87 584.84 558.45 750.39 499.41 783 341.13 844.79 280.23 690.1 321.01 630.55"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m352.43,840.43c-3.49,1.68-7.18,2.87-10.72,4.41-1.09.47-1.13-.36-1.35-.93-2.22-5.59-59.4-150.91-60.38-153.23-.34-.82-.27-1.43.19-2.17,10.12-16.4,20.23-32.8,30.33-49.21,1.95-3.17,4-6.28,5.8-9.52.95-1.7,2.2-2.5,4.04-2.92,3.05-.7,146.34-40.17,163.05-44.81,1.39-.39,1.88,0,2.41,1.2,13.43,30.64,61.95,140.99,72.79,165.51.54,1.21.39,1.74-.82,2.19-1.37.51-158.01,62.43-164.22,65.88-10.52,5.84-38.04,20.97-41.25,22.74-.28.15-.8.21-.58.71.05.13.46.1.71.14Zm200.08-94.06c-.15-.34-.29-.68-.44-1.01-2.92-6.66-57.19-130.27-68.65-156.46-.52-1.18-1.07-1.53-2.21-1-.33.15-.7.2-1.06.3-29.11,8.07-131.44,36.45-153.5,42.52-1.23.34-1.44.76-.94,1.94,1.64,3.85,48.6,119.48,70.49,173.43.57,1.4,1.03,1.75,2.57,1.14,45.27-17.81,147.07-57.73,152.67-59.95.42-.17,1.12-.13,1.07-.91Zm-233.97-111.1c-.64.15-.84.73-1.14,1.2-6.23,9.86-29.05,46.06-34.31,54.24-.77,1.2-.83,2.16-.32,3.46,8.33,21.02,46.13,116.94,56.75,143.86.73,1.86.75,1.85,2.52.81,3.34-1.94,34.27-19.9,46.42-26.89,1.2-.69,1.41-1.32.88-2.6-5.68-13.9-66.51-164.19-70.06-172.94-.17-.42-.24-.92-.75-1.15Z"/>
          <path fill="#e8c305" d="m405,814.14c11.15-5.79,22.74-10.52,34.66-14.47-11.2,5.68-22.74,10.54-34.66,14.47Z"/>
          <path fill="#e8c305" d="m481.16,783.9c10.59-5.39,21.46-10.04,32.72-13.8-10.53,5.5-21.49,9.99-32.72,13.8Z"/>
          <path fill="#e8c305" d="m410.01,815.41c-10.49,5.15-21.24,9.66-32.31,13.4,10.47-5.2,21.22-9.71,32.31-13.4Z"/>
          <path fill="#e8c305" d="m478.92,787.77c-9.35,4.74-19.04,8.7-28.93,12.16,9.36-4.73,19.02-8.74,28.93-12.16Z"/>
          <path fill="#e8c305" d="m444.89,709.73c-5.29,0-9.97-1.89-13.83-5.57-5.36-5.1-7.99-11.34-6.76-18.81,1.56-9.49,10.99-15.24,20.46-12.6,9.02,2.51,15.37,10.27,16.02,19.58.58,8.23-4.79,15.42-12.83,17.11-.96.2-1.98.19-3.07.29Z"/>
          <path fill="#e8c305" d="m367.98,788.05c-.12,1.83-.09,3.3-.55,4.71-.78,2.41-2.63,3.09-4.76,1.71-2.3-1.49-3.87-3.68-5.24-5.96-4.28-7.15-6.91-14.85-7.52-23.2-.12-1.63.09-3.25.59-4.8.78-2.42,2.45-3,4.62-1.67,1.83,1.12,3.16,2.74,4.32,4.49,5.03,7.61,7.9,15.97,8.54,24.72Z"/>
          <path fill="#e8c305" d="m312.15,728.19c.02,1.22-.07,2.42-.41,3.58-.65,2.21-1.83,2.63-3.72,1.34-1.15-.78-2.01-1.85-2.74-2.99-4.38-6.86-6.94-14.35-7.71-22.46-.16-1.68-.18-3.34.25-4.97.63-2.38,1.92-2.84,3.88-1.4,1.69,1.23,2.78,2.96,3.79,4.74,3.89,6.89,6.17,14.26,6.65,22.16Z"/>
        </g>
      </g>
    </svg>
  )
}