export const BigFlame02 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="285.84 625.56 389.68 623.02 529.67 632.85 520.48 799.2 433.68 837.61 283.37 803.81 285.84 625.56"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m545.04,606.45c-27.71-17.65-16.38-52.5-54.07-56.03-27.64-1.95-47.45-16.59-63.24-37.6,0,7.76-.13,15.4.05,23.03.09,3.66-.98,4.2-4.34,2.95-39.96-14.8-79.94-27.19-120.81-39.59,16.86,43.29.9,87.08-18.7,126.15-.18,0-.34.01-.5.01,0,.34-.01.68-.02,1.01-1.65,3.27-3.32,6.5-4.99,9.69-1.12,2.19-2.23,2.3-4.29,1.32-25.25-11.83-49.38-25.63-69.4-45.46,11.45,40.45,27.53,82.74,16.16,125.13-5.21,16.3,1.6,32.48,1.93,48.9,10.61,85.13,118.4,89.5,184.78,68.38,9.21,2.09,17.37,3.94,23.74,5.39,1.07.24,2.42.08,3.44-.36,28.43-12.49,56.82-25.06,85.24-37.56,1.35-.6,1.81-1.31,1.89-2.75.21-3.87.92-16.18,1.86-32.44,44.26-37.86,77.49-118.05,21.28-160.17Zm-144.49,16.12c42.11,3.12,84.21,6.34,126.32,9.39,1.09.08,1.89.25,2.46.58-2.17-.15-4.33-.28-6.5-.41-1.18.25-73.02,4.78-80.96,5.06-3.8.14-7.63-.3-11.44-.58-6-.44-134.05-10.63-135.11-10.71,0,0,0-.01,0-.02,9.19-.28,18.37-.71,27.56-1.18,25.76-.72,61.1-1.69,66.83-1.86.25,0,.49-.11.74-.16-1.04-.04-2.09-.15-3.13-.12-15.65.41-31.31.83-46.96,1.25,20.06-1.01,40.12-1.84,60.2-1.24Zm27.67,210.84c-47.89-10.7-95.59-21.37-143.48-32.07.88-56.74,1.77-113.43,2.65-170.38,49.65,4.19,99.12,8.36,148.87,12.55-2.68,63.29-5.35,126.46-8.04,189.9Zm91.86-38.7c.05,1.95-.73,2.81-2.46,3.55-21.04,8.99-80.57,34.64-80.85,34.76,0-2.86,7.42-177.48,7.82-186.79.11-2.52.1-2.47,2.63-2.65,12.9-.9,81.21-5.65,82.02-5.65-.27,5-9.22,154.12-9.16,156.78Z"/>
          <path fill="#e8c305" d="m430.13,630.13c-3.96-1.79-33.38-2.91-36.23-1.37,12.1,1.3,23.93,1.91,36.23,1.37Z"/>
          <path fill="#e8c305" d="m386.12,703.38c6.93,5.52,15.58,4.64,21.14-2.27,3.07-3.82,4.3-8.31,4.27-13.2-.11-6.73-2.25-12.64-7.37-17.19-6.48-5.77-15.99-5.56-21.71,1.82-6.86,8.85-5.09,23.85,3.68,30.84Z"/>
          <path fill="#e8c305" d="m311.57,781.74c6.9,5.71,15.61,3.81,19.47-4.26,4.15-8.65,1.16-20.9-6.5-26.67-6.86-5.17-15.28-3.18-18.82,4.64-1.14,2.51-2.01,8.13-1.86,8.17-.49,6.53,2.67,13.95,7.71,18.12Z"/>
          <path fill="#e8c305" d="m472.81,671.12c-2.39-3.52-5.9-4.09-9.1-1.3-1.35,1.18-2.51,2.69-3.4,4.25-3,5.27-4.03,11.06-4.24,17.06.15,1.77.21,3.55.48,5.3.49,3.18,1.33,6.25,3.43,8.81,2.07,2.53,4.93,3.02,7.66,1.24,2.36-1.54,3.83-3.84,5-6.32,2.82-5.96,3.75-12.27,3.27-18.8-.27-3.63-1.01-7.16-3.11-10.25Z"/>
          <path fill="#e8c305" d="m467.91,761.96c-1.89-1.97-4.39-2.43-6.49-.75-1.84,1.47-3.61,3.23-4.83,5.22-3.69,6.01-4.88,12.75-4.71,19.71.09,3.57.67,7.1,2.78,10.15,1.79,2.59,3.98,3.33,6.77,1.95,1.36-.67,2.66-1.71,3.6-2.9,4.82-6.04,6.17-13.24,6.61-20.63-.13-1.6-.18-3.12-.4-4.61-.45-2.97-1.16-5.87-3.33-8.14Z"/>
          <path fill="#e8c305" d="m514.11,665.17c-1.84-2.82-5.02-3.01-7.2-.77-1.25,1.29-2.43,2.8-3.19,4.42-3.46,7.4-4.26,15.16-2.44,23.11.41,1.78,1.31,3.55,2.36,5.07,1.63,2.36,4.22,2.59,6.4.72,1.02-.87,1.96-1.92,2.66-3.06,3.16-5.21,4.19-10.99,4.16-16.99,0-1.26.1-2.52-.02-3.76-.31-3.09-.98-6.08-2.72-8.75Z"/>
          <path fill="#e8c305" d="m509.38,748.96c-1.49-1.82-3.71-2.28-5.54-.79-1.43,1.16-2.81,2.57-3.71,4.15-3.81,6.66-5.01,13.93-4.08,21.49.27,2.22,1.05,4.5,2.11,6.48,1.52,2.84,4.48,3.19,6.84.94,1.33-1.28,2.51-2.82,3.39-4.45,2.72-5.07,3.8-10.59,3.74-16.32,0-1.2.12-2.42-.02-3.6-.33-2.82-.85-5.6-2.72-7.89Z"/>
        </g>
      </g>
    </svg>
  )
}