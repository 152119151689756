export const NoFlame46 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="342.52 624.57 293.8 777.51 305.33 783.73 487.21 838.22 540.31 657.54 362 595.97 342.52 624.57"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <g>
            <path fill="#e8c305" d="m361.82,595.46c.35-1.22,1.14-.58,1.76-.37,7.78,2.66,15.56,5.34,23.33,8.02,19.71,6.8,39.41,13.61,59.12,20.42,31.69,10.94,63.38,21.89,95.08,32.8,1.06.37,1.36.72,1.03,1.87-9.83,33.76-19.62,67.53-29.41,101.3-7.53,25.98-15.06,51.97-22.58,77.95-.74,2.55-.73,2.55-3.26,1.8-43.31-12.91-86.63-25.83-129.95-38.74-17.97-5.36-35.93-10.72-53.9-16.08-.29-.09-.57-.23-.86-.35.18-.6.34-1.2.53-1.8,7.95-25.09,15.92-50.17,23.86-75.26,9.95-31.43,19.88-62.86,29.81-94.29,1.78-5.63,3.5-11.28,5.24-16.93,0,0,0,.05,0,.05.07-.13.14-.26.21-.39Zm174.07,65.26c.23-.94-.8-.85-1.4-1.06-55.5-19.11-111-38.19-166.5-57.28-1.96-.67-1.96-.67-2.59,1.33-9.77,30.94-19.53,61.89-29.3,92.83-8.67,27.45-17.33,54.9-26.03,82.34-.37,1.17-.2,1.58,1.02,1.93,4.99,1.42,9.93,2.97,14.9,4.45,52.61,15.76,105.22,31.51,157.82,47.27,1.98.59,1.97.6,2.59-1.52,15.44-53.11,30.87-106.23,46.31-159.34,1.04-3.57,2.07-7.14,3.18-10.94Z"/>
            <path fill="#e8c305" d="m361.82,595.46c-.07.13-.14.26-.21.39.07-.13.14-.26.21-.39Z"/>
            <path fill="#e8c305" d="m367.89,769.39c-11.23-.25-19.92-8.97-19.77-20.1.15-11.13,8.5-19.86,19.73-20.05,11-.19,20.09,8.76,20.2,19.57.11,11.55-8.66,20.51-20.15,20.59Z"/>
            <path fill="#e8c305" d="m412.71,783.13c-10.73-.27-19.22-8.27-19.53-19.33-.31-11.01,8.43-20.29,19.06-20.51,10.82-.22,19.92,8.5,20.09,19.26.18,11.41-8.39,20.4-19.61,20.58Z"/>
            <path fill="#e8c305" d="m395.69,639.8c11.11.18,20.14,9.48,19.73,20.84-.36,10.01-8.2,18.61-19.06,18.62-11.52.01-20.3-9.18-20.11-20.32.2-11.43,8.96-19.36,19.44-19.14Z"/>
            <path fill="#e8c305" d="m437.4,777.22c-.03-11.03,8.43-20.09,18.82-20.14,10.87-.06,19.62,8.64,19.46,19.72-.16,11.21-8.43,19.74-19.04,19.71-11.23-.03-19.02-8.72-19.25-19.28Z"/>
            <path fill="#e8c305" d="m439.64,654.59c10.41.27,19.53,8.58,19.29,20.39-.22,10.54-8.27,18.67-18.71,18.73-10.86.07-19.7-9-19.7-19.87,0-10.89,8.31-19.25,19.12-19.25Z"/>
            <path fill="#e8c305" d="m501.57,688.97c-.04,10.38-8.41,19.02-18.32,18.91-10.61-.11-19.41-9.01-19.35-19.54.07-10.95,8.2-19.42,18.74-19.23,10.77.19,19,9.12,18.93,19.86Z"/>
          </g>
          <polygon points="297.04 767 293.62 778.08 302.17 784.09 303.63 779.34 295.38 775.85 302.46 749.94 297.04 767"/>
          <path fill="#e8c305" d="m337.8,638.84l5.26-13.44s.08-.87.82-1.65,16.16-21.76,16.16-21.76l1.77-6.52s-17.99,26.55-18.85,27.9-.94,2.17-1.29,3.28-3.88,12.19-3.88,12.19Z"/>
        </g>
      </g>
    </svg>
  )
}