export const i18nEn = {
    securityTextTitle: "Bar Dice 100 is Super Secure!",
    securityTextItem1: "Top-tier security.",
    securityTextItem2: "Robust encryption.",
    securityTextItem3: "Multi-factor auth.",
    phoneEntryTitle: "We will text you a code",
    phoneEntryPlaceholder: "Enter your phone number",
    codeEntryTitle: "We texted you a code",
    codeEntryGreyText: "Enter the 4 digit code sent via SMS",
    playButtonLabel: "PLAY",
    playButtonLabelAlt: "Didn't receive an SMS? Click to resend code in "
  };
  

export const i18nPt = {
  securityTextTitle: "Jogo do Dado é Super Seguro!",
  securityTextItem1: "Segurança de alto nível.",
  securityTextItem2: "Criptografia robusta.",
  securityTextItem3: "Autenticação multifator.",
  phoneEntryTitle: "Nós lhe enviaremos um código",
  phoneEntryPlaceholder: "Digite o número de telefone",
  codeEntryTitle: "Verifique seu texto em busca de código",
  codeEntryGreyText: "Digite os 4 dígitos enviados para SMS",
  playButtonLabel: "JOGAR AGORA",
  playButtonLabelAlt: "Não recebeu um SMS? Clique para reenviar o código em "
}