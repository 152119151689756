export const NoFlame04 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>  
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="290.91 624 398.69 601.34 540.1 641.06 512.64 796.68 419.54 856.14 277.79 789.47 290.91 624"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m276.44,791.05c3.98-55.66,8.11-111.06,11.92-166.69.1-1.71.61-2.37,2.35-2.71,35.62-6.85,71.19-13.92,106.8-20.81,47.57,11.61,94.75,26.61,142.2,39.26,2.42.68,1.32,2.17,1.18,3.15-.73,5.09-1.68,10.15-2.6,15.21-8.36,46.37-16.75,92.73-25.04,139.11-.17,1.01-.55,1.67-1.44,2.24-31.05,18.45-61.05,42.39-92.65,58.14-47.12-21.65-94.26-43.26-141.42-64.81-1.07-.49-1.64-1.04-1.3-2.1Zm261.56-144.37c-29.18,9.7-58.06,20.49-87.15,30.52-.92.32-1.43.7-1.57,1.8-8.1,57.53-16.59,114.9-24.62,172.46,28.64-19.1,59.83-36.05,86.99-56.54,8.9-49.43,17.9-98.89,26.54-148.35-.06.03-.13.07-.19.1Zm-121.46,204.78c8.19-57.55,15.98-115.17,24.17-172.73.2-1.41-.22-1.77-1.41-2.16-49.06-15.88-97.99-32.04-147.06-47.88-3.81,52.96-7.73,105.89-11.74,158.82-.13,1.6.3,2.36,1.81,3.04,44.78,20.25,89.46,40.66,134.24,60.9Zm-119.52-228.92c0,.12,0,.23,0,.35,49.79,15.23,99.38,33.44,149.37,46.94,29.36-9.74,58.58-19.81,87.99-29.4-44.94-12.93-90.5-26.68-135.88-38.01-33.84,6.61-67.63,13.48-101.47,20.12Z"/>
          <path fill="#e8c305" d="m538,646.68c.06-.03.13-.07.19-.1-.03.06-.07.12-.1.19,0,0-.09-.08-.09-.08Z"/>
          <path fill="#e8c305" d="m394.25,688.86c24.07,2.17,24.85,48.42-2.4,38-15.42-6.79-18.29-37.46,2.4-38Z"/>
          <path fill="#e8c305" d="m329.27,769.43c-1.27,22.57-24.53,14.19-27.66-2.74-3.52-10.25,4.68-24.23,16.25-17.49,7.66,4.69,10.85,11.84,11.4,20.24Z"/>
          <path fill="#e8c305" d="m456.7,722.59c.45-9.59,2.97-17.33,8.15-24.11,2.31-3.29,8.07-7.6,11.35-3.18,5.22,9.73-.23,23.56-6.55,31.69-6.96,7.85-12.04,5.23-12.95-4.39Z"/>
          <path fill="#e8c305" d="m443.84,807.61c.86-10.09,3.91-18.59,10.63-25.52,7.84-7.6,11.85.71,10.32,8.52-1.6,9.34-5.29,19.42-13.98,24.33-5.21,2.43-6.49-3.82-6.97-7.33Z"/>
          <path fill="#e8c305" d="m502.91,702.36c.66-8.98,3.03-16.67,8.43-23.21.96-1.17,2.3-2.17,3.66-2.84,5.94-2.87,6.33,5.94,6.24,9.77-.5,7.23-2.75,13.95-7.03,19.88-5.92,7.95-10.89,5.76-11.3-3.61Z"/>
          <path fill="#e8c305" d="m489.47,781.7c1.04-9.7,3.64-18.25,10.7-24.89,6.64-5.3,7.82,3.11,7.34,8.11-.82,7.66-3.48,14.65-8.63,20.5-5.51,5.71-8.62,3.36-9.4-3.72Z"/>
          <path fill="#e8c305" d="m426.55,636.93c-8.25-.2-16.98-1.07-24.15-5.47-4.21-3.14,2.91-4.62,5.34-4.73,8.59-.49,17.02.45,25.11,3.54,11.43,4.82-.57,7.15-6.3,6.66Z"/>
        </g>
      </g>
    </svg>
  )
}