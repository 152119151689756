export const NoFlame54 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="313.93 824.11 446.47 855.49 559.67 739.07 483.79 631.98 366.08 571.66 268.12 699.81 313.93 824.11"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m446.91,857.07c-44.03-10.29-88.05-20.64-132.09-30.91-1.07-.25-1.69-.69-2.09-1.77-14.31-39.52-35.42-89.79-46.37-124.38,10.93-14.2,23.15-30.02,34.04-44.24,21.43-27.99,42.88-55.98,64.27-84.01.92-1.21,1.57-1.33,2.89-.65,39.31,21.06,80.25,39.99,118.79,62.06,24.44,35,48.79,70.06,73.31,104.99.84,1.19.78,1.85-.22,2.87-37.7,38.29-74.46,78.69-112.54,116.03Zm-74.77-281.35c-.03-.08-.04-.16-.15-.12.06.03.11.07.17.1,19.52,34.73,40.86,68.97,60.81,103.63,1.76,3.01,3.89,4.92,7.1,6.28,38.33,16.26,76.39,32.67,114.72,48.99-24.01-33.83-46.62-69.14-71.61-102.04-37.04-18.86-73.92-38.22-111.04-56.83Zm-56.95,241.94c37.16-43.29,74.09-86.8,111.25-130.1.77-.9.87-1.57.26-2.61-20.39-35.2-40.74-70.43-61.12-105.63-1.26-2.18-.95-2.45-2.71-.15-30.56,39.77-61.05,79.58-91.63,119.32-.66.86-.8,1.54-.41,2.55,7.62,20.14,36.43,98.63,44.37,116.62Zm238.64-75.5c-39.18-17.08-79.15-33.13-118.62-49.82-.77-.32-1.27-.37-1.89.35-25.57,29.75-51.19,59.45-76.77,89.19-11.45,13.3-22.89,26.59-34.33,39.89-1.22,1.42-1.2,1.43.6,1.86,40.93,9.75,81.86,19.49,122.78,29.32,1.19.29,1.9.02,2.71-.84,35.04-36.77,70.44-73.21,105.52-109.94Z"/>
          <path fill="#e8c305" d="m372.16,575.69c-.06-.03-.11-.07-.17-.1.14-.06.12.14.17.1Z"/>
          <path fill="#e8c305" d="m315.58,760.51c.44-7.15,2.88-14.25,8.63-19.97,12.89-11.26,18.85,6.8,14.84,17.49-3.54,16.03-23.04,25.88-23.47,2.49Z"/>
          <path fill="#e8c305" d="m340.67,640.6c.69-9.23,4.04-17.14,11.81-22.66,6.56-4.77,12.65.69,12.63,7.27,1.98,18.39-22.68,39.78-24.45,15.39Z"/>
          <path fill="#e8c305" d="m430.43,753.26c-26.52-3.4-1.71-33.8,17.2-29.59,18.05,4.07,4.46,24.31-6.94,27.46-3.11,1.34-6.37,2.02-10.25,2.13Z"/>
          <path fill="#e8c305" d="m462.15,764.25c-6.79.32-12.95-5.77-9.45-12.95,3.98-8.91,13.42-14.69,22.97-15.51,8.33-1,15.2,6,10.19,14.49-4.21,7.81-14.54,13.98-23.71,13.96Z"/>
          <path fill="#e8c305" d="m374.49,815.54c-14.94-.15-14.31-13.59-4.09-20.75,7.57-7.38,32.68-11.03,28.46,5.68-1.47,3.92-4.21,6.82-7.52,9.22-5.05,3.66-10.67,5.68-16.85,5.86Z"/>
          <path fill="#e8c305" d="m492.17,774.43c-5.26.48-11.12-3.35-9.82-9.19,2.7-11.41,17.48-20.35,28.84-17.18,9.99,3.64,3.28,16.13-3.06,20.22-4.68,3.66-9.92,5.98-15.97,6.15Z"/>
          <path fill="#e8c305" d="m420.76,798.1c5.46-.39,12.37,3.53,10.26,10.02-3.49,10.12-14.92,15.92-25.23,15.83-6.42-.04-11.88-5.27-8.35-12.07,4.49-8.52,13.66-13.26,23.32-13.78Z"/>
          <path fill="#e8c305" d="m437.21,831.88c-13.18,0-11.13-12.16-2.74-18.23,6.13-5.93,27.32-12.1,26.45,1.93-.69,3.39-2.69,5.97-5.13,8.27-5.22,4.93-11.41,7.64-18.59,8.03Z"/>
          <path fill="#e8c305" d="m438.04,654.92c9.8,2.16,18.19,10.18,24.39,17.87,2.5,2.89,6.04,10.84-1.08,9.26-10.54-3.33-19.41-11.33-25.3-20.58-1.59-2.49-2.66-6.93,2-6.55Z"/>
          <path fill="#e8c305" d="m428.19,626.94c-9.13-2.31-17.15-9.55-23.15-16.69-2.13-2.33-5.63-9.23.5-7.96,9.48,2.83,17.3,9.86,23.37,17.48,1.73,2.24,4.55,7.48-.72,7.17Z"/>
          <path fill="#e8c305" d="m520.94,708.38c-9.57-2.67-21.4-13.29-25.12-22.6-1.03-7.59,10.77.39,13.16,2.32,3.1,1.64,22.31,20.03,11.96,20.28Z"/>
          <path fill="#e8c305" d="m463.88,631.62c9.09,2.65,17.04,10.25,22.56,17.91,2.68,3.67,1.88,7.1-3.13,4.87-8.71-4.12-15.79-10.15-21.03-18.27-1.1-1.95-2.12-4.84,1.61-4.51Z"/>
        </g>
      </g>
    </svg>
  )
}