export const NoFlame49 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="282.29 750.25 313.63 804.24 481.62 852.28 550.68 683.51 384.25 620.16 338.77 603.22 282.29 750.25"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m340.06,599.12c.4.16.69.28.98.4,14.41,5.71,28.85,11.33,43.21,17.14,32.62,13.19,65.42,25.94,98.11,38.95,23.21,9.24,46.43,18.44,69.68,27.6,1.53.6,1.03,1.33.65,2.26-11.29,27.62-22.58,55.23-33.87,82.85-11.61,28.39-23.22,56.78-34.78,85.19-.54,1.32-1.07,1.55-2.39,1.17-35.24-10.29-70.49-20.55-105.74-30.81-20.82-6.06-41.64-12.11-62.46-18.15-.92-.27-1.52-.71-2.02-1.54-9.98-16.53-19.99-33.04-30.03-49.53-.5-.82-.51-1.48-.19-2.32,10.58-27.66,21.14-55.33,31.7-82.99,8.75-22.92,17.5-45.85,26.25-68.77.2-.53.2-1.25.89-1.43Zm206.78,88.06c-.5-.63-1.31-.74-2-1.01-51.61-20.28-103.23-40.53-154.83-60.83-1.42-.56-1.85-.16-2.34,1.12-22.38,57.74-44.78,115.47-67.18,173.2-.94,2.43-1.01,1.95,1.31,2.64,20.94,6.17,41.89,12.31,62.84,18.46,31.49,9.25,62.99,18.5,94.47,27.79,1.34.4,1.87.15,2.4-1.16,10.65-26.24,21.36-52.46,32.06-78.68,10.92-26.75,21.83-53.51,32.75-80.26.16-.39.32-.77.53-1.28Zm-207.42-83c-.74.16-.77.81-.97,1.33-7.42,19.41-14.84,38.82-22.25,58.24-10.89,28.51-21.77,57.02-32.67,85.52-.31.82-.3,1.42.17,2.18,4.12,6.54,8.2,13.11,12.29,19.67,5.32,8.53,10.65,17.05,16,25.56.31.49.46,1.37,1.19,1.28.62-.07.6-.9.8-1.41,11.19-28.9,22.37-57.8,33.56-86.7,11.15-28.8,22.29-57.6,33.46-86.4.41-1.05.28-1.57-.81-2.03-13.32-5.61-26.62-11.26-39.93-16.89-.29-.12-.58-.24-.86-.35Z"/>
          <path fill="#e8c305" d="m412.66,704.04c-9.61.58-18.07-6.87-19.01-16.45-1.02-10.42,6.1-20.42,15.81-22.66,10.53-2.43,20.66,4.33,22.28,15.03,1.68,11.11-5.58,21.51-16.59,23.76-.82.17-1.66.21-2.49.31Z"/>
          <path fill="#e8c305" d="m397.55,770.4c0,12.26-11.58,22.48-23.53,20.55-10.5-1.7-15.93-10.17-14.88-19.39,1.24-10.93,10.37-18.89,21.03-18.8,9.92.09,17.38,7.71,17.38,17.64Z"/>
          <path fill="#e8c305" d="m472.49,698.3c-.04,8.96-6.6,17.58-15.33,19.79-10.44,2.64-20.19-3.99-21.57-14.68-1.41-10.9,6.07-21.19,16.82-23.13,9.74-1.76,19.26,5.64,19.93,15.5.05.73.09,1.46.15,2.52Z"/>
          <path fill="#e8c305" d="m401.21,786.94c.03-10.88,9.32-20.43,20.05-20.62,10.88-.19,18.81,9.32,16.8,20.14-1.66,8.93-8.44,15.71-17.19,17.13-10.29,1.68-19.8-5.65-19.66-16.65Z"/>
          <path fill="#e8c305" d="m511.29,712.24c-.17,8.78-5.93,17.33-14.83,19.83-9,2.53-17.96-2.59-20.31-11.65-2.69-10.36,3.78-21.81,14.05-24.75,10.55-3.02,21.12,4.31,21.09,16.56Z"/>
          <path fill="#e8c305" d="m441.59,800c.16-10.64,8.59-19.67,18.82-20.56,10.46-.9,18.65,8.29,16.89,18.95-1.54,9.35-8.66,16.43-18.02,17.49-8.55.97-16.21-4.45-17.46-13.6-.12-.88-.18-1.77-.23-2.28Z"/>
          <path fill="#e8c305" d="m328.24,731.65c-.18,5.77-1.71,11.25-3.73,16.61-1.54,4.08-3.32,8.03-6.03,11.5-.39.49-.81.97-1.27,1.38-.75.67-1.61,1.39-2.65,1.02-1.01-.36-1.3-1.41-1.52-2.41-.47-2.14-.29-4.28.04-6.4,1.32-8.47,3.91-16.48,8.65-23.71.83-1.27,1.75-2.49,3.09-3.3,1.48-.9,2.46-.54,3.01,1.1.46,1.37.42,2.79.42,4.2Z"/>
          <path fill="#e8c305" d="m340.68,633.73c-.25,5.71-1.9,11.09-4.04,16.34-1.34,3.3-2.91,6.49-4.99,9.4-.7.99-1.52,1.88-2.48,2.6-1.22.92-2.17.57-2.58-.91-.67-2.4-.21-4.79.28-7.14,1.65-7.84,4.32-15.28,9.04-21.86.58-.81,1.25-1.55,2.13-2.06,1.28-.74,2.04-.43,2.47.98.06.2.15.4.16.6.02.68,0,1.36,0,2.04Z"/>
        </g>
      </g>
    </svg>
  )
}