export const SmallFlame03 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="286.47 624.07 393.35 610.99 534.19 635.59 516.35 797.92 426.82 846.72 280.53 797.9 286.47 624.07"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m378.86,696.02c.05,22.29,29.63,32.39,33.18,6.01,2.59-25.31-31.8-35.84-33.18-6.01Z"/>
          <path fill="#e8c305" d="m321.04,750.3c-9.55-6.15-17.86,1.83-18.69,11.33.25,8.86,2.85,15.4,9.1,20.16,21.89,13.03,25.63-23.12,9.59-31.49Z"/>
          <path fill="#e8c305" d="m463.46,684.89c-4.66,6.41-6.41,13.74-6.53,21.53-.13,3.64.31,7.44,2.47,10.5,4.15,5.98,10-.21,12.6-4.32,3.59-5.71,5.06-12.11,5.25-18.8.72-9.64-5.53-19.49-13.78-8.91Z"/>
          <path fill="#e8c305" d="m459.16,771.14c-7.63,6.25-10.59,16.36-10.71,25.94.04,10.26,6,14.39,13.11,5.51,4.72-6.31,6.72-13.6,7.04-21.39.61-5.3-2.95-14.94-9.43-10.06Z"/>
          <path fill="#e8c305" d="m512.32,668.59c-9.53,4.54-13.2,24.5-8.44,33.36,1.28,2.33,3.69,2.73,5.94,1.31,7.03-5.46,8.67-15.14,9.49-23.57.56-5.71-.85-12.48-6.99-11.1Z"/>
          <path fill="#e8c305" d="m498.27,756.5c-3.61,5.82-5.24,12.27-5.34,19.09-.24,9.11,4.76,13.69,11.09,5.01,4.12-5.99,5.71-12.81,6.1-21.28-1.29-9.76-5.83-11.84-11.86-2.81Z"/>
          <path fill="#e8c305" d="m431.77,629.77c-.33-.23-.76-.37-1.16-.45-2.86-.62-5.69-1.36-8.58-1.79-7.1-1.05-14.24-1.56-21.4-.79-.12.01-.24.03-.36.05-1.67.27-1.86,2.62-.28,3.2.3.11.6.2.9.28,8.63,2.19,17.42,2.82,26.29,2.49,1.5-.05,3.01-.32,4.49-.65.1-.02.19-.06.28-.1.76-.36.85-1.42.2-1.96-.12-.1-.24-.19-.37-.28Z"/>
          <path fill="#e8c305" d="m556.49,657.72c-10.49-43.33-34.74-76.43-76.77-94.79-22.46-9.81-40.85-24.65-54.44-45.32-8.22-12.5-16.32-25.2-28.71-34.23-1.93-1.41-3.66-3.26-6.47-3.72-.13,1.32-.26,2.28-.31,3.25-1.1,20.76-9.42,37.87-25.84,50.8-3.34,2.63-5.84,5.84-7.86,9.64-20.52,1.57-40.87,4.13-60.97,9.19,11.89,15.9,13.02,32.91,8.26,51.33-24.85-2.06-48.91-6.07-73.52-10.24.53,1.13.7,1.76,1.07,2.23,11.13,14.51,14.61,31.01,13.05,48.94-1.42,16.28-4.75,32.24-7.63,48.28-3.06,17.06-6.25,34.05-2.57,51.48,1.55,7.35,4.65,14.26,5.9,21.63,5.72,33.59,24.4,57.37,54.75,72.02,27.44,13.24,56.58,15.15,86.34,11.33,9.14-1.18,18.16-2.99,27.56-5.74,9.12,3.1,15.69,5.34,18.1,6.15.67.23,1.68.03,2.32-.33,18.14-10.25,75.44-42.73,86.02-48.57,1.84-1.02,2.76-2.12,3-4.36.37-3.44,1.43-12.87,2.86-25.54,1.78-1.08,2.61-2.66,3.62-3.97,7.46-9.62,14.08-19.77,19.46-30.71,12.27-24.96,19.42-51.27,12.77-78.74Zm-262.33-33.98c.15-.02.48-.06.95-.12,28.15-3.49,55.85-6.97,83.55-10.25,1.26-.15,2.51-.34,3.77-.54,5.09-.61,8.83-1.04,10.47-1.21,2.63-.27,5.39.46,8.05.92,3.88.67,11.13,1.93,20.28,3.52,20.85,3.77,41.71,7.51,62.57,11.27,14.47,2.61,28.93,5.25,43.79,7.95-7.89,1.7-59.42,12.69-83.18,17.7-1.14.24-2.4.26-3.54.04-23.52-4.61-146.02-28.84-146.72-28.99,0-.1,0-.2,0-.29Zm128.88,219.6c-.73-.21-137.36-46.25-138.15-46.52-2.29-.77-2.27-.77-2.17-3.12.28-6.12,6.6-163.44,6.65-164.09,49.89,10.14,99.58,20.24,149.43,30.37-5.26,61.15-10.51,122.15-15.77,183.36Zm93.04-49.7c-.19,1.85-.84,2.92-2.51,3.85-26.9,14.89-81.71,45.37-82.44,45.74.35-4.07,14.97-169.06,15.93-179.22.35-3.66.43-3.68,4.06-4.51,27.1-6.19,81.8-18.63,82.31-18.72-.18,1.85-16.47,144.12-17.36,152.86Z"/>
          <path fill="#e8c305" d="m192.51,535.92c-1.51-13.63-10.47-23.39-19.43-33.91-2.28,7.36-3.25,13.92-3.75,20.55-.41,5.48-.92,11.21,2.27,16.02,7.57,11.45,18.4,19.25,30.2,25.83v-.02c-.72-1.62-1.3-3.32-2.17-4.85-4.13-7.36-6.18-15.15-7.12-23.62Z"/>
          <polygon points="201.94 564.52 201.79 564.4 201.8 564.41 201.94 564.52"/>
        </g>
      </g>
    </svg>
  )
}