export const NoFlame02 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="285.86 625.38 389.7 622.84 529.69 632.67 520.51 799.02 433.7 837.43 283.4 803.63 285.86 625.38"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m522.83,632.13c2.45.15,4.91.29,7.36.47.93.07,1.26.59,1.21,1.58-.41,7.85-8.67,149.88-9.49,164.89-.08,1.45-.54,2.16-1.89,2.75-28.42,12.5-56.82,25.07-85.24,37.56-1.01.45-2.36.6-3.44.36-37.57-8.51-137.1-31.19-149.3-33.96-.4-.09-.79-.24-1.27-.38.89-60,1.78-119.95,2.67-180.02,5.5-.16,22.91-.65,26.3-.74,25.86-.69,51.72-1.38,77.59-2.06,1.04-.03,2.08.08,3.13.12-.25.06-.49.16-.74.16-9.16.28-93.88,2.6-94.4,2.64,0,.14,0,.28.01.42,1.06.07,129.11,10.27,135.11,10.71,3.81.28,7.64.72,11.44.58,7.95-.29,79.79-4.81,80.96-5.07Zm-235.43-1.16c-.89,56.94-1.77,113.64-2.65,170.38,47.89,10.7,95.59,21.37,143.48,32.07,2.69-63.44,5.36-126.61,8.04-189.9-49.75-4.19-99.21-8.36-148.87-12.55Zm241.85,6.96c-.81,0-69.12,4.75-82.02,5.65-2.53.18-2.52.13-2.63,2.65-.4,9.31-7.82,183.93-7.82,186.79.28-.11,59.8-25.77,80.85-34.76,1.73-.74,2.51-1.6,2.46-3.55-.06-2.66,8.89-151.79,9.16-156.78Z"/>
          <path fill="#e8c305" d="m430.13,630.13c-12.3.54-24.13-.07-36.23-1.37,2.85-1.53,32.27-.42,36.23,1.37Z"/>
          <path fill="#e8c305" d="m411.52,687.91c.03,4.88-1.19,9.38-4.27,13.2-5.56,6.91-14.21,7.8-21.14,2.27-8.78-6.99-10.54-21.99-3.68-30.84,5.72-7.38,15.23-7.59,21.71-1.82,5.12,4.56,7.26,10.46,7.37,17.19Z"/>
          <path fill="#e8c305" d="m305.72,755.45c3.55-7.83,11.96-9.81,18.82-4.64,7.66,5.77,10.65,18.02,6.5,26.67-3.86,8.06-12.57,9.96-19.47,4.26-5.04-4.17-8.2-11.58-7.71-18.12-.15-.04.72-5.66,1.86-8.17Z"/>
          <path fill="#e8c305" d="m456.07,691.14c.21-6,1.24-11.79,4.24-17.06.89-1.56,2.05-3.07,3.4-4.25,3.2-2.79,6.7-2.22,9.1,1.3,2.1,3.08,2.84,6.62,3.11,10.25.48,6.52-.45,12.84-3.27,18.8-1.17,2.48-2.64,4.78-5,6.32-2.73,1.78-5.59,1.3-7.66-1.24-2.1-2.57-2.94-5.64-3.43-8.81-.27-1.75-.33-3.53-.48-5.3Z"/>
          <path fill="#e8c305" d="m471.64,774.71c-.44,7.4-1.78,14.59-6.61,20.63-.94,1.18-2.25,2.23-3.6,2.9-2.79,1.37-4.98.64-6.77-1.95-2.11-3.05-2.69-6.59-2.78-10.15-.18-6.96,1.02-13.7,4.71-19.71,1.22-1.99,2.99-3.75,4.83-5.22,2.1-1.68,4.6-1.22,6.49.75,2.17,2.27,2.88,5.17,3.33,8.14.22,1.49.27,3.02.4,4.61Z"/>
          <path fill="#e8c305" d="m516.85,677.69c.03,6-.99,11.78-4.16,16.99-.69,1.14-1.64,2.18-2.66,3.06-2.17,1.87-4.76,1.64-6.4-.72-1.05-1.52-1.95-3.29-2.36-5.07-1.82-7.95-1.02-15.71,2.44-23.11.76-1.62,1.93-3.13,3.19-4.42,2.18-2.24,5.36-2.05,7.2.77,1.74,2.67,2.41,5.66,2.72,8.75.12,1.24.02,2.51.02,3.76Z"/>
          <path fill="#e8c305" d="m512.13,760.45c.06,5.73-1.02,11.24-3.74,16.32-.87,1.63-2.05,3.18-3.39,4.45-2.36,2.25-5.32,1.9-6.84-.94-1.06-1.98-1.83-4.26-2.11-6.48-.93-7.56.27-14.82,4.08-21.49.91-1.58,2.28-2.99,3.71-4.15,1.83-1.49,4.05-1.03,5.54.79,1.87,2.3,2.39,5.08,2.72,7.89.14,1.19.02,2.4.02,3.6Z"/>
        </g>
      </g>
    </svg>
  )
}