export const BigFlame56 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="267.94 677.71 308.97 826.02 426.72 846.48 553.06 762.42 492.96 628.27 386.92 573.3 267.94 677.71"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m247.45,322.71c-14.54-8.23-30.56-13.77-42.94-25.46-1.33,1.72-.79,3.07-.62,4.29,6.74,33.39-15.65,56.43-28.54,84.37-18.67,43.94,6.9,64.5,35.09,93.78.38-.23.76-.46,1.13-.69-2.59-25.23-.75-50.34,15.72-71.12,16.57-19.28,50.67-65.09,20.16-85.17Z"/>
          <path fill="#e8c305" d="m323.99,474.22c.42-7.74.78-13.7,1.05-19.66,1.47-12.51-8.75-17.97-19.38-20.57-13.58-3.76-25.07-8.59-38.02-14.74,16.76,45.71,36.5,26.57,56.35,54.98Z"/>
          <path fill="#e8c305" d="m554.57,760.69c-5.18-11.22-10.35-22.45-15.51-33.68,38.72-89.86-44.13-158.69-126.61-142.69-8.66-4.49-17.28-9.03-25.76-13.72-1.04-.55-1.68-.51-2.59.3-7.75,6.92-15.51,13.83-23.26,20.74-4.77.98-9.55,1.96-14.45,2.96.62,2.88,1.28,5.23,2.64,7.57-4.03,3.59-8.06,7.19-12.09,10.78-31.59,2.44-74.53,12.67-91.37-20.99-10.37,45.68-7.93,91.34-.5,137.64-3.09-1.17-3.38-3.74-5.18-5.13-3.21,12.92-.84,24.38,8.83,33.52,5.56,5.25,9.47,11.38,13.16,17.91,10.08,17.78,25.08,30.83,42.28,43.46.64,2.38,1.28,4.76,1.92,7.14.29,1.08.79,1.4,1.78,1.55,39.64,6.36,79.08,12.97,118.76,19.23,42.63-26.94,84.72-56.12,127.19-83.75,1.33-.88,1.35-1.55.75-2.84Zm-246.32,57.74c-12.68-46.43-25.22-92.9-38.01-139.29-.33-1.18-.07-1.91.84-2.72,37.27-32.84,74.27-65.98,111.53-98.83,19.63,47.28,37.34,96.49,56.4,144.38.39.99-.13,1.43-.67,1.82-18.09,13.18-35.92,26.72-53.88,40.09-24.7,18.39-49.36,36.83-74.04,55.25-1.63,1.22-1.65,1.22-2.17-.69Zm238.69-53.13c-39.33,26.14-78.68,52.28-118,78.44-37.86-3.12-76.53-12.47-114.57-18.01,43.45-31.96,86.49-64.28,130.04-96.05,34.06,9.19,68.18,23.09,102.24,33.86.51.17,1.32.11,1.39.77.06.54-.68.71-1.1.99Zm-98.12-44.08c-21.51-46.93-38.11-97.55-58.51-145.51,32.69,16.99,64.81,33.85,97.47,50.73,2.88,1.49,4.78,3.36,6.14,6.37,18.55,41.59,38.33,82.89,56.5,124.52-33.63-12.63-67.77-23.96-101.59-36.11Z"/>
          <path fill="#e8c305" d="m335.97,743.05c-15.06-9.5-26.71,16.9-20.55,28.88,3.28,8.78,14,8.57,19.06,1.95,4.53-4.99,6.41-11.04,6.65-17.66.02-4.81-1.33-9.99-5.17-13.17Z"/>
          <path fill="#e8c305" d="m361.31,620.89c-6.82,5.2-9.98,12.27-10.36,20.7-.35,6.04,3.21,12.81,9.27,13.36,12.84.27,20.35-15.45,18.52-26.81-.68-8.41-10.25-13.15-17.43-7.24Z"/>
          <path fill="#e8c305" d="m430.91,774.06c7.91-.46,15.27-3.08,21.83-7.81,9.78-6.96,7.26-15-4.95-14.11-9.96,1.23-21.35,5.03-26.42,14.34-2.32,5.9,4.98,7.75,9.54,7.58Z"/>
          <path fill="#e8c305" d="m477.37,760.77c-7.31.21-13.91,2.65-20.04,6.5-2.54,1.6-4.82,3.54-6.46,6.1-2.16,3.38-1.17,6.24,2.63,7.5,8.13,2.05,16.74-1.24,23.77-5.24,8.27-4.25,14.05-14.86.09-14.85Z"/>
          <path fill="#e8c305" d="m392.23,807.65c3.56-5.56-4.1-8.05-8.48-7.62-7.13.14-13.71,2.21-19.8,5.81-10.01,5.54-10.33,14.18,2.71,13.65,9.35-.86,19.82-3.93,25.57-11.84Z"/>
          <path fill="#e8c305" d="m503.45,768.64c-10.12,1.01-21.86,4.6-26.86,14.16-1.45,4.73,4.82,6,8.26,5.57,9.58-1.04,20.25-4.82,25.58-13.3,2.4-4.62-3.27-6.61-6.97-6.43Z"/>
          <path fill="#e8c305" d="m420.83,813.02c2.86-4.9-3.7-7.02-7.53-6.68-7.39.23-14.17,2.45-20.4,6.39-3.61,2.07-9.85,8.31-3.71,11.19,9.84,3.18,25.77-2.44,31.65-10.89Z"/>
          <path fill="#e8c305" d="m439.44,812.17c-8.99.99-19.23,4.05-24.51,11.88-2.28,4.75,3.41,5.97,7.42,5.89,7.64-.49,15.19-3.11,21.8-8.24,6.94-5.46,2.93-10.25-4.71-9.53Z"/>
          <path fill="#e8c305" d="m452.62,677.11c-6.25-1.63-6.2,6.01-4.48,9.97,1.93,5.46,5.02,10.23,8.87,14.49,3.33,4.37,16.87,14.73,17.24,3.37.03,0,.05,0,.07-.01-1.87-11.57-10.63-23.7-21.71-27.81Z"/>
          <path fill="#e8c305" d="m430.73,611.49c-3.29-3.05-12.41-8.34-12.49-.04,2.25,10.84,9.53,19.41,18.89,25.06,2.93,1.79,7.6,1.7,7.44-2.74-1.02-9.04-7.3-16.42-13.84-22.27Z"/>
          <path fill="#e8c305" d="m519.61,729.27c3.16,1.13,5.16-.24,5.27-3.63-1.93-10.64-9.8-22.8-20.15-26.65-4.99-1.12-4.59,5.2-3.37,8.28,3.4,8.99,9.32,17.89,18.25,22Z"/>
          <path fill="#e8c305" d="m479.76,635.55c-2.18-1.81-7.88-4.62-8.43.01.26,8.64,6.44,15.74,12.31,21.51,2.53,2.71,10.86,8.11,11.01,1.01-1.38-9.24-7.79-16.82-14.88-22.53Z"/>
        </g>
      </g>
    </svg>
  )
}