export const NoFlame53 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="269.83 711.93 316.62 823.17 455.82 858.23 559.02 727.25 480.57 635 357.34 574.69 269.83 711.93"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m455.82,859.51c-46.72-11.92-93.32-23.91-140.03-35.86-1.06-.27-1.39-.93-1.74-1.77-7.48-17.81-14.96-35.61-22.45-53.41-7.72-18.37-15.42-36.75-23.18-55.1,20.46-39.49,49.99-77.11,73.44-115.95,4.76-7.33,9.52-14.67,14.28-22.01q1.16-1.78,3.14-.8c40.63,20.17,81.24,40.36,121.9,60.47,27.17,29.28,52,61.23,78.4,91.38.92,1.09,1.12,1.68.05,3.04-34.61,43.13-68.59,87.94-103.82,130.01Zm-91.59-280.75c-.12-.04-.23-.07-.35-.11,19.76,29.23,41.13,57.61,61.35,86.61,1.05,1.49,2.33,2.43,3.96,3.14,21.69,9.46,43.38,18.94,65.04,28.47,19.91,8.8,39.78,17.45,59.78,26.12-22.49-28.04-46.58-55.13-69.61-82.86-1.61-1.91-3.2-3.72-5.6-4.89-38.31-18.6-76.25-37.94-114.58-56.48Zm61.59,96.66c-34.54,47.48-68.24,95.87-102.5,143.64q-.99,1.39.69,1.83c43.95,10.31,88.81,25.35,132.36,33.35,30.59-41.36,69.96-83.31,96.44-124.29-42.36-18.13-84.65-36.36-126.99-54.53Zm-109.78,140.15c33.97-47.79,67.63-95.79,101.55-143.61.81-1.14,1.15-1.89.2-3.25-20.12-28.79-40.18-57.62-60.25-86.45-1.41-2.02-1.4-2.03-2.77.07-27.54,42.36-55.06,84.73-82.64,127.06-.5.77-.86,1.39-.42,2.41,14.88,34.57,29.46,69.17,44.32,103.77Z"/>
          <path fill="#e8c305" d="m363.99,579c-.03-.11-.07-.23-.1-.34.12.04.23.07.35.11-.08.08-.16.16-.24.23Z"/>
          <path fill="#e8c305" d="m415.37,731.31c.03-15.2,23.54-29.13,34.94-16.75,13.52,19.32-31.8,42.94-34.94,16.75Z"/>
          <path fill="#e8c305" d="m474.07,723.61c8.43-.14,14.4,7.33,10.71,15.96-4.63,16.62-40.74,24.26-33.41-.68,3.54-8.64,13.93-15.64,22.7-15.29Z"/>
          <path fill="#e8c305" d="m375.75,812.11c-3.47-.25-7.62-1.04-10.25-4.97-7.23-12.98,11.06-25.97,23.05-25.14,9.01-.69,15.38,8.9,10.42,16.77-4.27,7.98-13.28,13.31-23.22,13.34Z"/>
          <path fill="#e8c305" d="m482.71,756.48c1.07-10.7,11.6-19.11,22.06-20.01,5.44-.44,11.54,2.06,12.04,8.87,1.52,16.72-32.53,32.34-34.1,11.14Z"/>
          <path fill="#e8c305" d="m410.17,821.82c-6.32.23-13.05-5.66-10.51-12.73,2.5-15.3,38.37-25.83,34.04-2.86-2,5.88-6.3,9.75-11.6,12.63-3.45,1.87-7.16,2.9-11.93,2.96Z"/>
          <path fill="#e8c305" d="m443.01,830.89c-6.11.28-12.08-4.24-10.42-11.03,2.6-9.99,13.05-16.74,23.11-16.84,15.32.72,11.15,16.71,1.56,22.78-4.21,3.13-8.95,4.95-14.26,5.08Z"/>
          <path fill="#e8c305" d="m338.31,746.91c-.45,8.18-2.99,15.54-8.64,21.65-3.23,3.88-10.12,5.58-12.64.05-4.59-10.75.65-26.17,10.35-32.51,7.44-4.09,11.3,4.5,10.93,10.81Z"/>
          <path fill="#e8c305" d="m335.81,642.34c.79-9.59,4.57-19.08,13.15-24.22,9.69-4.41,10.66,9.77,8.19,15.87-2.24,7.2-6.39,15.48-14.17,17.75-5.47,1.09-7.33-5.06-7.17-9.4Z"/>
          <path fill="#e8c305" d="m429.99,646.09c10.25,2.57,19.61,9.89,26.76,17.64,1.43,1.57,4.64,6.2.7,6.54-10.64-1.69-19.56-9.3-27.06-16.64-1.95-2.11-6.62-7.86-.4-7.54Z"/>
          <path fill="#e8c305" d="m519.57,698.58c-10.24-2.66-19.09-10.1-26.02-17.96-4.95-6.41,1.03-5.88,5.44-3.43,3.28.55,30.25,20.04,20.58,21.39Z"/>
          <path fill="#e8c305" d="m424.54,622.79c-1.69,6.47-24.58-10.6-26.91-13.85-4.68-4.57-4.98-8.81,2.68-5.58,9.13,4.52,19.59,10,24.23,19.43Z"/>
          <path fill="#e8c305" d="m458.42,632.31c9.61,2.78,18.04,9.42,24.64,16.88,4.21,5.24-1.57,4.44-4.82,2.56-2.88-.37-28.9-18.6-19.81-19.44Z"/>
        </g>
      </g>
    </svg>
  )
}