export const BigFlame03 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="286.87 624.17 393.74 611.1 534.59 635.7 516.74 798.03 427.22 846.82 280.92 798 286.87 624.17"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m536.65,601.59c-32.98-24.39-37.68-12.66-41.07-57.36-7.68-39.44-58.97,10.09-94.94-46.69.37,11.56,2.14,21.94,3.81,32.8-35.89-13.01-71.67-24.31-108.84-33.65,23.35,55.75-5.53,111.39-30.55,160.5-1.26,2.46-2.72,2.26-4.85,1.46-32.29-12.18-64.42-31.37-78.79-64.3-6.21,48.88,22.6,93.02,31.77,139.82,2.9,13.28,9.84,25.27,12.41,38.85,16.07,73.16,104.54,79.59,166.81,65.35,17.17,5.85,30.36,10.34,34.01,11.57.67.23,1.68.03,2.32-.33,18.14-10.25,75.44-42.73,86.02-48.57,1.84-1.02,2.76-2.12,3-4.36.39-3.68,1.58-14.21,3.16-28.23,48.51-40.57,79.78-125.89,15.72-166.86Zm-242.49,22.14c.15-.02.46-.06.9-.11,12.28-1.27,24.56-2.91,36.84-4.59,18.76-2.33,39.92-4.94,52.01-6.38,4.11-.37,8.23-.68,12.34-.93,1.57.2,3.15.55,4.7.82,2.03.35,5,.86,8.68,1.5,39,7.17,78.9,13.14,117.32,21.38-9.72,2.09-59.35,12.67-82.54,17.57-1.14.24-2.4.26-3.54.04-23.52-4.61-146.02-28.84-146.72-28.99,0-.1,0-.2,0-.29Zm128.88,219.6c-.73-.21-137.36-46.25-138.15-46.52-2.29-.77-2.27-.77-2.17-3.12.28-6.12,6.6-163.44,6.65-164.09,49.89,10.14,99.58,20.24,149.43,30.37-5.26,61.15-10.51,122.15-15.77,183.36Zm93.04-49.7c-.19,1.85-.84,2.92-2.51,3.85-26.9,14.89-81.71,45.37-82.44,45.74.35-4.07,14.97-169.06,15.93-179.22.35-3.66.43-3.68,4.06-4.51,27.1-6.19,81.8-18.63,82.31-18.72-.18,1.85-16.47,144.12-17.36,152.86Zm19.53-154.04c.19,1.44-.15,3.1-.55,4.77.21-1.85.4-3.45.55-4.77Z"/>
          <path fill="#e8c305" d="m378.86,696.02c.05,22.29,29.63,32.39,33.18,6.01,2.59-25.31-31.8-35.84-33.18-6.01Z"/>
          <path fill="#e8c305" d="m321.04,750.3c-9.55-6.15-17.86,1.83-18.69,11.33.25,8.86,2.85,15.4,9.1,20.16,21.89,13.03,25.63-23.12,9.59-31.49Z"/>
          <path fill="#e8c305" d="m463.46,684.89c-4.66,6.41-6.41,13.74-6.53,21.53-.13,3.64.31,7.44,2.47,10.5,4.15,5.98,10-.21,12.6-4.32,3.59-5.71,5.06-12.11,5.25-18.8.72-9.64-5.53-19.49-13.78-8.91Z"/>
          <path fill="#e8c305" d="m459.16,771.14c-7.63,6.25-10.59,16.36-10.71,25.94.04,10.26,6,14.39,13.11,5.51,4.72-6.31,6.72-13.6,7.04-21.39.61-5.3-2.95-14.94-9.43-10.06Z"/>
          <path fill="#e8c305" d="m512.32,668.59c-9.53,4.54-13.2,24.5-8.44,33.36,1.28,2.33,3.69,2.73,5.94,1.31,7.03-5.46,8.67-15.14,9.49-23.57.56-5.71-.85-12.48-6.99-11.1Z"/>
          <path fill="#e8c305" d="m498.27,756.5c-3.61,5.82-5.24,12.27-5.34,19.09-.24,9.11,4.76,13.69,11.09,5.01,4.12-5.99,5.71-12.81,6.1-21.28-1.29-9.76-5.83-11.84-11.86-2.81Z"/>
          <path fill="#e8c305" d="m431.77,629.77c-.33-.23-.76-.37-1.16-.45-2.86-.62-5.69-1.36-8.58-1.79-7.1-1.05-14.24-1.56-21.4-.79-.12.01-.24.03-.36.05-1.67.27-1.86,2.62-.28,3.2.3.11.6.2.9.28,8.63,2.19,17.42,2.82,26.29,2.49,1.5-.05,3.01-.32,4.49-.65.1-.02.19-.06.28-.1.76-.36.85-1.42.2-1.96-.12-.1-.24-.19-.37-.28Z"/>
        </g>
      </g>
    </svg>
  )
}