const Style: { [key: string]: React.CSSProperties } = {
    securityContainer: {
        marginTop: "15%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    securityTitle: {
        // display:"flex",
        fontWeight: "bolder",
        marginBottom: "10px",
    },
    securityItems: {
        display: "flex",
        flexDirection: "column",
    },
    securityItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "15%",
        paddingBottom: "5px"
    },
    itemIcon: {
        paddingRight: "5px",
        width: "13px",
        height: "15px",
    },
    itemTextS: {
        fontSize: "0.8em"
    },
    itemTextM: {
        fontSize: "1em"
    },
    itemTextL: {
        fontSize: "1.2em"
    }
};
// Props
type Props = {
    title: string,
    item1: string,
    item2: string,
    item3: string,
    fontSize?: "small" | "medium" | "large"
}

export const SecurityText = ({
    title,
    item1,
    item2,
    item3,
    fontSize = "medium",
}: Props) => {
    let lockIcon = require("../assets/lockIcon.png");
    let encryptIcon = require("../assets/encryptIcon.png");
    let authIcon = require("../assets/authIcon.png");

    let finalFontStyle = undefined;
    switch (fontSize) {
        case "medium":
            finalFontStyle = Style.itemTextM;
            break;
        case "small":
            finalFontStyle = Style.itemTextS;
            break;
        default:
            finalFontStyle = Style.itemTextL;
            break;
    }

    return (
        <div className="securityContainer">
            <span style={Style.securityTitle}>{title}</span>
            <p style={Style.securityItems}>
                <span style={Style.securityItem}>
                    <img src={lockIcon} style={Style.itemIcon}></img>
                    <span style={finalFontStyle}>{item1}</span>
                </span>
                <span style={Style.securityItem}>
                    <img src={encryptIcon} style={Style.itemIcon}></img>
                    <span style={finalFontStyle}>{item2}</span>
                </span>
                <span style={Style.securityItem}>
                    <img src={authIcon} style={Style.itemIcon}></img>
                    <span style={finalFontStyle}>{item3}</span>
                </span>
            </p>
        </div>
    );
}