export function moneyFormatter (amount, currency, decimals=false) {
  amount = Number(amount)

  if (isNaN(amount)) {
    return "Invalid amount"
  }

  if (!decimals && Number.isInteger(amount)) {
    amount = amount.toString()
    amount = amount.replace(
      /\B(?=(\d{3})+(?!\d))/g, ","
    )
  } else {
    amount = amount.toFixed(2).split('.')
    amount[0] = amount[0].replace(
      /\B(?=(\d{3})+(?!\d))/g, ","
    )
    amount = amount.join('.')
  }

  return currency + amount
}

export function moneyDeformatter (currency) {
  return currency.replace(/[$,]/g, "")
}