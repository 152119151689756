export const BigFlame53 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="269.81 712.11 316.6 823.35 455.79 858.41 558.99 727.43 480.54 635.18 357.31 574.87 269.81 712.11"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m312.13,524.94c.03-38.2,65.19-32.21-4.8-72.49,23.92,34.82-24.4,33.85,4.8,72.49Z"/>
          <path fill="#e8c305" d="m60.54,321.04c5.19,9.94,16.61,14.97,19.51,26.38,4.12-18.19-5.45-33.35-10.63-49.85-11.08,4.68-14.26,13.42-8.87,23.47Z"/>
          <path fill="#e8c305" d="m559.59,726.46c-21.94-25.06-42.8-51.35-64.82-76.32-3.25-5.71-7.17-10.86-12.47-14.88-12.97-10.81-22.25-24.9-33.41-37.44-.76-.89-1.28-2.47-3.23-1.82.69,5.04,1.38,10.08,2.09,15.23-3.58.49-5.78-1.5-8.05-2.98-6.97-4.55-14.16-8.71-21.48-12.65-33.29-20.15-76.75-20.55-104.4-49.59-.32,10.18,1.25,19.89,4.8,29.29-.09.08-.18.16-.26.24-18.67-9.26-30.64-29.66-44.61-45.09,2.32-29.21,11.09-67.23-19.73-84.66-42.5-17.32-73.45-48.32-76.54-96.05-22.64,42.59-71.22,99.68-23.57,142.25,16.79,15.3,36.18,25.19,58.84,28.39,8.24,1.16,16.54,2.5,23.72,7.11,9.17,5.88,17.94,13.38,19.53,24.15,3.43,23.24,4.76,46.98-.31,70.08-10.04,35.02,3.34,67.07,12.88,100.19,4.91,20.04,13.87,57.31,32.6,69.32,4.3,10.22,8.59,20.44,12.89,30.67.35.84.68,1.5,1.74,1.77,46.71,11.94,93.31,23.93,140.03,35.85,35.23-42.07,69.21-86.89,103.82-130.01,1.07-1.36.87-1.95-.05-3.04Zm-287.88-14.65c-.44-1.02-.09-1.64.42-2.41,27.58-42.33,55.1-84.7,82.64-127.06,1.37-2.1,1.36-2.09,2.77-.07,20.07,28.83,40.13,57.66,60.25,86.45.95,1.35.61,2.11-.2,3.25-33.92,47.83-67.58,95.82-101.55,143.61-14.86-34.6-29.44-69.19-44.32-103.77Zm184.65,142.43c-43.54-8-88.4-23.04-132.36-33.35q-1.68-.44-.69-1.83c34.26-47.78,67.96-96.17,102.5-143.64,42.34,18.16,84.63,36.4,126.99,54.53-26.48,40.99-65.85,82.93-96.44,124.29Zm37.87-157.37c-21.66-9.53-43.35-19.01-65.04-28.47-1.63-.71-2.91-1.65-3.96-3.14-20.16-28.91-41.47-57.21-61.17-86.34.05-.05.11-.1.16-.16h0c38.33,18.54,76.28,37.88,114.58,56.48,2.4,1.16,3.98,2.98,5.6,4.89,23.03,27.73,47.12,54.82,69.61,82.86-20-8.67-39.87-17.32-59.78-26.12Z"/>
          <path fill="#e8c305" d="m415.37,731.31c3.14,26.18,48.47,2.57,34.94-16.75-11.41-12.37-34.91,1.55-34.94,16.75Z"/>
          <path fill="#e8c305" d="m484.78,739.57c3.68-8.64-2.29-16.11-10.71-15.96-8.77-.35-19.16,6.65-22.7,15.29-7.33,24.94,28.79,17.3,33.41.68Z"/>
          <path fill="#e8c305" d="m388.55,781.99c-12-.83-30.28,12.16-23.05,25.14,2.63,3.93,6.79,4.72,10.25,4.97,9.94-.03,18.95-5.36,23.22-13.34,4.96-7.87-1.41-17.46-10.42-16.77Z"/>
          <path fill="#e8c305" d="m516.8,745.34c-.5-6.81-6.6-9.31-12.04-8.87-10.46.9-20.99,9.31-22.06,20.01,1.57,21.2,35.62,5.58,34.1-11.14Z"/>
          <path fill="#e8c305" d="m399.66,809.09c-2.54,7.07,4.19,12.96,10.51,12.73,4.77-.07,8.49-1.09,11.93-2.96,5.29-2.88,9.6-6.75,11.6-12.63,4.33-22.96-31.53-12.43-34.04,2.86Z"/>
          <path fill="#e8c305" d="m455.7,803.03c-10.07.1-20.51,6.85-23.11,16.84-1.66,6.79,4.31,11.31,10.42,11.02,5.31-.13,10.05-1.95,14.26-5.08,9.59-6.07,13.75-22.06-1.56-22.78Z"/>
          <path fill="#e8c305" d="m327.38,736.1c-9.7,6.34-14.94,21.76-10.35,32.51,2.52,5.53,9.41,3.83,12.64-.05,5.65-6.11,8.19-13.47,8.64-21.65.37-6.31-3.49-14.9-10.93-10.81Z"/>
          <path fill="#e8c305" d="m348.96,618.12c-8.58,5.14-12.36,14.63-13.15,24.22-.16,4.34,1.7,10.49,7.17,9.4,7.79-2.26,11.94-10.54,14.17-17.75,2.47-6.1,1.5-20.28-8.19-15.87Z"/>
          <path fill="#e8c305" d="m429.99,646.09c-6.22-.32-1.55,5.43.4,7.54,7.51,7.34,16.43,14.95,27.06,16.64,3.93-.34.72-4.97-.7-6.54-7.15-7.75-16.51-15.07-26.76-17.64Z"/>
          <path fill="#e8c305" d="m519.57,698.58c9.67-1.35-17.3-20.84-20.58-21.39-4.42-2.45-10.39-2.98-5.44,3.43,6.93,7.86,15.78,15.3,26.02,17.96Z"/>
          <path fill="#e8c305" d="m400.31,603.36c-7.66-3.23-7.36,1.01-2.68,5.58,2.33,3.26,25.22,20.32,26.91,13.85-4.64-9.43-15.1-14.91-24.23-19.43Z"/>
          <path fill="#e8c305" d="m458.42,632.31c-9.08.84,16.93,19.07,19.81,19.44,3.26,1.88,9.03,2.68,4.82-2.56-6.6-7.46-15.03-14.11-24.64-16.88Z"/>
        </g>
      </g>
    </svg>
  )
}