export const BigFlame04 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="290.88 624.17 398.67 601.52 540.08 641.23 512.62 796.85 419.52 856.32 277.77 789.65 290.88 624.17"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m542.41,604.16c-.48-.49-1.71-1.48-1.92-.36-.96,5.1-2.72.87-3.51.2-13.46-12.79-32.79-18.67-45.44-31.91-7.56-13.32-8.22-28.58-11.64-43.14-11.09-34.76-48.36-.36-92.58-45.78,4.01,13.53,9.02,26.75,13.31,40.21.53,1.6,2.31,3.52.66,4.96-38.38-12.1-77.44-28.42-116.4-40.83-1.56-.53-3.17-1.97-5.21-.55,21.4,41.16,17.34,89.92-1.99,130.92-1.13,2.78-2.74,3.19-5.42,2.57-14.54-3.4-24.66-12.61-32.46-24.73-20.73-32.95-23.8-72.79-39.27-107.98-.99-2.23-1.4-5.05-4.99-6.64,16.26,65.84-57.2,141.96,2.61,197.64,51.87,48.62,2.62,97.8,69.83,142.04,33.12,21.27,72.95,24.68,111.36,18.9,13.27,6.09,26.54,12.17,39.81,18.27,31.6-15.75,61.61-39.7,92.65-58.14.88-.57,1.27-1.23,1.44-2.24,1.63-9.13,3.28-18.26,4.92-27.39,45.6-43.78,66.96-113.45,24.24-166.03Zm-245.39,18.37c33.76-6.62,67.47-13.48,101.24-20.08.36.05.72.11,1.08.17,45.11,11.29,90.38,24.94,135.04,37.8-29.42,9.59-58.63,19.65-87.99,29.4-49.99-13.5-99.58-31.71-149.37-46.94,0-.12,0-.23,0-.35Zm124.26-15.62c14.16,3.27,28.28,7.39,42.39,11.74-14.12-4.03-28.24-7.99-42.39-11.74Zm-28.25-5.2c-2.99.58-5.98,1.16-8.98,1.75,2.91-.59,5.81-1.19,8.72-1.78.09.01.17.02.26.04Zm23.5,249.75c-44.78-20.24-89.46-40.65-134.24-60.9-1.5-.68-1.94-1.44-1.81-3.04,4.01-52.94,7.93-105.87,11.74-158.82,49.08,15.84,98.01,32,147.06,47.88,1.18.38,1.61.75,1.41,2.16-8.18,57.56-15.97,115.17-24.17,172.73Zm95.11-56.53c-27.16,20.49-58.35,37.44-86.99,56.54,8.03-57.56,16.52-114.93,24.62-172.46.14-1.1.65-1.48,1.57-1.8,29.09-10.03,57.96-20.82,87.15-30.52,0,0,.09.08.09.08.03-.06.07-.12.1-.19-8.64,49.45-17.64,98.91-26.54,148.35Z"/>
          <path fill="#e8c305" d="m394.25,688.86c-20.69.54-17.83,31.22-2.4,38,27.25,10.42,26.48-35.84,2.4-38Z"/>
          <path fill="#e8c305" d="m317.87,749.19c-11.57-6.74-19.78,7.24-16.25,17.49,3.12,16.93,26.39,25.31,27.66,2.74-.55-8.4-3.74-15.55-11.4-20.24Z"/>
          <path fill="#e8c305" d="m464.86,698.48c-5.18,6.79-7.7,14.53-8.15,24.11.91,9.62,5.99,12.24,12.95,4.39,6.32-8.13,11.77-21.96,6.55-31.69-3.28-4.41-9.04-.1-11.35,3.18Z"/>
          <path fill="#e8c305" d="m454.47,782.09c-6.73,6.93-9.77,15.43-10.63,25.52.48,3.52,1.76,9.76,6.97,7.33,8.69-4.91,12.38-14.99,13.98-24.33,1.53-7.81-2.48-16.12-10.32-8.52Z"/>
          <path fill="#e8c305" d="m515,676.32c-1.36.67-2.7,1.67-3.66,2.84-5.4,6.54-7.77,14.23-8.43,23.21.42,9.37,5.39,11.56,11.3,3.61,4.27-5.93,6.53-12.65,7.03-19.88.09-3.83-.29-12.64-6.24-9.77Z"/>
          <path fill="#e8c305" d="m500.17,756.81c-7.05,6.63-9.66,15.19-10.7,24.89.78,7.08,3.9,9.43,9.4,3.72,5.15-5.86,7.82-12.85,8.63-20.5.48-4.99-.71-13.4-7.34-8.11Z"/>
          <path fill="#e8c305" d="m432.85,630.27c-8.09-3.08-16.52-4.02-25.11-3.54-2.42.11-9.54,1.59-5.34,4.73,7.17,4.4,15.9,5.27,24.15,5.47,5.73.48,17.73-1.85,6.3-6.66Z"/>
        </g>
      </g>
    </svg>
  )
}